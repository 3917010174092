import axios, { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { FormErrors } from 'redux-form';
import {
	couponUse,
	resetCoupon,
	setCoupon,
	setCouponError,
	setHash,
} from '../store/Coupon';
import { IValidationForm } from '../store/ValidationForm';

const asyncValidateCoupon = async (
	values: IValidationForm,
	dispatch: Dispatch,
) => {
	const errors: FormErrors<IValidationForm> = {};
	if (values.coupon) {
		try {
			dispatch(resetCoupon());
			const reedemData = {
				code: values.coupon,
				customerIdentity: values.documentId,
				vehiclePlate: values.plate,
			};

			const {
				data: { data, hash },
			}: AxiosResponse<any> = await axios.post(`/redeem`, reedemData);

			if (
				data &&
				data.vehiclePlate === values.plate &&
				data.documentIdentity === values.documentId
			) {
				dispatch(setCoupon(data));
				dispatch(setHash(hash));
				dispatch(couponUse());
			}

			if (
				data.vehiclePlate !== values.plate ||
				data.documentIdentity !== values.documentId
			) {
				errors.coupon = 'El código ingresado no es válido';
				dispatch(setCouponError('El código ingresado no es válido'));
			}
		} catch (error) {
			dispatch(setCouponError('El código ingresado no es válido'));
			errors.coupon = 'El código ingresado no es válido';
		}
	}

	if (errors.coupon) {
		throw errors;
	}
};

export default asyncValidateCoupon;
