import es from 'date-fns/locale/es';
import * as React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { WrappedFieldProps } from 'redux-form';

import 'react-datepicker/dist/react-datepicker.css';

registerLocale('es', es);

interface IFormDatePickerProps {
	disabled?: boolean;
	inputFormat: string;
	placeholder: string;
	minDate: Date;
	maxDate: Date;
	isNow: boolean;
	yearDropdownItemNumber: number;
}

export default function FormDatePicker(
	props: WrappedFieldProps & IFormDatePickerProps,
) {
	const {
		input,
		disabled = false,
		inputFormat,
		placeholder,
		minDate,
		maxDate,
		isNow,
		yearDropdownItemNumber = 0,
		meta: { touched, error, asyncValidating },
	} = props;

	const handleOnBlur = () => false;
	const handleOnChange = (date: Date) => input.onChange(date);

	if (typeof input.value === 'string' && input.value.length === 0 && isNow) {
		handleOnChange(new Date());
	}

	return (
		<>
			<DatePicker
				{...input}
				selected={input.value}
				onChange={handleOnChange}
				onBlur={handleOnBlur}
				dateFormat={inputFormat}
				maxDate={maxDate}
				minDate={minDate}
				locale={es}
				placeholderText={placeholder}
				className="form-control"
				disabled={disabled}
				showYearDropdown={true}
				scrollableYearDropdown={true}
				yearDropdownItemNumber={yearDropdownItemNumber}
			/>
			{asyncValidating && <i className="element-spinner" />}
			{touched && error && <small className="invalid">{error}</small>}
		</>
	);
}
