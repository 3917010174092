import React from 'react';
import Icon from '../assets/svg/companies-support.svg';

interface ICompaniesSupportProps {
	text: string;
}

export default function CompaniesSupport(props: ICompaniesSupportProps) {
	return (
		<div className="container-fluid mt10 pb15 pt15 pl0 pr15">
			<div className="container text-center">
				<div className="row">
					<div className="col-12 col-sm-12 mb15">{props.text}</div>
					<div className="col-12 relative">
						<img className="icon-companies" src={Icon} alt="companies" />
					</div>
				</div>
			</div>
		</div>
	);
}
