import React, { MutableRefObject, useEffect, useState } from 'react';
import {
	AsyncValidateCallback,
	Field,
	InjectedFormProps,
	reduxForm,
} from 'redux-form';
import asyncValidateStep1 from '../helpers/asyncValidateStep1';
import focusInputOnError from '../helpers/focusInputOnError';
import validate from '../helpers/quoteFormValidate';
import { formatPlate } from '../helpers/utils';
import { IValidationForm } from '../store/ValidationForm';
import {
	FormCheckbox,
	FormDatePicker,
	FormInput,
	FormSelect,
	InfoBox,
} from './';
import { IOptions } from './FormSelect';
import {
	EventNameUA,
	PageNameUA,
	PageNameGA4,
	sendUACustomEvent,
	sendUAPageView,
	sendGA4PageView,
	sendGA4FunnelStepViewed,
} from '../helpers/gtm';

interface IQuoteFormCustomProps {
	vehicleDepartments: IOptions[];
	doctypes: IOptions[];
	defaultVehicleDepartment?: IOptions;
	defaultDocType?: IOptions;
	errors: IValidationForm;
	coupon: any;
	couponCode: string;
	plate?: string;
	isMoto?: boolean;
	submitRef: MutableRefObject<HTMLButtonElement | any> | undefined;
	onChangePolicies: () => void;
	politycs?: boolean;
	setValidBtn: (valid: boolean) => void;
}

export function QuoteForm(
	props: IQuoteFormCustomProps &
		InjectedFormProps<IValidationForm, IQuoteFormCustomProps>,
) {
	const {
		handleSubmit,
		vehicleDepartments = [],
		doctypes = [],
		defaultVehicleDepartment,
		defaultDocType,
		valid,
		errors: {
			vehicleDepartment: vehicleDepartmentError,
			docType: doctypeError,
		},
		coupon,
		couponCode,
		isMoto = false,
		submitRef,
		onChangePolicies,
		politycs,
		setValidBtn,
		asyncValidating,
	} = props;

	const [viewCoupon, setViewCoupon] = useState(coupon.use || false);
	const [isMotorcycle, setMoto] = useState(false);

	useEffect(() => {
		if (couponCode !== undefined) {
			setViewCoupon(true);
		}
		setMoto(isMoto);
	}, [couponCode, isMoto]);

	useEffect(() => {
		sendUAPageView(PageNameUA.Paso_IngresoPlaca, true, true);
		sendGA4PageView(PageNameGA4.Paso_IngresoPlaca);
		sendGA4FunnelStepViewed(PageNameGA4.Paso_IngresoPlaca, false);
	}, []);

	const btnIsValid = () => {
		const couponValid = viewCoupon ? coupon.use : true;
		setValidBtn(valid && politycs && couponValid && !asyncValidating);
		return valid && politycs && couponValid && !asyncValidating;
	};

	return (
		<form onSubmit={handleSubmit} noValidate={true}>
			<div className="row flex-column">
				<div className="col-12 col-sm-9 col-md-7 col-lg-6 form-group mx-auto mb20">
					<label htmlFor="plate">Ingresa tu placa de auto o moto</label>
					<Field
						name="plate"
						type="text"
						placeholder="AUTO: ABC123 MOTO: 1234AB"
						component={FormInput}
						normalize={formatPlate}
					/>
				</div>
				<div className="col-12 col-sm-9 col-md-7 col-lg-6 form-group mx-auto mb20">
					<label htmlFor="vehicleDepartment">Departamento</label>
					<Field
						name="vehicleDepartment"
						placeholder={'Selecciona...'}
						options={vehicleDepartments}
						component={FormSelect}
						onFocus={(e: any) =>
							sendUACustomEvent(EventNameUA.SelectDepartamento, isMotorcycle)
						}
						props={{
							defaultOption: defaultVehicleDepartment,
							error: vehicleDepartmentError,
							showDefault: isMotorcycle,
						}}
					/>
				</div>
				<div className="col-12 col-sm-9 col-md-7 col-lg-6 form-group mx-auto mb20 d-none">
					<label htmlFor="docType">Tipo de Documento</label>
					<Field
						name="docType"
						placeholder="Selecciona..."
						options={doctypes}
						component={FormSelect}
						props={{ defaultOption: defaultDocType, error: doctypeError }}
					/>
				</div>
				<div className="col-12 col-sm-9 col-md-7 col-lg-6 form-group mx-auto mb20">
					<label htmlFor="documentId">Número de DNI</label>
					<Field
						name="documentId"
						type="text"
						placeholder="Ej. 123456789"
						maxlength={8}
						component={FormInput}
						onFocus={(e: any) => {
							sendUACustomEvent(EventNameUA.InputDni, isMotorcycle);
						}}
					/>
				</div>
				<div className="col-12 col-sm-9 col-md-7 col-lg-6 form-group mx-auto mb5">
					<label htmlFor="date">¿Cuándo quieres que inicie tu seguro?</label>
					<Field
						name="date"
						inputFormat="dd-MM-yyyy"
						placeholder="Elige una fecha"
						minDate={new Date()}
						maxDate={null}
						isNow={true}
						component={FormDatePicker}
						yearDropdownItemNumber={1}
						onFocus={(e: any) => {
							sendUACustomEvent(EventNameUA.SelectFechaSeguro, isMotorcycle);
						}}
					/>
				</div>

				<div className="col-12 col-sm-9 col-md-7 col-lg-6 form-group mx-auto mb40">
					<InfoBox
						type="information"
						text={
							'Recuerda que la fecha de inicio del seguro debe ser por lo menos 1 día después del fin de vigencia de tu SOAT actual.'
						}
					/>
				</div>

				<div className="col-12 col-sm-9 col-md-7 col-lg-6 form-group mx-auto mb20">
					<label htmlFor="coupon">
						¿Tienes un código de descuento? (opcional)
					</label>
					<Field
						name="coupon"
						type="text"
						placeholder="Ej. MICODIGO, 23456"
						component={FormInput}
						onFocus={(e: any) => {
							sendUACustomEvent(EventNameUA.InputCodigoPromocional, isMotorcycle);
						}}
						props={{ error: coupon.errors.coupon }}
					/>
				</div>

				<div className="col-12 col-sm-9 col-md-7 col-lg-6 form-group mx-auto mt20">
					<Field
						name="quotePrivacyPolicies"
						component={FormCheckbox}
						onChange={onChangePolicies}
						onFocus={() =>
							sendUACustomEvent(EventNameUA.CheckTerminosCondiciones, isMotorcycle)
						}
						newValue={politycs}
						text={[
							'He leído la ',
							<a
								href="https://sfestaticos.blob.core.windows.net/peru/web/pdf/POLITICAS%20DE%20PRIVACIDAD%20SEGUROS%20FALABELLA.pdf"
								target="_blank"
								rel="noopener noreferrer"
								key="quotePrivacyPoliciesKey"
							>
								política de privacidad.
							</a>,
						]}
					/>
				</div>
				<div
					className="col-12 col-sm-9 col-md-3 col-lg-5 form-group mx-auto"
					style={{ display: 'none' }}
				>
					<button
						id="quote"
						className={`btn ${btnIsValid() ? 'primary' : 'white'}`}
						type="submit"
						disabled={!btnIsValid()}
						ref={submitRef}
						onClick={() =>
							sendUACustomEvent(
								EventNameUA.ClickIngresoPlacaBtnContinuar,
								isMotorcycle,
							)
						}
					>
						Continuar
					</button>
				</div>
			</div>
		</form>
	);
}

export default reduxForm<IValidationForm, IQuoteFormCustomProps>({
	asyncBlurFields: ['plate', 'coupon', 'documentId'],
	asyncValidate: asyncValidateStep1,
	enableReinitialize: true,
	form: 'quote',
	onSubmitFail: focusInputOnError,
	shouldAsyncValidate: (
		params: AsyncValidateCallback<IValidationForm, string>,
	) => params.trigger === 'blur' && params.syncValidationPasses,
	validate,
})(QuoteForm);
