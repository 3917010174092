import * as React from 'react';
import { WrappedFieldProps } from 'redux-form';

/**
 * Options for Select in Forms
 * @property {string} name
 * @property {string} code
 * @property {string} value
 */
export interface IOptions {
	name: string;
	code?: string;
	value?: string;
}

interface IFormSelectProps {
	placeholder?: string;
	options: IOptions[];
	isDisabled?: boolean;
	showDefault?: boolean;
	defaultOption?: IOptions;
	error: string;
}

export default function FormSelect(
	props: WrappedFieldProps & IFormSelectProps,
) {
	const {
		input,
		placeholder = 'Seleccione',
		options,
		isDisabled = false,
		meta: { touched, error },
		showDefault = true,
		defaultOption,
		error: customError,
	} = props;

	if (defaultOption && !input.value) {
		input.onChange(defaultOption.value);
	}

	return (
		<div>
			<div className="select-container">
				<select
					{...input}
					id={input.name}
					placeholder={placeholder}
					className={`form-control ${
						options && !options.length && !customError ? 'loading' : ''
					}`}
					disabled={isDisabled}
					value={input.value || (defaultOption && defaultOption.value) || ''}
				>
					{showDefault && !input.value && !defaultOption && (
						<option value="">{placeholder}</option>
					)}
					{options &&
						options.map((option, i) => (
							<option key={i} value={option.value}>
								{option.name}
							</option>
						))}
				</select>
				{options && !options.length && options.length !== 0 && (
					<i className="element-spinner" />
				)}
			</div>
			{((touched && error) || customError) && (
				<small className="invalid">{customError || error}</small>
			)}
		</div>
	);
}
