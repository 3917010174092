import React from 'react';
import { AsyncValidateCallback, Field, reduxForm } from 'redux-form';
import asyncValidateStep1 from '../helpers/asyncValidateStep1';
import focusInputOnError from '../helpers/focusInputOnError';
import { IValidationForm } from '../store/ValidationForm';
import { FormInput, FormSelect } from './';
import { IOptions } from './FormSelect';

interface ICardFormCustomProps {
	errors: IValidationForm;
}

export function CardForm(props: ICardFormCustomProps) {
	const {
		errors: {
			vehicleDepartment: vehicleDepartmentError,
			docType: doctypeError,
		},
	} = props;

	const getYears = () => {
		const years: IOptions[] = [];
		const year = new Date().getFullYear();
		const maxYear = year + 10;
		for (let index = year; index <= maxYear; index++) {
			years.push({ name: index.toString(), value: index.toString() });
		}
		return years;
	};

	const getMonths = () => {
		const months: IOptions[] = [];
		for (let month = 1; month <= 12; month++) {
			months.push({ name: month.toString(), value: month.toString() });
		}
		return months;
	};

	return (
		<form noValidate={true}>
			<div className="row flex-row">
				<div className="col-12 col-sm-12 col-md-12 col-lg-12 form-group mx-auto mb20">
					<label>Paga con cualquier tarjeta CMR</label>
					<Field
						name="cardNumber"
						type="text"
						placeholder="Número de tarjeta de crédito"
						maxlength={16}
						component={FormInput}
					/>
				</div>
				<div className="col-12 col-sm-12 col-md-6 col-lg-6 form-group mx-auto mb20">
					<Field
						name="month"
						placeholder={'Mes de vencimiento'}
						options={getMonths()}
						component={FormSelect}
						props={{
							defaultOption: undefined,
							error: vehicleDepartmentError,
						}}
					/>
				</div>
				<div className="col-12 col-sm-12 col-md-6 col-lg-6 form-group mx-auto mb20">
					<Field
						name="year"
						placeholder="Año de vencimiento"
						options={getYears()}
						component={FormSelect}
						props={{ defaultOption: undefined, error: doctypeError }}
					/>
				</div>
				<div className="col-12 col-sm-12 col-md-12 col-lg-6 form-group mx-auto mb20">
					<Field
						name="cvv"
						type="text"
						placeholder="Código seguridad"
						maxlength={3}
						component={FormInput}
					/>
				</div>
				<div className="col-12 col-sm-12 col-md-12 col-lg-6 form-group mx-auto mb20">
					<Field
						name="documentId"
						type="text"
						placeholder="RUT dueño de tarjeta"
						maxlength={8}
						component={FormInput}
					/>
				</div>
				<div className="col-12 col-sm-12 col-md-12 col-lg-12 form-group mx-auto mb40">
					<label className="text-dark-gray">
						* Para validar tu tarjeta de crédito, es posible se haga un cargo
						que luego será reversado.
					</label>
				</div>
			</div>
		</form>
	);
}

export default reduxForm<IValidationForm, ICardFormCustomProps>({
	asyncBlurFields: ['cardNumber'],
	asyncValidate: asyncValidateStep1,
	enableReinitialize: true,
	form: 'card',
	onSubmitFail: focusInputOnError,
	shouldAsyncValidate: (
		params: AsyncValidateCallback<IValidationForm, string>,
	) => params.trigger === 'blur' && params.syncValidationPasses,
})(CardForm);
