import * as React from 'react';
import { Link } from 'react-router-dom';
import { Field, InjectedFormProps, reduxForm, getFormValues } from 'redux-form';
import {
	FormDatePicker,
	FormInput,
	FormNumber,
	FormSelect,
	FormCheckbox,
} from '.';
import validate from '../helpers/emitPersonalInformationValidate';
import focusInputOnError from '../helpers/focusInputOnError';
import { parseStringToNumber, removeAccentedChars } from '../helpers/utils';
import { IEmitForm } from '../store/Emit';
import { IOptions } from './FormSelect';
import { connect } from 'react-redux';
import ModalAdviser from './ModalAdviser';
import { IAdviserState } from '../store/Adviser';
import {
	EventNameUA,
	EventNameGA4,
	sendUACustomEvent,
	sendGA4UserInteractionEvent,
} from '../helpers/gtm';

interface IEmitFormProps {
	departments: IOptions[];
	provinces?: IOptions[];
	districts?: IOptions[];
	errors: IEmitForm;
	getDistricts: (provinceCode: string) => void;
	getProvinces: (departmentCode: string) => void;
	formData: IEmitForm;
	submitRef: React.MutableRefObject<HTMLButtonElement | any> | undefined;
	politycs: boolean;
	onChangePolicies: () => void;
	isMoto: boolean;
	validateAdviser: (adviserCode: string) => void;
	saveAdviserCode: (adviserCode: string) => void;
	adviser: IAdviserState;
	resetStateAdviserCode: () => void;
	documentId: string;
}

export function EmitPersonalInformationForm(
	props: IEmitFormProps & InjectedFormProps<IEmitForm, IEmitFormProps>,
) {
	const {
		handleSubmit,
		departments,
		provinces,
		districts,
		formData,
		getDistricts,
		getProvinces,
		errors: {
			department: departmentError,
			district: districtError,
			province: provinceError,
		},
		submitRef,
		politycs,
		onChangePolicies,
		isMoto,
		validateAdviser,
		saveAdviserCode,
		adviser,
		resetStateAdviserCode,
		documentId,
	} = props;

	const [isOpenModalAdviser, setIsOpenModalAdviser] = React.useState(false);

	const handleDepartmentChange = (event: any) => {
		getProvinces(event.target.value);
	};

	const normalizeAndRemoveAccentedChars = (name: string) =>
		removeAccentedChars(name);

	const handleProvinceChange = (event: any) => {
		getDistricts(event.target.value);
	};

	const handleModalAdviser = () => {
		setIsOpenModalAdviser(!isOpenModalAdviser);
	};

	const handleOnFocus = () => {
		sendUACustomEvent(EventNameUA.CheckDatosPersonalesCMRPuntos, isMoto);
		sendGA4UserInteractionEvent(EventNameGA4.CheckDatosPersonalesCMRPuntos, documentId);
	};

	if (!formData) {
		return null;
	}

	return (
		<>
			<form onSubmit={handleSubmit} noValidate={true}>
				<div className="row flex-column">
					<div className="col-12 col-sm-8 col-md-7 col-lg-6 form-group mx-auto mb2">
						<h2 className="wizard-card--title">Datos personales</h2>
					</div>

					<div className="w-100 d-none d-sm-block" />

					<div className="col-12 col-sm-8 col-md-7 col-lg-6 form-group mx-auto mb20">
						<label htmlFor="name">Nombre</label>
						<Field
							onFocus={() =>
								sendUACustomEvent(
									EventNameUA.InputDatosPersonalesNombre,
									isMoto,
								)
							}
							name="name"
							type="text"
							placeholder="Nombre"
							component={FormInput}
							normalize={normalizeAndRemoveAccentedChars}
						/>
					</div>

					<div className="w-100 d-none d-sm-block" />

					<div className="col-12 col-sm-8 col-md-7 col-lg-6 form-group mx-auto mb20">
						<label htmlFor="paternal">Apellido paterno</label>
						<Field
							onFocus={() =>
								sendUACustomEvent(
									EventNameUA.InputDatosPersonalesApellidoPaterno,
									isMoto,
								)
							}
							name="paternal"
							type="text"
							placeholder="Apellido paterno"
							component={FormInput}
							normalize={normalizeAndRemoveAccentedChars}
						/>
					</div>

					<div className="w-100 d-none d-sm-block" />

					<div className="col-12 col-sm-8 col-md-7 col-lg-6 form-group mx-auto mb20">
						<label htmlFor="maternal">Apellido materno</label>
						<Field
							onFocus={() =>
								sendUACustomEvent(
									EventNameUA.InputDatosPersonalesApellidoMaterno,
									isMoto,
								)
							}
							name="maternal"
							type="text"
							placeholder="Apellido materno"
							component={FormInput}
							normalize={normalizeAndRemoveAccentedChars}
						/>
					</div>

					<div className="w-100 d-none d-sm-block" />

					<div className="col-12 col-sm-8 col-md-7 col-lg-6 form-group mx-auto mb20">
						<label htmlFor="mail">Correo</label>
						<Field
							onFocus={() =>
								sendUACustomEvent(
									EventNameUA.InputDatosPersonalesCorreo,
									isMoto,
								)
							}
							name="email"
							type="email"
							placeholder="ejemplo@dominio.com"
							component={FormInput}
						/>
					</div>

					<div className="w-100 d-none d-sm-block" />

					<div className="col-12 col-sm-8 col-md-7 col-lg-6 form-group mx-auto mb20">
						<label htmlFor="gender">Celular</label>
						<Field
							onFocus={() =>
								sendUACustomEvent(
									EventNameUA.InputDatosPersonalesTelefono,
									isMoto,
								)
							}
							name="phone"
							placeholder="941234567"
							inputFormat="#########"
							isPhone={true}
							component={FormNumber}
						/>
					</div>

					<div className="w-100 d-none d-sm-block" />

					<div className="col-12 col-sm-8 col-md-7 col-lg-6 form-group mx-auto mb20">
						<label htmlFor="gender">Fecha de nacimiento</label>
						<Field
							onFocus={() =>
								sendUACustomEvent(
									EventNameUA.SelectDatosPersonalesFechaNacimiento,
									isMoto,
								)
							}
							name="birthdate"
							inputFormat="dd-MM-yyyy"
							placeholder="Selecciona una fecha"
							minDate={null}
							maxDate={new Date()}
							component={FormDatePicker}
							yearDropdownItemNumber={30}
						/>
						<hr className="mt30" />
					</div>
					<div className="col-12 col-sm-8 col-md-7 col-lg-6 form-group mx-auto mb20">
						<p>
							Recuerda que la dirección es solamente referencial. La entrega del
							SOAT llegará a tu correo electrónico.
						</p>
					</div>

					<div className="w-100 d-none d-sm-block" />

					<div className="col-12 col-sm-8 col-md-7 col-lg-6 form-group mx-auto mb20">
						<label htmlFor="gender">Departamento</label>
						<Field
							name="department"
							placeholder={'Selecciona...'}
							options={departments}
							component={FormSelect}
							onChange={handleDepartmentChange}
							props={{ error: departmentError }}
						/>
					</div>

					<div className="w-100 d-none d-sm-block" />

					<div className="col-12 col-sm-8 col-md-7 col-lg-6 form-group mx-auto mb20">
						<label htmlFor="gender">Provincia</label>
						<Field
							name="province"
							placeholder={'Selecciona...'}
							options={provinces}
							component={FormSelect}
							onChange={handleProvinceChange}
							props={{ error: provinceError }}
						/>
					</div>

					<div className="w-100 d-none d-sm-block" />

					<div className="col-12 col-sm-8 col-md-7 col-lg-6 form-group mx-auto mb20">
						<label htmlFor="district">Distrito</label>
						<Field
							name="district"
							placeholder={'Selecciona...'}
							options={districts}
							component={FormSelect}
							props={{ error: districtError }}
						/>
					</div>

					<div className="w-100 d-none d-sm-block" />

					<div className="col-12 col-sm-8 col-md-7 col-lg-6 form-group mx-auto mb20">
						<label htmlFor="address">Calle</label>
						<Field name="address" component={FormInput} placeholder="" />
					</div>

					<div className="w-100 d-none d-sm-block" />

					<div className="col-12 col-sm-8 col-md-7 col-lg-6 form-group mx-auto mb20">
						<label htmlFor="addressNumber">Número</label>
						<Field
							name="addressNumber"
							placeholder="Ej. 1234"
							inputFormat="#####"
							normalize={parseStringToNumber}
							component={FormNumber}
						/>
					</div>

					<div className="col-12 col-sm-8 col-md-7 col-lg-6 form-group mx-auto mb20">
						{!adviser.data.validAdviser && (
							<>
								<hr className="hr-line" />
								<div className="text-center">
									<label className="adviser-help" onClick={handleModalAdviser}>
										¿Te ayudó un asesor en la compra?
									</label>
								</div>
							</>
						)}
						<hr className="hr-line" />
						<div className="col-12 mb15 pl0 pr0">
							<Field
								onFocus={() =>
									sendUACustomEvent(
										EventNameUA.CheckDatosPersonalesCartaNombramiento,
										isMoto,
									)
								}
								name="politycs"
								component={FormCheckbox}
								onChange={onChangePolicies}
								newValue={politycs}
								text={[
									'He leído los ',
									<a
										href="https://sfestaticos.blob.core.windows.net/peru/soat/pdf/terminos-y-condiciones-soat.pdf"
										target="_blank"
										rel="noopener noreferrer"
										key="politycsKey"
									>
										Términos y Condiciones
									</a>,
									' del Seguro y acepto la ',
									<a
										href="https://sfestaticos.blob.core.windows.net/peru/pdf/normativa/modelo-carta-de-nombramiento.pdf"
										target="_blank"
										rel="noopener noreferrer"
										key="politycs2Key"
									>
										Carta de Nombramiento
									</a>,
									' a favor de Corredores de Seguros Falabella.',
								]}
							/>
						</div>

						<div className="col-12 mb15 pl0 pr0">
							<Field
								onFocus={handleOnFocus}
								name="news"
								component={FormCheckbox}
								text={[
									'Acepto los términos y condiciones para acceder al ',
									<a
										href="https://sfestaticos.blob.core.windows.net/pmc/peru/pdf/programa-cmr-puntos.pdf"
										target="_blank"
										rel="noopener noreferrer"
										key="newsKey"
									>
										Programa de CMR Puntos.
									</a>,
								]}
							/>
						</div>
					</div>
				</div>
				<div
					className="row justify-content-center mt30 buttons-container"
					style={{ display: 'none' }}
				>
					<div className="col-12 col-sm-5 col-md-4">
						<Link to="/plans">
							<button
								className="btn white"
								type="button"
								onClick={() =>
									sendUACustomEvent(
										EventNameUA.ClickDatosPersonalesBtnVolver,
										isMoto,
									)
								}
							>
								Volver
							</button>
						</Link>
					</div>
					<div className="col-12 col-sm-5 col-md-4">
						<button
							id="quote"
							className={`btn ${politycs ? 'primary' : 'white'}`}
							type="submit"
							disabled={!politycs}
							ref={submitRef}
							onClick={() =>
								sendUACustomEvent(
									EventNameUA.ClickDatosPersonalesBtnContinuar,
									isMoto,
								)
							}
						>
							Continuar
						</button>
					</div>
				</div>
			</form>
			<ModalAdviser
				isOpen={isOpenModalAdviser}
				handleModal={setIsOpenModalAdviser}
				validateAdviser={validateAdviser}
				saveAdviserCode={saveAdviserCode}
				adviser={adviser}
				resetStateAdviserCode={resetStateAdviserCode}
			/>
		</>
	);
}

function mapStateToProps(state: any) {
	const {
		ValidationForm: {
			data: { documentId },
		},
	} = state;

	return {
		formData: getFormValues('proposal')(state),
		documentId,
	};
}

export default connect(mapStateToProps)(
	reduxForm<IEmitForm, any>({
		enableReinitialize: true,
		form: 'proposal',
		onSubmitFail: focusInputOnError,
		validate,
	})(EmitPersonalInformationForm),
);
