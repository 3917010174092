import { AnyAction } from 'redux';

export const SET_COUPON = 'SET_COUPON';
export const SET_COUPON_ERROR = 'SET_COUPON_ERROR';
export const GET_COUPON = 'GET_COUPON';
export const RESET_COUPON = 'RESET_COUPON';
export const SET_HASH_COUPON = 'SET_HASH_COUPON';
export const COUPON_NOT_USE = 'COUPON_NOT_USE';
export const COUPON_USE = 'COUPON_USE';

export interface ICoupon {
	campaignName: string;
	codeUsed: string;
	discountType: string;
	discountValue: number;
	documentIdentity: string;
	redeemId: string;
	vehicleCategory: string;
	vehiclePlate: string;
}

export const getCoupon = () => ({
	type: GET_COUPON,
});

export const setCoupon = (payload: ICoupon) => ({
	payload,
	type: SET_COUPON,
});

export const setCouponError = (payload: string) => ({
	payload,
	type: SET_COUPON_ERROR,
});

export const resetCoupon = () => ({
	type: RESET_COUPON,
});

export const setHash = (hash: string) => ({
	hash,
	type: SET_HASH_COUPON,
});

export const couponNotUse = () => ({
	type: COUPON_NOT_USE,
});

export const couponUse = () => ({
	type: COUPON_USE,
});

const initialState = {
	data: {
		campaignName: '',
		codeUsed: '',
		discountType: '',
		discountValue: 0,
		documentIdentity: '',
		redeemId: '',
		vehicleCategory: '',
	},
	hash: '',
	errors: {
		coupon: '',
	},
	use: false,
};

export default function couponReducer(state = initialState, action: AnyAction) {
	switch (action.type) {
		case SET_COUPON:
			return {
				...state,
				data: action.payload,
			};
		case COUPON_NOT_USE:
			return {
				...state,
				use: false,
			};
		case COUPON_USE:
			return {
				...state,
				use: true,
			};
		case SET_HASH_COUPON:
			return {
				...state,
				hash: action.hash,
			};
		case RESET_COUPON:
			return initialState;
		case SET_COUPON_ERROR:
			return {
				...state,
				errors: { coupon: action.payload },
			};
		default:
			return state;
	}
}

export const getCouponData = (state: any) => state.Coupon;
