import React from 'react';
import { connect } from 'react-redux';
import { defaultEncrypter } from '../helpers/utils';

import { Dispatch } from 'redux';
import { Container, Fail, Success, ErrorPayment } from '../components';
import { restartFlow } from '../store/Commons';
import TagManager from 'react-gtm-module';

interface IMatchProps {
	path: string;
}
interface IResolveContainerProps {
	email: string;
	proposalId: string;
	paymentMethod?: string;
	match: IMatchProps;
	isMoto?: boolean;
	reset: () => void;
	documentId: string;
}

export function ResolveContainer(props: IResolveContainerProps) {
	const {
		email,
		proposalId,
		reset,
		paymentMethod,
		match: { path },
		isMoto,
		documentId,
	} = props;

	const pathRedirect = path.split('/')[1];

	React.useEffect(() => {
		if (path === '/fail') {
			const tagManagerArgs = {
				dataLayer: {
					event: 'soatPaymentError',
				},
				dataLayerName: 'dataLayer',
			};
			TagManager.dataLayer(tagManagerArgs);
		}
	}, [path, pathRedirect]);

	return (
		<Container>
			{pathRedirect.includes('success') ? (
				<Success
					email={email}
					proposalId={proposalId}
					paymentMethod={paymentMethod}
					reset={reset}
					isMoto={isMoto}
					documentId={documentId}
				/>
			) : pathRedirect.includes('paymentRejected') || pathRedirect.includes('subscriptionError') ? (
				<ErrorPayment isMoto={isMoto} typeError={pathRedirect} documentId={documentId} />
			) : (
				<Fail isMoto={isMoto} documentId={documentId} />
			)}
		</Container>
	);
}

export const mapStateToProps = (state: any, path: any) => {

	const { email, proposalId, paymentMethod, documentId, isMoto } = path.match.params;

	return {
		email: email ? defaultEncrypter.decrypt(email) : '',
		proposalId: proposalId ? defaultEncrypter.decrypt(proposalId) : '',
		paymentMethod: paymentMethod ? defaultEncrypter.decrypt(paymentMethod) : '',
		isMoto: isMoto === 'true' || false,
		documentId: documentId ? defaultEncrypter.decrypt(documentId) : '',
	};
};

export const mapDispatchToProps = (dispatch: Dispatch) => ({
	reset: () => dispatch(restartFlow()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResolveContainer);
