import { resetCoupon } from '../store/Coupon';
import { IValidationForm } from '../store/ValidationForm';
import { setMoto } from '../store/Vehicle';
import asyncValidateCoupon from './asyncValidateCoupon';
import asyncValidatePlate from './asyncValidatePlate';
import { isMoto } from './utils';
import { EventNameUA, sendUACustomEvent } from './gtm';

const asyncValidateStep1 = async (
	values: IValidationForm,
	dispatch: any,
	props: any,
	field: string,
) => {
	switch (field) {
		case 'coupon':
			await asyncValidateCoupon(values, dispatch);
			break;
		case 'plate':
			dispatch(resetCoupon());
			if (values.plate !== '') {
				const motoValidation = isMoto(values.plate);
				dispatch(setMoto(motoValidation));

				if (
					motoValidation &&
					process.env.REACT_APP_MOTO_SKIP_QUOTE === 'true'
				) {
					break;
				}

				sendUACustomEvent(EventNameUA.InputIngresoPlaca, motoValidation);

				await asyncValidatePlate(values);

				if (values.documentId !== '' && values.coupon !== '') {
					await asyncValidateCoupon(values, dispatch);
				}
			}
			break;
		case 'documentId':
			if (
				values.documentId !== '' &&
				values.plate !== '' &&
				values.coupon !== ''
			) {
				await asyncValidateCoupon(values, dispatch);
			}
			break;
		default:
			break;
	}
};

export default asyncValidateStep1;
