import * as React from 'react';
import { WrappedFieldProps } from 'redux-form';
// import Loading from './Loading';

interface IFormInputProps {
	type: string;
	placeholder?: string;
	disabled?: boolean;
	maxlength?: number;
	error?: string;
}

export default function FormInput(props: WrappedFieldProps & IFormInputProps) {
	const {
		input,
		type,
		placeholder = '',
		disabled = false,
		maxlength,
		meta: { touched, error, asyncValidating },
		error: customError
	} = props;
	const hasError = () => {
		return ((touched && error) || customError) ? true : false;
	};

	const errorTextClass = () => {
		return input.name === 'coupon' ? 'invalid-coupon-text' : 'invalid';
	};

	const errorInputClass = () => {
		if (hasError() && input.name === 'coupon') {
			return 'invalid-coupon-input';
		}
	};

	return (
		<>
			<input
				{...input}
				id={input.name}
				type={type}
				placeholder={placeholder}
				maxLength={maxlength}
				disabled={disabled}
				className={`form-control ${asyncValidating ? 'loading' : ''
					} ${errorInputClass()}`}
			/>
			{asyncValidating && <i className="element-spinner" />}
			{hasError() && <small className={errorTextClass()}>{error || customError}</small>}
		</>
	);
}
