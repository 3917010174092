import { push } from 'connected-react-router';
import { AnyAction } from 'redux';
import { reset } from 'redux-form';
import { put, select, spawn } from 'redux-saga/effects';
import Swal from 'sweetalert2';
import { IValidationForm } from './ValidationForm';
import { setVehicleForm } from './Vehicle';

export interface IFormSteps {
	Quote: IValidationForm;
}
export const NAVIGATE = 'NAVIGATE';
export const SHOW_ERROR = 'SHOW_ERROR';
export const RESET_STATE = 'RESET_STATE';
export const RESTART_FLOW = 'RESTART_FLOW';
export const SET_FORM_DATA = 'SET_FORM_DATA';
export const REDUX_FORM_CHANGE = '@@redux-form/CHANGE';

export const vehicleFormSelector = (state: any) => state.form.vehicle;

export const navigate = (payload: string) => ({
	payload,
	type: NAVIGATE,
});

export const showError = (payload: string) => ({ payload, type: SHOW_ERROR });

export const resetState = () => ({ type: RESET_STATE });

export const restartFlow = () => ({ type: RESTART_FLOW });

export const setFormData = (payload: string) => ({
	payload,
	type: SET_FORM_DATA,
});

export function* doNavigate({ payload }: AnyAction) {
	yield put(push(payload));
}

export function* doError({ payload }: AnyAction) {
	const alert = (args: any) => Swal.fire(args);

	yield spawn(alert, {
		button: 'Entendido',
		imageUrl:
			'https://sfestaticos.blob.core.windows.net/guideline/icons/icon-error.svg',
		imageHeight: 40,
		imageAlt: 'img error',
		text: payload,
		title: 'Ha ocurrido un error',
		confirmButtonColor: '#30B033',
		confirmButtonText: 'Entendido',
	});
}

export function* doRestart() {
	yield put(reset('quote'));
	yield put(reset('proposal'));
	yield put(resetState());
	yield put(push('/'));
}

export function* formChange(action: AnyAction) {
	if (action.meta.form === 'vehicle') {
		const { values } = yield select(vehicleFormSelector);
		yield put(
			setVehicleForm({
				...values,
				modelAsNumber: values.model,
			}),
		);
	}
}
