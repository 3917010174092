import React from 'react';
import IconWarning from '../assets/svg/warning-icon.svg';
import IconInformation from '../assets/svg/information-icon.svg';

interface IInfoBoxProps {
	type: string;
	text: string;
}

interface IIcons {
	warning: string;
	information: string;
	[key: string]: string;
}

const icons: IIcons = {
	warning: IconWarning,
	information: IconInformation,
};

export default function InfoBox(props: IInfoBoxProps) {
	const style = `container-fluid info-box-${props.type} mt10 pb15 pt15 pl0 pr15`;
	return (
		<div className={style}>
			<div className="container text-left">
				<div className="row">
					<div className="col-2 col-md-1">
						<img
							className="icon-warning"
							src={icons[props.type]}
							alt="warning"
							width="24"
						/>
					</div>
					<div className="col-10 col-md-10 col-sm-10 text">{props.text}</div>
				</div>
			</div>
		</div>
	);
}
