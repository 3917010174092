import { takeEvery } from 'redux-saga/effects';
import {
	doError,
	doNavigate,
	doRestart,
	formChange,
	NAVIGATE,
	REDUX_FORM_CHANGE,
	RESTART_FLOW,
	SHOW_ERROR,
} from './Commons';
import {
	doAlignet,
	setEmitPersonalInformationForm,
	doPayment,
	SUBMIT_ALIGNET_FORM,
	SUBMIT_EMIT_PERSONAL_INFO_FORM,
	SET_EMIT_PERSONAL_INFO_FORM,
} from './Emit';
import { SELECT_PLAN, selectPlan } from './Matrix';
import {
	SELECT_PAYMENT_METHOD,
	selectPaymentMethod,
	GET_PAYMENT_METHODS,
	paymentMethods,
	SET_INSTALMENTS_DATA,
	instalments,
} from './Payment';
import {
	departments,
	districts,
	doctypes,
	GET_DEPARTMENTS,
	GET_DISTRICTS,
	GET_DOCTYPES,
	GET_PROVINCES,
	GET_VEHICLE_DEPARTMENTS,
	provinces,
	VALIDATE_PLATE,
	submitToVehicle,
	vehicleDepartments,
} from './ValidationForm';
import {
	brands,
	DO_QUOTE,
	DO_DATA_BY_PROPOSALID,
	GET_VEHICLE_TYPES,
	GET_VEHICLE_USES,
	GET_VEHICLES_BRAND,
	GET_VEHICLES_MODELS,
	infoVehicleToPlate,
	models,
	quote,
	SET_VEHICLE_VALUES,
	types,
	uses,
	renewByProposalId,
} from './Vehicle';
import { validateAdviser, setAdviser, GET_ADVISER_STATUS, GET_ADVISER_CODE } from './Adviser';

import { SUBMIT_FPAY_INTENTION, doIntention, RESET_FLOW_PAYMENT } from './Fpay';

export default function* sagas() {
	yield takeEvery(DO_QUOTE, quote);
	yield takeEvery(DO_DATA_BY_PROPOSALID, renewByProposalId);
	yield takeEvery(SELECT_PLAN, selectPlan);
	yield takeEvery(SELECT_PAYMENT_METHOD, selectPaymentMethod);
	yield takeEvery(NAVIGATE, doNavigate);
	yield takeEvery(SUBMIT_EMIT_PERSONAL_INFO_FORM, doPayment);
	yield takeEvery(SET_EMIT_PERSONAL_INFO_FORM, setEmitPersonalInformationForm);
	yield takeEvery(SUBMIT_ALIGNET_FORM, doAlignet);
	yield takeEvery(SHOW_ERROR, doError);
	yield takeEvery(RESTART_FLOW, doRestart);
	yield takeEvery(RESTART_FLOW, doRestart);
	yield takeEvery(GET_DEPARTMENTS, departments);
	yield takeEvery(GET_VEHICLE_DEPARTMENTS, vehicleDepartments);
	yield takeEvery(GET_VEHICLE_TYPES, types);
	yield takeEvery(GET_DOCTYPES, doctypes);
	yield takeEvery(VALIDATE_PLATE, submitToVehicle);
	yield takeEvery(GET_VEHICLES_BRAND, brands);
	yield takeEvery(GET_VEHICLES_MODELS, models);
	yield takeEvery(SET_VEHICLE_VALUES, infoVehicleToPlate);
	yield takeEvery(GET_VEHICLE_USES, uses);
	yield takeEvery(GET_PROVINCES, provinces);
	yield takeEvery(GET_DISTRICTS, districts);
	yield takeEvery(REDUX_FORM_CHANGE, formChange);
	yield takeEvery(GET_PAYMENT_METHODS, paymentMethods);
	yield takeEvery(SUBMIT_FPAY_INTENTION, doIntention);
	yield takeEvery(RESET_FLOW_PAYMENT, doRestart);
	yield takeEvery(SET_INSTALMENTS_DATA, instalments);
	yield takeEvery(GET_ADVISER_STATUS, validateAdviser);
	yield takeEvery(GET_ADVISER_CODE, setAdviser);
}
