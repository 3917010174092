import { FormErrors } from 'redux-form';
import {
	ceValidator as ce,
	dniValidator as dni,
	isPlateValid,
} from '../helpers/utils';
import { IValidationForm } from '../store/ValidationForm';

const validators: { [key: string]: (data: string) => boolean } = {
	ce,
	dni,
};

export default (values: IValidationForm) => {
	const errors: FormErrors<IValidationForm> = {};

	if (!values.plate) {
		errors.plate = 'Campo obligatorio.';
	} else if (!isPlateValid(values.plate)) {
		errors.plate = 'Formato de placa inválida.';
	}

	if (!values.docType) {
		errors.docType = 'Campo obligatorio.';
	}

	if (!values.vehicleDepartment) {
		errors.vehicleDepartment = 'Campo obligatorio.';
	}

	if (!values.documentId) {
		errors.documentId = 'Campo obligatorio.';
	} else if (!values.docType) {
		errors.documentId = 'Debe seleccionar un tipo de documento.';
	} else if (
		validators[values.docType.toLowerCase()] &&
		!validators[values.docType.toLowerCase()](values.documentId)
	) {
		errors.documentId = `${values.docType.toUpperCase()} inválido.`;
	}

	if (!values.date) {
		errors.date = 'Campo obligatorio.';
	}

	return errors;
};
