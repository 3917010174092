import React, { useEffect } from 'react';
import moto from '../assets/svg/logo-moto.svg';
import auto from '../assets/svg/logo-car.svg';
import {
	EventNameUA,
	PageNameUA,
	PageNameGA4,
	sendUACustomEvent,
	sendUAPageView,
	sendGA4PageView,
	sendGA4FunnelStepViewed,
} from '../helpers/gtm';

interface ISuccessProps {
	email: string;
	proposalId: string;
	paymentMethod?: string;
	isMoto?: boolean;
	reset: () => void;
	documentId: string;
}

export default function Success(props: ISuccessProps) {
	const { email, proposalId, paymentMethod, reset, isMoto, documentId } = props;

	useEffect(() => {
		sendUAPageView(PageNameUA.Paso_VentaSoat, isMoto);
		sendGA4PageView(PageNameGA4.Paso_VentaSoat, documentId);
		sendGA4FunnelStepViewed(PageNameGA4.Paso_VentaSoat, isMoto, documentId);
	}, [paymentMethod, isMoto]);

	const resetFlow = () => {
		sendUACustomEvent(EventNameUA.ClickSuccessBtnSegurosFalabella, isMoto);
		reset();
	};

	return (
		<div>
			<div className="container">
				<div className="row col-12 my-4 mx-0 p-0 justify-content-center message--title mt20">
					<div className="d-flex flex-row align-items-center message--title--image">
						<img
							className="icon-exp mr10"
							src={isMoto ? moto : auto}
							alt="vehicle"
							width={36}
							height={36}
						/>
						<h2>
							<span>Seguro</span> {isMoto ? 'SOAT Moto' : 'SOAT Auto'}
						</h2>
					</div>
				</div>
				<div className="col-12 col-sm-10 mx-auto">
					<div className="row">
						<div className="col-12 col-sm-12 col-md-12 message--icon-container text-center">
							<i className="material-icons message--icon">check</i>
							<h2 className="mt15 mb40 text-green">¡Ya estás protegido!</h2>
						</div>
						<div className="col-12 col-sm-12 col-md-12 message--content text-center">
							<p className="mb20">
								El número de propuesta es{' '}
								<span className="text-bold">{proposalId}</span>
							</p>
							<p className="m-0">
								Te enviaremos una copia de tu contrato a{' '}
								<span className="text-bold">{email}</span>.
							</p>
							<p className="d-inline">
								{' '}
								Asegúrate de revisar tu bandeja principal y spam.{' '}
							</p>
						</div>
					</div>
				</div>
			</div>
			<footer>
				<div className="d-flex justify-content-center mx-auto btn-unique">
					<button type="button" className={`btn white`} onClick={resetFlow}>
						Ir a Seguros Falabella
					</button>
				</div>
			</footer>
			{/* <Crossing /> */}
		</div>
	);
}
