import axios from 'axios';
import { push } from 'connected-react-router';
import { AnyAction } from 'redux';
import { call, put, select } from 'redux-saga/effects';
import { RESET_STATE, showError } from './Commons';

export const SET_EMIT_FORM = 'EMIT_FORM/SET';
export const SUBMIT_ALIGNET_FORM = 'PROPOSALPAYMENT/SUBMIT';
export const SUBMIT_EMIT_PERSONAL_INFO_FORM = 'PROPOSAL/SUBMIT';
export const SET_EMIT_PERSONAL_INFO_FORM = 'PROPOSAL/SET';
export const EMITTING = 'EMITTING';
export const FAIL_EMITTING = 'EMITTING/FAIL';
export const SET_PROPOSAL = 'PROPOSAL/SET';
export const SET_FORM_ERROR = 'SET_EMIT_FORM_ERROR';
export const GET_DISTRICTS = 'EMITING/DISTRICTS';
export const GET_PROVINCES = 'EMITING/PROVINCES';
export const URL_EMIT = '/closure';
export const URL_ALIGNET_PAYMENT = '/alignet/payment';

export interface IEmitForm {
	address: string;
	addressNumber: number;
	birthdate: string;
	department: string;
	district: string;
	email: string;
	maternal: string;
	name: string;
	paternal: string;
	phone: string;
	province: string;
	politycs: boolean;
	news: boolean;
	gender: string;
	relationship: string;
}

export interface IValidationCustomErrors {
	name: string;
	value: string;
}

export interface IProposal {
	errorMessage: string;
	number: number;
}

export const submitEmitPersonalInformationForm = (payload: IEmitForm) => ({
	payload,
	type: SUBMIT_EMIT_PERSONAL_INFO_FORM,
});

export const emitPersonalInformationForm = (payload: IEmitForm) => ({
	payload,
	type: SET_EMIT_PERSONAL_INFO_FORM,
});

export const submitAlignetForm = () => ({
	type: SUBMIT_ALIGNET_FORM,
});

export const emitting = () => ({
	type: EMITTING,
});

export const failEmitting = () => ({
	type: FAIL_EMITTING,
});

export const setEmitForm = (payload: IEmitForm) => ({
	payload,
	type: SET_EMIT_FORM,
});

export const setProposal = (payload: IProposal) => ({
	payload,
	type: SET_PROPOSAL,
});

export const setFormErrors = (payload: IValidationCustomErrors) => ({
	payload,
	type: SET_FORM_ERROR,
});

const initialState = {
	data: {
		address: '',
		addressNumber: 0,
		birthdate: '',
		department: '',
		district: '',
		email: '',
		maternal: '',
		name: '',
		news: false,
		paternal: '',
		phone: '',
		politycs: false,
		province: '',
	},
	emitting: false,
	errors: {
		department: '',
		district: '',
		province: '',
	},
	proposal: {
		errorMessage: '',
		number: 0,
	},
};

// TODO: Ocupar esto de ejemplo
export default function proposal(state = initialState, action: AnyAction) {
	switch (action.type) {
		case SET_EMIT_FORM:
			return {
				...state,
				data: action.payload,
			};
		case EMITTING:
			return {
				...state,
				emitting: true,
			};
		case FAIL_EMITTING:
			return {
				...state,
				emitting: false,
			};
		case SET_PROPOSAL:
			return {
				...state,
				emitting: false,
				proposal: action.payload,
			};
		case RESET_STATE:
			return initialState;
		case SET_FORM_ERROR:
			return {
				...state,
				errors: {
					...state.errors,
					[action.payload.name]: action.payload.value,
				},
			};
		default:
			return state;
	}
}

export function* setEmitPersonalInformationForm({ payload }: AnyAction) {
	try {
		yield put(setEmitForm(payload));
	} catch (error) {
		yield put(failEmitting());
		yield put(showError('Por favor intente nuevamente.'));
	}
}

export function* doPayment({ payload }: AnyAction) {
	try {
		yield put(setEmitForm(payload));
		yield put(push('/payment'));
	} catch (error) {
		yield put(failEmitting());
		yield put(showError('Por favor intente nuevamente.'));
	}
}

export function* doAlignet() {
	try {
		yield put(emitting());
		const state = (yield select()) as unknown as any;
		const {
			Emit: {
				data: {
					email,
					name: names,
					paternal,
					maternal,
					phone,
					birthdate,
					department,
					province,
					district,
					address,
					addressNumber,
					gender,
					relationship,
					news,
					politycs,
				},
			},
			Vehicle: {
				data: {
					use: useCode,
					model,
					modelAsNumber,
					brand: brandCode,
					type: typeCode,
					year,
					seats,
					serial,
				},
				control,
				planSelected,
				quoteId,
				branch,
				consecutive,
				transactionId,
				brands,
				types,
				uses,
			},
			ValidationForm: {
				data: {
					plate,
					documentId,
					docType,
					vehicleDepartment,
					date: startDate,
				},
			},
			Coupon,
			Payment: { paymentMethodSelected },
		} = state;

		const brand = brands.find(
			(elem: { value: any }) => elem.value === brandCode,
		);
		const type = types.find((elem: { value: any }) => elem.value === typeCode);
		const use = uses.find((elem: { value: any }) => elem.value === useCode);

		const alignetData = {
			channel: 'INTERNET',
			executive: '38644',
			payload: {
				agreements: {
					news,
					politycs,
				},
				control,
				transactionId,
				insured: {
					address: {
						area: {
							level1: department,
							level2: province,
							level3: district,
							level4: address,
							level5: addressNumber.toString(),
						},
					},
					birthdate:
						typeof birthdate === 'string'
							? birthdate
							: birthdate.toISOString().split('T')[0],
					document: {
						number: documentId,
						type: docType,
					},
					email,
					maternal,
					names,
					paternal,
					phone,
					gender,
					relationship,
					nationality: 'PE',
				},
				plan: {
					premiums: {
						local: planSelected.premiums.local,
						total: planSelected.premiums.total,
					},
					properties: {
						details: planSelected.properties.details,
						id: planSelected.properties.id,
					},
					company: {
						id: planSelected.company.id,
					},
					quoteId: planSelected.quoteId,
				},
				policy: {
					startDate:
						startDate instanceof Date
							? startDate.toISOString().split('T')[0]
							: startDate,
				},
				quote: {
					branch,
					consecutive,
					id: quoteId,
				},
				vehicle: {
					address: {
						area: {
							level1: vehicleDepartment,
						},
					},
					brand: {
						code: brand.value,
						name: brand.name,
					},
					model: {
						code: modelAsNumber,
						name: model,
					},
					plate,
					seats,
					serial,
					type: {
						code: type.value,
						name: type.name,
					},
					use: {
						code: use.value,
						name: use.name,
					},
					year,
				},
				paymentMethodSelected,
			},
			plan: planSelected.properties.id,
		};

		if (Coupon.use) {
			const coupon = {
				data: Coupon.data,
				hash: Coupon.hash,
			};

			const payloadCoupon = { ...alignetData.payload, coupon };
			alignetData.payload = payloadCoupon;
		}

		try {
			const { data } = yield call(
				axios.post,
				URL_ALIGNET_PAYMENT,
				alignetData,
				{
					headers: { 'x-trace': plate },
          timeout: 20000,
				},
			);
			const div = document.createElement('div');
			div.innerHTML = data.trim();
			document.getElementsByTagName('body')[0].appendChild(div.children[0]);
			(
				document.getElementById('startPaymentGatewayForm') as HTMLFormElement
			).submit();
		} catch (e) {
			window.location.href = '/fail';
		}
	} catch (error) {
		yield put(failEmitting());
		yield put(
			showError(
				'Por favor intente nuevamente y/o si el error persiste puede llamar al número 615 6000 o acercarse a alguna de nuestras sucursales.',
			),
		);
	}
}
