import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IPlan, IVehicleForm, doRenewByProposalId } from '../store/Vehicle';
import Loading from '../components/Loading';

interface IResolveContainerProps {
	reset: () => void;
	selectPlan: (payload: IPlan) => void;
	selectData: (payload: IPlan) => void;
	quote: (data: IVehicleForm) => void;
	model: (data: string) => void;
	brand: (data: string) => void;
	serial: (data: string) => void;
	use: (data: string) => void;
	year: (data: string) => void;
	type: (data: string) => void;
	modelAsNumber: (data: string) => void;
	renewByProposalId: (data: string) => void;
	proposalId: any;
}

export function Renew(props: IResolveContainerProps) {
	const { proposalId, renewByProposalId } = props;
	renewByProposalId(proposalId);
	return (
		<>
			<Loading />
		</>
	);
}

export const mapStateToProps = (_: any, path: any) => {
	const { proposalId } = path.match.params;
	return {
		proposalId: window.atob(`${proposalId}`),
	};
};

export const mapDispatchToProps = (dispatch: Dispatch) => ({
	renewByProposalId: (data: string) => dispatch(doRenewByProposalId(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Renew);
