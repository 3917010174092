import { IOptions } from '../components/FormSelect';
import { IEmitForm, IProposal } from '../store/Emit';
import {
	IValidationCustomErrors,
	IValidationForm,
} from '../store/ValidationForm';
import { IVehicleForm } from '../store/Vehicle';
import FPAY_CMR_Credit from '../assets/svg/FPAY_CMR_Credit.svg';
import FPAY_CMR_Debit from '../assets/svg/FPAY_CMR_Debit.svg';
import FPAY_ThirdParties_Credit from '../assets/svg/FPAY_ThirdParties_Credit.svg';
import FPAY_ThirdParties_Debit from '../assets/svg/FPAY_ThirdParties_Debit.svg';

import FPAY_Wallet from '../assets/svg/FPAY_Wallet.svg';
import logoCard from '../assets/svg/logo-card.svg';
import Company_LaPositiva from '../assets/svg/Company_LaPositiva.svg';

export const quoteForm: IValidationForm = {
	date: '2020-02-02',
	docType: 'dni',
	documentId: '12345678',
	plate: 'ABS133',
	quotePrivacyPolicies: false,
	vehicleDepartment: '14',
	coupon: '',
};

export const vehicleForm: IVehicleForm = {
	brand: '2005',
	model: 'ABL 150 (N)',
	modelAsNumber: '11111',
	seats: '4',
	serial: '12345678901234567',
	type: '16',
	use: '1',
	year: '2019',
	engineSerial: 'sdsds',
};

export const vehicleUsesResponse: IOptions[] = [
	{ code: '1', name: 'PARTICULAR' },
	{ code: '4', name: 'CARGA' },
	{ code: '10', name: 'TAXI' },
];

export const motoUseResponseMapped: IOptions[] = [
	{ value: '1', name: 'PARTICULAR' },
];

export const vehicleUsesResponseMapped: IOptions[] = [
	{ value: '1', name: 'PARTICULAR' },
	{ value: '4', name: 'CARGA' },
	{ value: '10', name: 'TAXI' },
];

export const vehicleTypesResponse: any[] = [
	{
		brand: { code: '2005' },
		model: { code: '6' },
		type: { code: '16', name: 'CAMIONETA PICK UP' },
	},
];

export const vehicleTypesResponseMapped: IOptions[] = [
	{
		name: 'CAMIONETA PICK UP',
		value: '16',
	},
];

export const motoTypesResponseMapped: IOptions[] = [
	{
		name: 'MOTOCICLETA / VEH. MENOR',
		value: '004007',
	},
];

export const vehicleBrandsResponse: IOptions[] = [
	{ code: '2005', name: 'ABL (N)' },
	{ code: '2006', name: 'AC (N)' },
	{ code: '2010', name: 'ACURA (N)' },
];

export const vehicleBrandsResponseMapped: IOptions[] = [
	{ value: '2005', name: 'ABL (N)' },
	{ value: '2006', name: 'AC (N)' },
	{ value: '2010', name: 'ACURA (N)' },
];

export const vehicleModelsResponse: IOptions[] = [
	{ code: '1', name: 'ABL 150 (N)' },
	{ code: '2', name: 'SMASH (N)' },
];

export const vehicleModelsResponseMapped: IOptions[] = [
	{ value: '1', name: 'ABL 150 (N)' },
	{ value: '2', name: 'SMASH (N)' },
];

export const plan = {
	company: {
		id: '29',
		image: '/fpro-auto-comparador-portlet/images/lgt/falabella.jpg',
		name: 'METLIFE CHILE CIA. SEGUROS DE VIDA S.A.',
	},
	premiums: {
		annual: 8.268,
		local: 19145,
		monthly: 0.689,
		period: 'ANUAL',
		total: 0,
	},
	properties: {
		benefits: 'Sin Beneficios',
		campaign: null,
		characteristics: null,
		deductible: 0,
		details: 'Plan Unico',
		id: 'VML5C',
		name: 'COMPLEMENTARIO S/CONSUTA 50%',
	},
};

export const planWithConsultation = {
	...plan,
	beneficiaryCap: 250,
	beneficsPdf:
		'https://sfestaticos.blob.core.windows.net/pmc/salud/assets/pdf/PROPUESTA_GENERICA_VML5C1.pdf',
	consultation: true,
	deductible: '1,5',
	eventCap: 50,
	plan_id: 'VML5C1',
	refund: 50,
};

export const planWithoutConsultation = {
	...plan,
	beneficiaryCap: 250,
	beneficsPdf:
		'https://sfestaticos.blob.core.windows.net/pmc/salud/assets/pdf/PROPUESTA_GENERICA_VML5C.pdf',
	consultation: false,
	deductible: '1,5',
	eventCap: 50,
	plan_id: 'VML5C',
	refund: 50,
};

export const quoteRequest = {
	branch: '51',
	channel: 'INTERNET',
	executive: '38644',
	payload: {
		insured: {
			birthdate: '1990-10-20T03:00:00.000Z',
			document: {
				number: '6-k',
				type: 'RUT',
			},
			email: 'test@test.cl',
			phone: '+56912345678',
		},
	},
};

export const quoteResponse = {
	branch: 8681,
	consecutive: 0,
	id: 6091175,
	plans: [plan],
};

export const quoteResponseAdapted = {
	...quoteResponse,
	plansWithConsultation: [],
	plansWithoutConsultation: [
		{
			...plan,
			amount: '2500',
			beneficiaryCap: 250,
			beneficsPdf:
				'https://sfestaticos.blob.core.windows.net/pmc/salud/assets/pdf/PROPUESTA_GENERICA_VML5C.pdf',
			consultation: false,
			deductible: '1,5',
			eventCap: 50,
			image: '/fpro-auto-comparador-portlet/images/lgt/falabella.jpg',
			refund: 50,
		},
	],
};

export const emitForm: IEmitForm = {
	address: 'testAddress',
	addressNumber: 1234,
	birthdate: '20-10-1990',
	department: '',
	district: '',
	email: 'mail@mail.com',
	maternal: 'maternal',
	name: 'name',
	news: false,
	paternal: 'paternal',
	phone: '912345678',
	politycs: false,
	province: 'testProvince',
	gender: 'M',
	relationship: 'C',
};

export const planSelected = {
	name: 'COMPLEMENTARIO S/CONSUTA 50%',
	plan_id: 'VML5C',
	premium_monthly: 0.689,
	premium_monthly_CLP: 18993,
};

export const emissionData = {
	branch: '51',
	channel: 'INTERNET',
	control: 'test',
	executive: '38644',
	payload: {
		insured: {
			address: 'testAddress',
			addressNumber: 1234,
			birthdate: '1990-10-20T03:00:00.000Z',
			commune: 'testCommune',
			documentId: '6-k',
			email: 'mail@mail.com',
			gender: 'M',
			maternal: 'maternal',
			name: 'name',
			paternal: 'paternal',
			phone: '+56912345678',
			province: 'testProvince',
			region: 'testRegion',
		},
		payment: {
			cardNumber: '4111110000001111',
			method: 'CMR',
		},
	},
	quote: {
		branch: 8086,
		consecutive: 0,
		quoteId: 1234,
	},
	subProduct: 'VML5C',
};

export const proposalResponse: IProposal = {
	errorMessage: '',
	number: 89678234,
};

export const departmentResponse: IOptions[] = [
	{ code: '1', name: 'AMAZONAS' },
	{ code: '2', name: 'Lima' },
	{ code: '3', name: 'Pasco' },
];

export const departmentListMapped: IOptions[] = [
	{ name: 'AMAZONAS', value: '1' },
	{ name: 'Lima', value: '2' },
	{ name: 'Pasco', value: '3' },
];

export const doctypesResponse: IOptions[] = [
	{
		code: 'CE',
		name: 'Carnet de extranjería',
	},
	{
		code: 'DNI',
		name: 'DNI',
	},
];

export const doctypesResponseMapped: IOptions[] = [
	{
		name: 'Carnet de extranjería',
		value: 'CE',
	},
	{
		name: 'DNI',
		value: 'DNI',
	},
];

export const validationFormCustomErrors: IValidationCustomErrors = {
	name: 'plate',
	value: 'Error dummie',
};

export const vehicleFormCustomErrors: IValidationCustomErrors = {
	name: 'model',
	value: 'Error dummie',
};

const availablePaymentMethod = process.env.REACT_APP_AVAILABLE_PAYMENT_METHOD
	? process.env.REACT_APP_AVAILABLE_PAYMENT_METHOD.split(',')
	: [
			'FPAY_CREDIT_CMR',
			'FPAY_DEBIT_CMR',
			'FPAY_THIRD_PARTIES_CREDIT',
			'FPAY_THIRD_PARTIES_DEBIT',
			'FPAY_WALLET',
			'ALIGNET',
	  ];

const paymentMethod = [
	{
		key: 'FPAY_CREDIT_CMR',
		name: 'FPAY_CREDIT',
		ulrImage: FPAY_CMR_Credit,
		label: 'CMR Falabella',
		priority: 2,
		order: 1,
		upsell: true,
		active: false,
		id: '64946ad8587cceb0da4278bf',
		loyaltyPoints: true,
		promo: false,
		urlPayment: '',
		card_allowed: 'CMR',
	},
	{
		key: 'FPAY_DEBIT_CMR',
		name: 'FPAY_CREDIT',
		ulrImage: FPAY_CMR_Debit,
		label: 'Débito Banco Falabella',
		priority: 2,
		order: 2,
		upsell: true,
		active: false,
		id: '64946ad8587cceb0da4278bf',
		loyaltyPoints: true,
		promo: false,
		urlPayment: '',
		card_allowed: 'CMR',
	},
	{
		key: 'FPAY_THIRD_PARTIES_CREDIT',
		name: 'FPAY_CREDIT',
		ulrImage: FPAY_ThirdParties_Credit,
		label:
			'Otras tarjetas de crédito (Visa, Mastercard, American Express, Diners)',
		priority: 2,
		order: 3,
		upsell: true,
		active: false,
		id: '64946ad8587cceb0da4278bf',
		loyaltyPoints: true,
		promo: false,
		urlPayment: '',
		card_allowed: 'NO_CMR',
	},
	{
		key: 'FPAY_THIRD_PARTIES_DEBIT',
		name: 'FPAY_CREDIT',
		ulrImage: FPAY_ThirdParties_Debit,
		label:
			'Otras tarjetas de débito (Visa, Mastercard, American Express, Diners)',
		priority: 2,
		order: 4,
		upsell: true,
		active: false,
		id: '64946ad8587cceb0da4278bf',
		loyaltyPoints: true,
		promo: false,
		urlPayment: '',
		card_allowed: 'NO_CMR',
	},
	{
		key: 'FPAY_WALLET',
		name: 'FPAY_WALLET',
		ulrImage: FPAY_Wallet,
		label: 'Pagar con App Fpay',
		priority: 1,
		order: 5,
		upsell: false,
		active: false,
		id: '5fdb5bcdd302cc639296581f',
		loyaltyPoints: false,
		promo: false,
		urlPayment: '',
	},
	{
		key: 'ALIGNET',
		name: 'ALIGNET',
		ulrImage: logoCard,
		label: 'Otras tarjetas de crédito y débito',
		priority: 6,
		order: 6,
		upsell: false,
		active: false,
		id: '59e8b6fe84ce05148c290d0d',
		loyaltyPoints: true,
		promo: false,
		urlPayment: '',
	},
];

export const paymentMethodsResponse: any = {
	data: paymentMethod.map((p) => {
		const isActive = availablePaymentMethod.some((a) => a === p.key);
		return isActive
			? {
					...p,
					active: true,
			  }
			: p;
	}),
};

export const dataQuotes: any = {
	moto: [
		{
			company: {
				id: '0',
				image: Company_LaPositiva,
				name: 'La Positiva',
			},
			premiums: {
				annual: '',
				local: `Desde s/${process.env.REACT_APP_MOTO_DEFAULT_PRICE}`,
				monthly: '',
				period: '',
				total: `Desde s/${process.env.REACT_APP_MOTO_DEFAULT_PRICE}`,
			},
			properties: {
				benefits: '',
				campaign: '',
				characteristics: '',
				deductible: '',
				details: '',
				id: '',
				name: '',
			},
			redirect: `https://api.whatsapp.com/send/?phone=${process.env.REACT_APP_WHATSAPP}&text=%C2%A1Hola%21+Estoy+buscando+renovar+mi+SOAT+Moto+con+La+Positiva.&type=phone_number&app_absent=0`,
			type: 'default',
			quoteId: 'default',
		},
	],
};

const intalmentsRange = (start: number, end: number) => {
	return new Array(end - start + 1).fill(null).map((_, i) => {
		const instalment = {
			name: i + start === 1 ? 'Sin cuotas' : `${i + start} cuotas`,
			value: `${i + start}`,
		};

		return instalment;
	}) as IOptions[];
};

export const dataInstallments: IOptions[] = intalmentsRange(
	1,
	Number(process.env.REACT_APP_CHECKOUT_INSTALMENTS_MAX) || 12,
);
