import React from 'react';
import { Link } from 'react-router-dom';
import { EventNameUA, sendUACustomEvent } from '../helpers/gtm';
import { IPlan } from '../store/Vehicle';

export interface IPlanNavbarProps {
	onLineViewChange: (payload: boolean) => void;
	plate: string;
	use: string;
	type: string;
	brand: string;
	model: string;
	date: string;
	serial: string;
	seats: string;
	year: string;
	isMoto: boolean;
	plans: IPlan[];
}

export default function PlansNavbar(props: IPlanNavbarProps) {
	const { plate, brand, model, date, isMoto, plans } = props;

	const getDate = (dateParam: any) => {
		const existDate = !!dateParam;
		return existDate && typeof dateParam !== 'string'
			? new Date(dateParam)
					.toISOString()
					.split('T')[0]
					.split('-')
					.reverse()
					.join('-')
			: dateParam.split('-').reverse().join('-');
	};

	const withPlans = plans.length > 0;

	return (
		<section className="container-fluid bg-white card-resume">
			{withPlans && (
				<h2>
					<span className="jump"> Revisa tus datos y </span>
					<span className="jump">{`selecciona tu SOAT ${
						isMoto ? 'Moto' : 'Auto'
					}`}</span>
				</h2>
			)}

			{!withPlans && (
				<h2>
					<span className="jump"> Lamentablemente no </span>
					<span className="jump"> hemos encontrado opciones para ti </span>
				</h2>
			)}

			<div className="d-flex container justify-content-between card-resume--box">
				<div className="d-flex flex-column pl-1">
					<p className="mb10 px-16">
						Marca:
						<span className="info-bold"> {brand}</span>
					</p>
					<div className="mb10 px-16">
						Modelo:
						<span className="info-bold"> {model} </span>
					</div>
					<p className="mb10 px-16">
						Placa:
						<span className="info-bold"> {plate.toUpperCase()}</span>
					</p>
					<p className="mb0 px-16">
						Inicio vigencia:
						<span className="info-bold"> {getDate(date)}</span>
					</p>
				</div>

				<div>
					<Link to="/vehicle">
						<button
							className="btn-edit"
							type="button"
							onClick={() => {
								sendUACustomEvent(EventNameUA.ClickEditarInformacion, isMoto);
							}}
						>
							<i className="material-icons d-inline px-12 pr-1 pt-1">edit</i>
							Editar
						</button>
					</Link>
				</div>
			</div>
		</section>
	);
}
