import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import {
	Container,
	EmitPersonalInformationForm,
	EmitResumeForm,
	Loading,
} from '../components/';
import { IOptions } from '../components/FormSelect';
import { ICoupon } from '../store/Coupon';
import { IAdviserState, getAdviserStatus, setAdviserCode, resetAdviserState } from '../store/Adviser';
import { IEmitForm, submitAlignetForm, submitEmitPersonalInformationForm } from '../store/Emit';
import { getInsuredDepartments, getInsuredDistricts, getInsuredProvinces } from '../store/ValidationForm';
import {
	PageNameUA,
	PageNameGA4,
	sendUAPageView,
	sendGA4PageView,
	sendGA4FunnelStepViewed,
} from '../helpers/gtm';

interface IProposalProps {
	getDepartments: () => void;
	getDistricts: (provinceCode: string) => void;
	getProvinces: (departmentCode: string) => void;
	back: () => void;
	emit: (payload: IEmitForm) => void;
	alignet: (payload: IEmitForm) => void;
	savePersonalInformation: (payload: IEmitForm) => void;
	initialValues: any;
	local: string;
	total: string;
	emitting: boolean;
	departments?: IOptions[];
	districts?: IOptions[];
	provinces?: IOptions[];
	errors: IEmitForm;
	brand: string;
	model: string;
	year: string;
	use: string;
	plate: string;
	coupon: ICoupon;
	couponUse: boolean;
	isMoto: boolean;
	validateAdviser: (adviserCode: string) => void;
	saveAdviserCode: (adviserCode: string) => void;
	adviser: IAdviserState;
	resetStateAdviserCode: () => void;
	documentId: string;
}

export function Emit(props: IProposalProps) {
	const {
		back,
		alignet,
		savePersonalInformation,
		initialValues,
		local,
		total,
		brand,
		model,
		use,
		year,
		emitting,
		departments,
		districts,
		provinces,
		getDepartments,
		getProvinces,
		getDistricts,
		errors,
		plate,
		coupon,
		couponUse,
		isMoto,
		validateAdviser,
		saveAdviserCode,
		adviser,
		resetStateAdviserCode,
		documentId,
	} = props;
	const [page, setPage] = useState(1);
	const [politycs, setPolitycs] = useState(false);
	const submitRef = React.useRef<
		React.MutableRefObject<HTMLButtonElement> | undefined
	>();

	const onChangePolicies = () => {
		setPolitycs(!politycs);
	};

	useEffect(() => {
		getDepartments();
		sendUAPageView(PageNameUA.Paso_DatosAsegurado, isMoto);
		sendGA4PageView(PageNameGA4.Paso_DatosAsegurado, documentId);
		sendGA4FunnelStepViewed(PageNameGA4.Paso_DatosAsegurado, isMoto, documentId);
	}, [getDepartments, isMoto]);

	const nextPage = (payload: IEmitForm) => {
		savePersonalInformation(payload);
		setPage(page + 1);
	};

	const returnPage = () => {
		setPage(1);
	};

	const headerClassByPage = (): string =>
		page === 3
			? 'text-center mt60 mb50 d-none'
			: 'col-12 col-sm-8 col-md-7 col-lg-6 mx-auto mt60 mb50';

	return (
		<div>
			<Container>
				{!emitting && (
					<div className="relative">
						<div className="container mb50 subscription">
							<div className="row">
								<div className="col-12">
									{!emitting && (
										<h1 className={headerClassByPage()}>
											Completa la información
										</h1>
									)}
									<div className="col-12 mt50">
										{page === 1 && (
											<EmitPersonalInformationForm
												isMoto={isMoto}
												onChangePolicies={onChangePolicies}
												onSubmit={nextPage}
												back={back}
												initialValues={initialValues}
												departments={departments}
												districts={districts}
												provinces={provinces}
												getProvinces={getProvinces}
												getDistricts={getDistricts}
												errors={errors}
												submitRef={submitRef}
												politycs={politycs}
												validateAdviser={validateAdviser}
												saveAdviserCode={saveAdviserCode}
												adviser={adviser}
												resetStateAdviserCode={resetStateAdviserCode}
											/>
										)}
										{page === 2 && (
											<EmitResumeForm
												onSubmit={alignet}
												returnPage={returnPage}
												errors={errors}
												local={local}
												brand={brand}
												model={model}
												year={year}
												use={use}
												plate={plate}
												total={total}
												coupon={coupon}
												couponUse={couponUse}
											/>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
				{emitting && <Loading />}
			</Container>
			<footer>
				<div className="d-flex flex-row justify-content-center">
					<div className="btn-back">
						<Link to="/plans">
							<button className="btn white" type="button">
								<i className="material-icons align-middle">
									keyboard_arrow_left
								</i>
							</button>
						</Link>
					</div>
					<div className="btn-next">
						<button
							id="quote"
							className={`btn ${politycs ? 'primary' : 'white'}`}
							onClick={() =>
								(submitRef.current as unknown as HTMLButtonElement).click()
							}
							disabled={false}
						>
							Continuar
						</button>
					</div>
				</div>
			</footer>
		</div>
	);
}

export const mapStateToProps = (state: any) => {
	const {
		ValidationForm: {
			data: { plate, documentId },
			departments,
			provinces,
			districts,
			errors,
		},
		Vehicle: {
			data: { use, model, brand, year },
			brands,
			uses,
			models,
			planSelected: {
				premiums: { local, total },
			},
			isMoto,
		},
		Emit: { emitting, data },
		Coupon,
		Adviser,
	} = state;

	return {
		brand: brands
			? brands.find(({ value }: IOptions) => value === brand).name
			: brands,
		departments,
		districts,
		emitting,
		errors,
		initialValues: {
			...{
				birthdate: '',
				email: '',
				name: '',
				phone: '',
			},
			...data,
		},
		local,
		total,
		model: models
			? models.find(({ value }: IOptions) => value === model).name
			: model,
		plate,
		provinces,
		use: uses ? uses.find(({ value }: IOptions) => value === use).name : use,
		year,
		coupon: Coupon.data,
		couponUse: Coupon.use,
		isMoto,
		adviser: Adviser,
		documentId,
	};
};

export const mapDispatchToProps = (dispatch: Dispatch) => ({
	alignet: () => dispatch(submitAlignetForm()),
	getDepartments: () => dispatch(getInsuredDepartments()),
	getDistricts: (provinceCode: string) =>
		dispatch(getInsuredDistricts({ provinceCode })),
	getProvinces: (departmentCode: string) =>
		dispatch(getInsuredProvinces({ departmentCode })),
	savePersonalInformation: (payload: IEmitForm) =>
		dispatch(submitEmitPersonalInformationForm(payload)),
	validateAdviser: (adviserCode: string) =>
		dispatch(getAdviserStatus({adviserCode})),
	saveAdviserCode: (adviserCode: string) =>
		dispatch(setAdviserCode(adviserCode)),
	resetStateAdviserCode: () => dispatch(resetAdviserState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Emit);
