import * as React from 'react';
import { Element } from 'react-scroll';
import Header from './Header';
import ScrollUpButton from './ScrollUpButton';

interface IContainerProps {
	children: React.ReactNode;
}

export default function Container(props: IContainerProps) {
	const { children } = props;

	return (
		<div className="content-body">
			<Element name="index" />
			<Header />
			{children}
			<ScrollUpButton />
		</div>
	);
}
