import React, { useEffect } from 'react';
import {
	PageNameUA,
	PageNameGA4,
	sendUAPageView,
	sendGA4PageView,
	sendGA4FunnelStepViewed,
	sendGA4ErrorTriggeredEvent,
} from '../helpers/gtm';
import errorPayment from '../assets/svg/errorPayment.svg';
import whatsapp from '../assets/svg/whatsapp.svg';

interface IFailProps {
	isMoto?: boolean;
	typeError: string;
	documentId: string;
}

const PaymentRejected = () => {
	return (
		<div>
			<p className="text">
				¡Lo sentimos! No hemos podido concluir con la contratación de tu SOAT.
			</p>
			<p className="text">Revisa que tu tarjeta:</p>
			<ul>
				<li className="text">Tenga saldo suficiente.</li>
				<li className="text">Se encuentre activa para compras por internet</li>
			</ul>
			<p className="text">Luego intenta nuevamente.</p>
		</div>
	);
};

const SubscriptionError = () => {
	return (
		<div>
			<p className="text">
				¡Lo sentimos! Ha ocurrido un error durante la contratación de tu SOAT.
			</p>
			<p className="text">
				Realizaremos la devolución del cobro a a tu medio de pago en un máximo
				de 30 días calendario.
			</p>
			<p className="text font-weight-bold">
				Ante cualquier duda, escríbenos a nuestro Whatsapp
			</p>
			<div className="col-12 col-sm-12 col-md-12 message--content text-center">
				<div className="row justify-content-md-center">
					<img
						className="mt10 mr10"
						src={whatsapp}
						alt="whatsapp"
						width={30}
						height={30}
					/>
					<h2 className="mt15 mb40 text-green">
						<a
							href={`https://wa.me/${process.env.REACT_APP_WHATSAPP}`}
							target="_blank"
							rel="noopener noreferrer"
						>
							+{`${process.env.REACT_APP_WHATSAPP}`}
						</a>
					</h2>
				</div>
				<p className="text">Lunes a sábado de 9:00 a 20:00 hrs.</p>
			</div>
		</div>
	);
};

interface IErrors {
	paymentRejected: JSX.Element;
	subscriptionError: JSX.Element;
	[key: string]: any;
}

const errors: IErrors = {
	paymentRejected: <PaymentRejected />,
	subscriptionError: <SubscriptionError />,
};

export default function ErrorPayment(props: IFailProps) {
	const { typeError, isMoto, documentId } = props;
	useEffect(() => {
		sendUAPageView(PageNameUA.Paso_Error, isMoto);
		sendGA4PageView(PageNameGA4.Paso_Error, documentId, typeError);
		sendGA4FunnelStepViewed(PageNameGA4.Paso_Error, isMoto, documentId);
		sendGA4ErrorTriggeredEvent(typeError, documentId);
	}, [isMoto]);

	return (
		<div>
			<div className="container">
				<div className="col-12 col-sm-10 mx-auto">
					<div className="row">
						<div className="col-12 col-sm-12 col-md-12 message--icon-container text-center mt20">
							<img
								className="icon-exp mr10"
								src={errorPayment}
								alt="vehicle"
								width={149}
								height={87}
							/>
							<h2 className="mt15 mb40 text-black">Error al contratar</h2>
						</div>
						<div className="col-8 mx-auto">
							<div>{errors[typeError]}</div>
						</div>
					</div>
				</div>
			</div>
			<footer>
				<div className="d-flex justify-content-center mx-auto btn-unique">
					<button
						className="btn primary"
						onClick={() => (window.location.href = '/')}
					>
						Entendido
					</button>
				</div>
			</footer>
		</div>
	);
}
