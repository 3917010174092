import * as React from 'react';
import NumberFormat from 'react-number-format';
import { WrappedFieldProps } from 'redux-form';

const containerClassName = (isPhone: boolean) => (isPhone ? 'input-group' : '');
const errorClassName = (isPhone: boolean) =>
	isPhone ? 'invalid w-100' : 'invalid';

interface IFormNumberProps {
	placeholder?: string;
	disabled?: boolean;
	inputFormat: string;
	mask?: string[];
	isPhone?: boolean;
}

export default function FormNumber(
	props: WrappedFieldProps & IFormNumberProps,
) {
	const {
		input,
		placeholder = '',
		disabled = false,
		inputFormat,
		mask = [],
		isPhone = false,
		meta: { touched, error },
	} = props;

	return (
		<div className={containerClassName(isPhone)}>
			{isPhone && (
				<div className="input-group-prepend">
					<span className="input-group-text" id="basic-addon1">
						+51
					</span>
				</div>
			)}
			<NumberFormat
				{...input}
				id={input.name}
				className="form-control"
				placeholder={placeholder}
				disabled={disabled}
				format={inputFormat}
				mask={mask}
			/>
			{touched && error && (
				<small className={errorClassName(isPhone)}>{error}</small>
			)}
		</div>
	);
}
