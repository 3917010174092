import * as React from 'react';
import HeaderLogo from './HeaderLogo';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Stepper from './Stepper';

function Header(props: RouteComponentProps<any>) {
	const {
		location: { pathname },
	} = props;

	const withStepper = (url: string): boolean =>
		['/vehicle', '/plans', '/emit', '/payment'].includes(url) ? true : false;

	return (
		<header className="header">
			{withStepper(pathname) ? <Stepper /> : <HeaderLogo />}
		</header>
	);
}

export default withRouter(Header);
