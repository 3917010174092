import React, { useEffect } from 'react';
import {
	PageNameUA,
	PageNameGA4,
	sendUAPageView,
	sendGA4PageView,
	sendGA4FunnelStepViewed,
} from '../helpers/gtm';

interface IFailProps {
	isMoto?: boolean;
	documentId: string;
}

export default function Fail(props: IFailProps) {
	const { isMoto, documentId } = props;

	useEffect(() => {
		sendUAPageView(PageNameUA.Paso_Error, isMoto);
		sendGA4PageView(PageNameGA4.Paso_Error, documentId);
		sendGA4FunnelStepViewed(PageNameGA4.Paso_Error, isMoto, documentId);
	}, [isMoto]);

	return (
		<div>
			<div className="container">
				<div className="row">
					<div className="col-12 col-md-9 wizard-card message message__error mt50">
						<div className="row">
							<div className="col-12 text-center pb-2">
								<h1 className="text-center d-none d-sm-block">Lo sentimos</h1>
							</div>
							<div className="col-12 mx-auto">
								<div className="row">
									<div className="col-12 col-sm-3 col-md-2 text-center message--icon-container">
										<i className="material-icons message--icon ml-3">error</i>
									</div>
									<div className="col-12 col-sm-9 col-md-10">
										<p>
											<span className="jump">¡ Lo sentimos ! &nbsp;</span>
											<span className="jump">Ha ocurrido un error. </span>
										</p>
										<p>
											En caso hayamos realizado un cobro a tu tarjeta, por favor{' '}
											<br />
											ingresa tu placa en el enlace de &nbsp;
											<a
												href="https://www.apeseg.org.pe/consultas-soat/"
												target="_blank"
												rel="noopener noreferrer"
											>
												APESEG
											</a>
											&nbsp; y valida si tienes tu póliza activa.
										</p>
										<p>
											En caso contrario, comunícate con nosotros por &nbsp;
											<a
												href={`https://api.whatsapp.com/send?phone=+${process.env.REACT_APP_WHATSAPP}&text=!Hola!%20Me%20gustar%C3%ADa%20tener%20mayor%20informaci%C3%B3n%20sobre%20seguros`}
												target="_blank"
												rel="noopener noreferrer"
											>
												Whatsapp al +51 1 615 6000.
											</a>
											&nbsp;
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
