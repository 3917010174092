import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
	getPaymentMethods,
	IPaymentMethod,
	selectedPaymentMethod,
	setInstalmentNull,
	setInstalmentsData,
	setSelectInstalment,
} from '../store/Payment';
import {
	submitFpayIntention,
	setScreenContainerState,
	setFpayLoading,
	clearFpayState,
	setInIframe,
} from '../store/Fpay';
import { submitAlignetForm, emitting as doEmitting } from '../store/Emit';
import { Container, PaymentMethod, Summary } from '../components/';
import { IOptions } from '../components/FormSelect';
import {
	PageNameUA,
	PageNameGA4,
	sendUAPageView,
	sendGA4PageView,
	sendGA4FunnelStepViewed
} from '../helpers/gtm';

interface IPaymentInformation {
	local: string;
	total: string;
	discount: string;
	brand: string;
	model: string;
	plate: string;
	year: string;
	company: string;
	companyImage: string;
	documentNumber: string;
	startDate: string;
	couponUse: boolean;
	coupon: any;
	paymentMethods: any[];
	paymentMethodSelected: IPaymentMethod;
	iframeURL: string;
	loading: boolean;
	screenContainerState: boolean;
	emitting: boolean;
	inIframe: boolean;
	selectPaymentMethod: (payload: IPaymentMethod) => void;
	getPaymentMethodsList: () => void;
	changeScreenContainerState: (screenContainerState: boolean) => void;
	changeLoadingState: (loadingState: boolean) => void;
	createIntention: () => void;
	clearFpayStateAction: () => void;
	setProcessState: (processState: boolean) => void;
	doAlignet: () => void;
	doEmitting: () => void;
	changeInIframe: (inIframe: boolean) => void;
	isMoto: boolean;
	instalmentsData: () => void;
	instalments: any [];
	defaultInstalment: object;
	selectInstalment: (instalment: number) => void;
	instalment: number;
	instalmentNull: () => void;
	documentId: string;
}

export function Payment(props: IPaymentInformation) {
	const {
		local,
		total,
		coupon,
		brand,
		model,
		plate,
		year,
		company,
		companyImage,
		documentNumber,
		startDate,
		couponUse,
		discount,
		paymentMethods = [],
		getPaymentMethodsList,
		paymentMethodSelected,
		selectPaymentMethod,
		iframeURL,
		loading,
		screenContainerState,
		changeScreenContainerState,
		changeLoadingState,
		createIntention,
		clearFpayStateAction,
		doAlignet,
		emitting,
		inIframe,
		changeInIframe,
		isMoto,
		instalmentsData,
		instalments,
		defaultInstalment,
		selectInstalment,
		instalment,
		instalmentNull,
	} = props;

	const [paymentMethodActive, setPaymentMethodSelected] = useState(
		paymentMethodSelected,
	);

	const isMobileDevice = () =>
		/Android|webOS|iPhone|iPad|iyPod|BlackBerry|IEMobile|Opera Mini/i.test(
			navigator.userAgent,
		);

	const setPaymentMethod = (paymentMethod: IPaymentMethod) =>
		setPaymentMethodSelected(paymentMethod);

	const onChangeScreenState = (show: boolean) => {
		changeScreenContainerState(!show);
		changeInIframe(!show);
	};

	useEffect(() => {
		if (
			paymentMethodSelected.key === 'FPAY_DEBIT_CMR' ||
			paymentMethodSelected.key === 'FPAY_THIRD_PARTIES_DEBIT' ||
			paymentMethodSelected.key === 'FPAY_WALLET'
		) {
			createIntention();
		}

		if ((paymentMethodSelected.key === 'FPAY_CREDIT_CMR' || paymentMethodSelected.key === 'FPAY_THIRD_PARTIES_CREDIT') && instalment !== null){
			createIntention();
		}

		if (paymentMethodSelected.key === 'ALIGNET') {
			doAlignet();
		}
	}, [paymentMethodSelected, instalment, createIntention, doAlignet]);

	useEffect(() => {
		sendUAPageView(PageNameUA.Paso_PagarSoat, isMoto);
		sendGA4PageView(PageNameGA4.Paso_PagarSoat, documentNumber);
		sendGA4FunnelStepViewed(PageNameGA4.Paso_PagarSoat, isMoto, documentNumber);
		getPaymentMethodsList();
	}, [getPaymentMethodsList, isMoto]);

	return (
		<Container>
			<div className="container content-payment">
				<div className="row">
					<div
						className={`col-12 col-sm-12 col-md-12 col-lg-8 ${
							isMobileDevice() ? 'px-2' : 'px-3 px-sm-0'
						}`}
					>
						<div className={`bg-white mt-4 payment-methods p-4`}>
							<div>
								<h2
									className="payment line-height-normal mb-5"
									style={{ marginBottom: '-50px' }}
								>
									Elige tu medio de pago
								</h2>
								<p style={{ marginTop: '-20px' }}>
									<strong>Revisa</strong> tu saldo disponible y{' '}
									<strong>activa</strong> tus tarjetas para compras por Internet
									desde la app de tu Banco
								</p>
							</div>
							{paymentMethods.map((paymentMethod) => {
								return (
									paymentMethod.active && (
										<PaymentMethod
											isMoto={isMoto}
											key={paymentMethod.key}
											paymentMethod={paymentMethod}
											price={local}
											iframeURL={iframeURL}
											paymentMethodSelected={paymentMethodActive}
											selectPaymentMethod={selectPaymentMethod}
											setPaymentMethod={setPaymentMethod}
											loading={loading}
											screenContainerState={screenContainerState}
											changeLoadingState={changeLoadingState}
											onChangeScreenState={onChangeScreenState}
											clearFpayState={clearFpayStateAction}
											instalmentsData={instalmentsData}
											instalments={instalments}
											defaultInstalment={defaultInstalment}
											selectInstalment={selectInstalment}
											instalmentNull={instalmentNull}
											documentId={documentNumber}
										/>
									)
								);
							})}
						</div>
					</div>
					<div
						className={`d-flex flex-column col-12 col-sm-12 col-md-12 col-lg-4 ${
							isMobileDevice() ? 'px-2' : 'px-3 px-sm-0'
						}`}
					>
						<div className={`bg-white mt-md-3 p-4`}>
							<Summary
								local={local}
								total={total}
								coupon={coupon}
								discount={discount}
								brand={brand}
								model={model}
								plate={plate}
								year={year}
								company={company}
								companyImage={companyImage}
								documentNumber={documentNumber}
								startDate={startDate}
								couponUse={couponUse}
								paymentMethodSelected={paymentMethodActive}
								doIntention={createIntention}
								doAlignet={doAlignet}
								emitting={emitting}
								loading={loading}
								inIframe={inIframe}
							/>
						</div>
					</div>
				</div>
			</div>
		</Container>
	);
}

export const mapStateToProps = (state: any) => {
	const {
		ValidationForm: {
			data: { plate, documentId, date },
		},
		Vehicle: {
			data: { model, brand, year },
			brands,
			models,
			planSelected: {
				premiums: { local, total },
				company: { name, image },
			},
			isMoto,
		},
		Coupon,
		Payment: { paymentMethodSelected, paymentMethods, instalments, defaultInstalment, instalment },
		Emit: { emitting },
		Fpay: { iframeURL, loading, screenContainerState, inIframe },
	} = state;

	return {
		brand: brands
			? brands.find(({ value }: IOptions) => value === brand).name
			: brands,
		local,
		total,
		discount: Coupon.use ? (total - local).toString() : '0',
		model: models
			? models.find(({ value }: IOptions) => value === model).name
			: model,
		plate,
		year,
		documentNumber: documentId,
		startDate: date,
		coupon: Coupon.data,
		couponUse: Coupon.use,
		company: name,
		companyImage: image,
		paymentMethods,
		paymentMethodSelected,
		instalments,
		defaultInstalment,
		instalment,
		iframeURL,
		loading,
		screenContainerState,
		emitting,
		inIframe,
		isMoto,
	};
};

export const mapDispatchToProps = (dispatch: Dispatch) => ({
	getPaymentMethodsList: () => dispatch(getPaymentMethods()),
	doAlignet: () => dispatch(submitAlignetForm()),

	selectPaymentMethod: (payload: IPaymentMethod) =>
		dispatch(selectedPaymentMethod(payload)),
	changeScreenContainerState: (stateScreenContainer: boolean) =>
		dispatch(setScreenContainerState(stateScreenContainer)),
	changeLoadingState: (stateLoading: boolean) =>
		dispatch(setFpayLoading(stateLoading)),
	changeInIframe: (inIframe: boolean) => dispatch(setInIframe(inIframe)),
	createIntention: () => dispatch(submitFpayIntention()),
	doEmitting: () => dispatch(doEmitting()),
	clearFpayStateAction: () => dispatch(clearFpayState()),
	instalmentsData: () => dispatch(setInstalmentsData()),
	selectInstalment: (instalment: number) => dispatch(setSelectInstalment(instalment)),
	instalmentNull: () => dispatch(setInstalmentNull()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
