import axios from 'axios';
import { AnyAction } from 'redux';
import { call, put, select } from 'redux-saga/effects';

import { IOptions } from '../components/FormSelect';
import { showError } from './Commons';
import { isMobileDevice } from '../helpers/utils';

export const SUBMIT_FPAY_INTENTION = 'INTENTION/SUBMIT';
export const INTENTION_LOADING = 'INTENTION/LOADING';
export const SET_SCREEN_CONTAINER_FPAY = 'MODAL/SET';
export const SET_REDIRECT_URL = 'REDIRECT_URL/SET';
export const SET_INTENTION_ID = 'INTENTION_ID/SET';
export const CLEAR_FPAY_STATE = 'STATE/CLEAR';
export const SET_PROCESS_STATE = 'PROCESS/SET';
export const IN_IFRAME = 'IN_IFRAME/SET';
export const RESET_FLOW_PAYMENT = 'FLOW_PAYMENT/RESET';

export const URL_FPAY_INTENTION = '/fpay/payment';

export const submitFpayIntention = () => ({
	type: SUBMIT_FPAY_INTENTION,
});

export const setRedirectURL = (payload: string) => ({
	payload,
	type: SET_REDIRECT_URL,
});

export const setIntentionId = (payload: string) => ({
	payload,
	type: SET_INTENTION_ID,
});

export const setFpayLoading = (payload: boolean) => ({
	payload,
	type: INTENTION_LOADING,
});

export const setInIframe = (payload: boolean) => ({
	payload,
	type: IN_IFRAME,
});

export const setScreenContainerState = (payload: boolean) => ({
	payload,
	type: SET_SCREEN_CONTAINER_FPAY,
});

export const clearFpayState = () => ({
	type: CLEAR_FPAY_STATE,
});

export const resetFlowPayment = () => ({
	type: RESET_FLOW_PAYMENT,
});

const initialState = {
	iframeURL: '',
	intentionId: '',
	loading: false,
	screenContainerState: false,
	inIframe: false,
};

export function* doIntention() {
	yield put(setFpayLoading(true));
	yield put(setScreenContainerState(true));

	try {
		const state = (yield select()) as unknown as any;
		const {
			Emit: {
				data: {
					email,
					name: names,
					paternal,
					maternal,
					phone,
					birthdate,
					department,
					province,
					district,
					address,
					addressNumber,
					gender,
					relationship,
					news,
					politycs,
				},
			},
			Vehicle: {
				data: {
					use: useCode,
					model,
					modelAsNumber,
					brand: brandCode,
					type: typeCode,
					year,
					seats,
					serial,
				},
				control,
				planSelected,
				quoteId,
				branch,
				consecutive,
				transactionId,
				brands,
				types,
				uses,
			},
			ValidationForm: {
				data: {
					plate,
					documentId,
					docType,
					vehicleDepartment,
					date: startDate,
				},
				districts,
			},
			Coupon,
			Payment: { paymentMethodSelected, instalment },
			Adviser: {
				data: {
					adviserCode
				}
			}
		} = state;

		const brand = brands.find(
			(elem: { value: any }) => elem.value === brandCode,
		);
		const type = types.find((elem: IOptions) => elem.value === typeCode);
		const use = uses.find((elem: IOptions) => elem.value === useCode);
		const districtName = districts.find((d: IOptions) => d.value === district);

		const payload = {
			agreements: {
				news,
				politycs,
			},
			control,
			transactionId,
			insured: {
				address: {
					district: districtName.name,
					area: {
						level1: department,
						level2: province,
						level3: district,
						level4: address,
						level5: addressNumber.toString(),
					},
				},
				birthdate:
					typeof birthdate === 'string'
						? birthdate
						: birthdate.toISOString().split('T')[0],
				document: {
					number: documentId,
					type: docType,
				},
				email,
				maternal,
				names,
				paternal,
				phone,
				gender,
				relationship,
				nationality: 'PE',
			},
			plan: {
				premiums: {
					local: planSelected.premiums.local,
					total: planSelected.premiums.total,
				},
				properties: {
					details: planSelected.properties.details,
					id: planSelected.properties.id,
					name: planSelected.properties.name,
				},
				company: {
					id: planSelected.company.id,
				},
				quoteId: planSelected.quoteId,
			},
			policy: {
				startDate:
					startDate instanceof Date
						? startDate.toISOString().split('T')[0]
						: startDate,
			},
			quote: {
				branch,
				consecutive,
				id: quoteId,
			},
			vehicle: {
				address: {
					area: {
						level1: vehicleDepartment,
					},
				},
				brand: {
					code: brand.value,
					name: brand.name,
				},
				model: {
					code: modelAsNumber,
					name: model,
				},
				plate,
				seats,
				serial,
				type: {
					code: type.value,
					name: type.name,
				},
				use: {
					code: use.value,
					name: use.name,
				},
				year,
			},
			paymentMethodSelected,
			fpayChannel: isMobileDevice() ? 'MOBILE' : 'WEB',
			...(adviserCode && { adviserCode })
		};

		const paymentData = {
			channel: 'INTERNET',
			executive: '38644',
			payload:
				instalment !== null ? { ...payload, instalment } : { ...payload },
			plan: planSelected.properties.id,
		};

		if (Coupon.use) {
			const coupon = {
				data: Coupon.data,
				hash: Coupon.hash,
			};

			const payloadCoupon = { ...paymentData.payload, coupon };
			paymentData.payload = payloadCoupon;
		}

		const { data } = yield call(axios.post, URL_FPAY_INTENTION, paymentData, {
			headers: { 'x-trace': plate },
      timeout: 20000,
		});

		let approvalUrl;
		if (paymentMethodSelected.name === 'FPAY_CREDIT') {
			approvalUrl = data.links.find((link: any) => link.rel === 'tokenize');
		} else {
			approvalUrl = data.links.find((link: any) => link.rel === 'approval_url');
		}
		yield put(setIntentionId(data._id));
		yield put(setRedirectURL(approvalUrl.href));
		yield put(setInIframe(true));
		yield put(setFpayLoading(false));
	} catch (error) {
		yield put(clearFpayState());
		yield put(
			showError(
				'Por favor intente nuevamente y/o si el error persiste puede llamar al número 615 6000 o acercarse a alguna de nuestras sucursales.',
			),
		);
	}
}

export default function fpayReducer(state = initialState, action: AnyAction) {
	switch (action.type) {
		case SET_REDIRECT_URL:
			return {
				...state,
				iframeURL: action.payload,
			};
		case SET_INTENTION_ID:
			return {
				...state,
				intentionId: action.payload,
			};
		case INTENTION_LOADING:
			return {
				...state,
				loading: action.payload,
			};
		case SET_SCREEN_CONTAINER_FPAY:
			return {
				...state,
				screenContainerState: action.payload,
			};
		case IN_IFRAME:
			return {
				...state,
				inIframe: action.payload,
			};
		case CLEAR_FPAY_STATE:
			return { ...initialState };
		default:
			return state;
	}
}
