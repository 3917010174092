import React from 'react';
import MintenanceSVG from '../assets/svg/maintenance.svg';
// import Crossing from './Crossing';

export default function Fail() {
	return (
		<div>
			<div className="container">
				<div className="row">
					<div className="col-12 col-md-10 wizard-card shadow-none message message__error mt50">
						<div className="row">
							<div className="col-12 text-center message--title">
								<h1>Estamos preparando las mejores ofertas para ti.</h1>
							</div>
							<div className="col-12 col-sm-10 mx-auto">
								<div className="row">
									<div className="col-12 text-center">
										<img
											src={MintenanceSVG}
											alt='Mantenimiento'
										/>
									</div>
									<div className="col-12 mt-5 text-center">
										<p>
											¡Pronto podrás obtener tu SOAT aquí!
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<footer>
				<div className="d-flex justify-content-center mx-auto btn-unique">
					<button
						className="btn primary"
						onClick={() => window.open('https://www.segurosfalabella.com.pe', '_blank')}
					>
						Ir a Seguros Falabella
					</button>
				</div>
			</footer>
		</div>
	);
}
