import React from 'react';
import { WrappedFieldProps } from 'redux-form';

const customCheckStyles = (value: boolean) =>
	value ? { backgroundColor: '#30b033', border: 'none' } : {};

const customIStyles = (value: boolean) => (value ? { color: '#FFFFFF' } : {});

interface IFormCheckboxProps {
	text: string;
	newValue: boolean;
}

export default function FormCheckbox(
	props: WrappedFieldProps & IFormCheckboxProps,
) {
	const {
		input,
		text,
		newValue,
		meta: { touched, error },
	} = props;

	if (newValue !== undefined) {
		input.value = newValue;
	}

	return (
		<>
			<label htmlFor={input.name} className="custom-check">
				<div className="custom-check--container">
					<input {...input} id={input.name} type="checkbox" />
					<div
						className="custom-check--bg"
						style={customCheckStyles(input.value)}
					/>
					<i className="material-icons" style={customIStyles(input.value)}>
						check
					</i>
				</div>
				<span>{text}</span>
			</label>
			{touched && error && <small className="invalid">{error}</small>}
		</>
	);
}
