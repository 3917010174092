import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

const baseClass =
	'justify-content-center align-items-center d-flex steps--step';

const stepOneClass = (pathname: string): string =>
	['/', '/vehicle', '/plans', '/emit', '/payment', '/success'].includes(
		pathname,
	)
		? `${baseClass} active`
		: baseClass;

const stepTwoClass = (pathname: string): string =>
	['/emit', '/payment', '/success'].includes(pathname)
		? `${baseClass} active`
		: baseClass;

const stepThreeClass = (pathname: string): string =>
	['/success'].includes(pathname) ? `${baseClass} active` : baseClass;

export function Navbar(props: RouteComponentProps<any>) {
	const {
		location: { pathname },
	} = props;

	return (
		<nav className="col-12 col-md-9 steps">
			<div className="row no-gutters">
				<div className="col-4">
					<div className={stepOneClass(pathname)}>
						<span>1</span>
						<p>Ingresa tus datos</p>
					</div>
				</div>
				<div className="col-4">
					<div className={stepTwoClass(pathname)}>
						<span>2</span>
						<p>Completa tu información</p>
					</div>
				</div>
				<div className="col-4">
					<div className={stepThreeClass(pathname)}>
						<span>3</span>
						<p>Ya estás asegurado</p>
					</div>
				</div>
			</div>
		</nav>
	);
}

export default withRouter(Navbar);
