import React from 'react';
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { FormSelect } from './';

interface IDropdownInstalmentsForm {
	instalments: any [];
	defaultInstalment: object;
    selectInstalment: (instalment: number) => void;
}

const DropdownInstalmentsForm = (props: IDropdownInstalmentsForm & InjectedFormProps<{}, IDropdownInstalmentsForm>) => {

    const {
        handleSubmit,
        instalments,
        selectInstalment,
    } = props;

    const handledChangeInstalment = (event: any) => {
		selectInstalment(Number(event.target.value));
	};

	return(
		<form onSubmit={handleSubmit} noValidate={true}>
			<Field
				name="instalments"
				placeholder={'Seleccione el n° de cuotas'}
				options={instalments}
				component={FormSelect}
                onChange={handledChangeInstalment}
                type='submit'
			/>
            <span 
                style={{
                    fontSize: '14px',
                    display: 'block',
                    lineHeight: '17px',
                    marginTop: '7px',
                    color: '#a6a6a6'
                }}
            > El pago en cuotas genera cobro de intereses.</span>
		</form>
	);
};

export default reduxForm<{}, IDropdownInstalmentsForm>({
	form: 'DropdownInstalmentsForm',
})(DropdownInstalmentsForm);
