import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

function ModalCoverage({ isOpen, toggle, data }: any) {
	const { title, toppings, extra } = data;
	return (
		<Modal
			isOpen={isOpen}
			toggle={() => toggle('0')}
			modalClassName="modal-coverage"
		>
			<i
				className="d-block d-sm-none material-icons"
				onClick={() => toggle('0')}
			>
				arrow_back_ios_new
			</i>
			<ModalHeader toggle={toggle}> {title} </ModalHeader>
			<ModalBody>
				<ul className="list-group">
					{toppings.map((coverage: string, i: number) => (
						<li key={i}> {coverage} </li>
					))}
				</ul>
				{extra && <div dangerouslySetInnerHTML={{ __html: extra }} />}
			</ModalBody>
			<ModalFooter>
				<Button className="d-none d-sm-block" onClick={() => toggle('0')}>
					Entendido
				</Button>
			</ModalFooter>
		</Modal>
	);
}

export default ModalCoverage;
