import * as React from 'react';
import { Route } from 'react-router';
import Emit from './containers/Emit';
import Maintenance from './containers/Maintenance';
import Renew from './containers/Renew';
import Matrix from './containers/Matrix';
import Quote from './containers/Quote';
import Resolve from './containers/Resolve';
import Vehicle from './containers/Vehicle';
import Payment from './containers/Payment';

export default function App() {
	return (
		<>
			<Route exact={true} path="/" component={process.env.REACT_APP_MAINTENANCE_STATUS === 'true' ? Maintenance : Quote} />
			<Route exact={true} path="/cupon=:couponCode" component={Quote} />
			<Route exact={true} path="/patente=:plate" component={Quote} />
			<Route exact={true} path="/vehicle" component={Vehicle} />
			<Route exact={true} path="/plans" component={Matrix} />
			<Route exact={true} path="/emit" component={Emit} />
			<Route exact={true} path="/payment" component={Payment} />
			<Route
				exact={true}
				path="/success/:email?/:proposalId?/:paymentMethod?/:documentId?/:isMoto?"
				component={Resolve}
			/>
			<Route exact={true} path="/fail" component={Resolve} />
			<Route
				exact={true}
				path="/paymentRejected/:email?/:proposalId?/:paymentMethod?/:documentId?/:isMoto?"
				component={Resolve}
			/>
			<Route
				exact={true}
				path="/subscriptionError/:email?/:proposalId?/:paymentMethod?/:documentId?/:isMoto?"
				component={Resolve}
			/>
			<Route exact={true} path="/renew/:proposalId?/" component={Renew} />
		</>
	);
}
