import React, { RefObject } from 'react';
import { Collapse, CardBody, Card, CardHeader } from 'reactstrap';
import { IPaymentMethod } from '../store/Payment';
import { InfoBox, Loading } from '../components';
import ModalFPay from './ModalFPay';
import { EventNameUA, EventNameGA4, sendUACustomEvent, sendGA4UserInteractionEvent } from '../helpers/gtm';
import { isMobileDevice } from '../helpers/utils';
import PaymentInstalments from './PaymentInstalments';

interface IPaymentMethodProps {
	paymentMethod: IPaymentMethod;
	price: string;
	paymentMethodSelected: IPaymentMethod;
	loading: boolean;
	iframeURL: string;
	screenContainerState: boolean;
	selectPaymentMethod: (payload: IPaymentMethod) => void;
	setPaymentMethod: (payload: IPaymentMethod) => void;
	changeLoadingState: (loadingState: boolean) => void;
	onChangeScreenState: (screenState: boolean) => void;
	clearFpayState: () => void;
	isMoto?: boolean;
	instalmentsData: () => void;
	instalments: any [];
	defaultInstalment: object;
	selectInstalment: (instalment: number) => void;
	instalmentNull: () => void;
	documentId: string;
}

interface IIframeFpay {
	iframeStyles: any;
	iframeURL: string;
	iframeRef: any;
	changeLoadingState: (loadingState: boolean) => void;
	onLoadIframe: (
		frame: RefObject<HTMLIFrameElement>,
		eventStateCallback: () => void,
	) => any;
	paymentMethod: string;
}

const IframeFpay = (props: IIframeFpay) => {
	let styelCardForm = {};
	let styleIframe = {};

	styelCardForm = {
		minHeight: (isMobileDevice() && props.paymentMethod !== 'FPAY_THIRD_PARTIES_CREDIT') ? '660px' : '510px',
		position: 'relative',
		paddingBottom: '56.25%',
		overflow: 'hidden',
		...props.iframeStyles,
	};

	styleIframe = {
		minHeight: (isMobileDevice() && props.paymentMethod !== 'FPAY_THIRD_PARTIES_CREDIT') ? '650px' : '500px',
		position: 'absolute',
		overflow: 'hidden',
		border: 0,
		alignSelf: 'center',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		...props.iframeStyles,
	};

	return (
		<div className="card-form" style={styelCardForm}>
			<iframe
				style={styleIframe}
				name="checkoutFrame"
				id="checkoutFrame"
				className="border-0 mt-20"
				scrolling="no"
				sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-top-navigation"
				src={props.iframeURL}
				title="Pay"
				ref={props.iframeRef}
				onClick={() => false}
				onLoad={props.onLoadIframe(props.iframeRef, () =>
					props.changeLoadingState(false),
				)}
			/>
		</div>
	);
};


export default function PaymentMethod(props: IPaymentMethodProps) {
	const {
		paymentMethod,
		price,
		selectPaymentMethod,
		paymentMethodSelected,
		setPaymentMethod,
		loading,
		screenContainerState,
		onChangeScreenState,
		iframeURL,
		changeLoadingState,
		clearFpayState,
		isMoto,
		instalmentsData,
		instalments,
		defaultInstalment,
		selectInstalment,
		instalmentNull,
		documentId,
	} = props;

	const isSelected = paymentMethodSelected.key === paymentMethod.key;
	const toggle = () => {
		instalmentNull();
		clearFpayState();
		selectPaymentMethod(paymentMethod);
		setPaymentMethod(paymentMethod);
		if (paymentMethod.key.toLowerCase().includes('alignet')) {
			sendUACustomEvent(EventNameUA.SelectMetodosPagoAlignet, isMoto);
		}
		if (paymentMethod.key.toLowerCase().includes('fpay_wallet')) {
			sendUACustomEvent(EventNameUA.SelectMetodosPagoFPayWallet, isMoto);
		}
		if (paymentMethod.key.toLowerCase().includes('fpay_credit_cmr')) {
			instalmentsData();
			onChangeScreenState(screenContainerState);
			sendUACustomEvent(EventNameUA.SelectMetodosPagoFPayCreditCmr, isMoto);
			sendGA4UserInteractionEvent(EventNameGA4.SelectMetodosPagoFPayCreditCmr, documentId);
		}
		if (paymentMethod.key.toLowerCase().includes('fpay_debit_cmr')) {
			sendUACustomEvent(EventNameUA.SelectMetodosPagoFPayDebitCmr, isMoto);
			sendGA4UserInteractionEvent(EventNameGA4.SelectMetodosPagoFPayDebitCmr, documentId);
		}
		if (paymentMethod.key.toLowerCase().includes('fpay_third_parties_credit')) {
			instalmentsData();
			onChangeScreenState(screenContainerState);
			sendUACustomEvent(EventNameUA.SelectMetodosPagoFPayThirdPartiesCredit, isMoto);
			sendGA4UserInteractionEvent(EventNameGA4.SelectMetodosPagoFPayThirdPartiesCredit, documentId);
		}
		if (paymentMethod.key.toLowerCase().includes('fpay_third_parties_debit')) {
			sendUACustomEvent(EventNameUA.SelectMetodosPagoFPayThirdPartiesDebit, isMoto);
			sendGA4UserInteractionEvent(EventNameGA4.SelectMetodosPagoFPayThirdPartiesDebit, documentId);
		}
	};

	const onLoadIframe =
		(frame: RefObject<HTMLIFrameElement>, eventStateCallback: () => void) =>
		(event: any) => {
			const current = frame.current;

			if (current) {
				const contentDocument = current.contentDocument || { URL: '' };
				const url: string = contentDocument.URL;

				eventStateCallback();
				if (
					url &&
					(url.split('#')[0].indexOf('fail') > 0 ||
						url.split('#')[0].indexOf('success') > 0 ||
						url.split('#')[0].indexOf('paymentRejected') > 0 ||
						url.split('#')[0].indexOf('subscriptionError') > 0)
				) {
					onChangeScreenState(screenContainerState);
					window.location.href = url;
				}
			}
		};

	const iframeRef = React.useRef<HTMLIFrameElement>(null);
	const iframeStyles = loading ? ({ display: 'none' } as const) : {};

	return (
		<Card
			className={`payment-method ${isSelected ? 'selected' : ''}`}
			key={paymentMethod.name}
		>
			<CardHeader onClick={toggle} data-event={paymentMethod}>
				<div className="col-10 p-0">
					<div className="title">
						<img
							src={paymentMethod.ulrImage}
							alt={paymentMethod.name}
							width="35px"
						/>
						<div className="text">
							<span className="main-title">
								<b>{paymentMethod.label}</b>
							</span>
							{paymentMethod.key === 'FPAY_WALLET' && !isSelected && (
								<span className="subtitle">
									Muy fácil. Continua tu pago usando tu app Fpay.
								</span>
							)}
							{paymentMethod.key === 'FPAY_WALLET' && isSelected && (
								<span className="subtitle">
									En el próximo paso, deberás <b>escanear el código QR</b> desde
									tu celular, en tu aplicación y realizar el pago.
								</span>
							)}
							{paymentMethod.key === 'FPAY_WALLET' && isSelected && (
								<span className="tip">
									* Recuerda activar tu tarjeta para compras por internet y así
									evitar problemas con el pago.
								</span>
							)}
							{paymentMethod.key === 'ALIGNET' && isSelected && (
								<span className="subtitle mt-3">
									Si eliges este medio de pago serás redirigido a la pasarela de
									pago. Tu orden de pago no estará ingresada hasta que realices
									el pago.
								</span>
							)}
						</div>
					</div>
				</div>
				<div className="col-2 p-0 price my-auto">
					<label>S/ {price}</label>
				</div>
			</CardHeader>

			<Collapse isOpen={screenContainerState}>
				{paymentMethod.key === 'FPAY_CREDIT_CMR' &&
					paymentMethodSelected.key === 'FPAY_CREDIT_CMR' && (
						<CardBody>
							<InfoBox
								type="information"
								text={
									'Si pagas con CMR, el DNI del titular de la tarjeta debe ser igual al que ingresaste para cotizar.'
								}
							/>
							<br/>
							<PaymentInstalments
								instalments={instalments}
								defaultInstalment={defaultInstalment}
								selectInstalment={selectInstalment}
							/>
							<br/>
							{loading && <Loading />}
							{	iframeURL &&
								<IframeFpay
									iframeStyles={iframeStyles}
									iframeURL={iframeURL}
									iframeRef={iframeRef}
									onLoadIframe={onLoadIframe}
									changeLoadingState={changeLoadingState}
									paymentMethod={paymentMethod.key}
								/>
							}
						</CardBody>
					)}
			</Collapse>

			<Collapse isOpen={screenContainerState}>
				{(paymentMethod.key === 'FPAY_DEBIT_CMR') &&
					(paymentMethodSelected.key === 'FPAY_DEBIT_CMR') && (
						<CardBody>
							<InfoBox
								type='information'
								text={
									'Si pagas con CMR, el DNI del titular de la tarjeta debe ser igual al que ingresaste para cotizar.'
								}
							/>
							<br/>
							{loading && <Loading />}
							{	iframeURL &&
								<IframeFpay
									iframeStyles={iframeStyles}
									iframeURL={iframeURL}
									iframeRef={iframeRef}
									onLoadIframe={onLoadIframe}
									changeLoadingState={changeLoadingState}
									paymentMethod={paymentMethod.key}
								/>
							}
						</CardBody>
					)}
			</Collapse>

			<Collapse isOpen={screenContainerState}>
				{(paymentMethod.key === 'FPAY_THIRD_PARTIES_CREDIT') &&
					(paymentMethodSelected.key === 'FPAY_THIRD_PARTIES_CREDIT') && (
						<CardBody>
							<PaymentInstalments
								instalments={instalments}
								defaultInstalment={defaultInstalment}
								selectInstalment={selectInstalment}
							/>
							{loading && <Loading />}
							{	iframeURL &&
								<IframeFpay
									iframeStyles={iframeStyles}
									iframeURL={iframeURL}
									iframeRef={iframeRef}
									onLoadIframe={onLoadIframe}
									changeLoadingState={changeLoadingState}
									paymentMethod={paymentMethod.key}
								/>
							}
						</CardBody>
					)}
			</Collapse>

			<Collapse isOpen={screenContainerState}>
				{(paymentMethod.key === 'FPAY_THIRD_PARTIES_DEBIT') &&
					(paymentMethodSelected.key === 'FPAY_THIRD_PARTIES_DEBIT') && (
						<CardBody>
							{loading && <Loading />}
							<IframeFpay
								iframeStyles={iframeStyles}
								iframeURL={iframeURL}
								iframeRef={iframeRef}
								onLoadIframe={onLoadIframe}
								changeLoadingState={changeLoadingState}
								paymentMethod={paymentMethod.key}
							/>
						</CardBody>
					)}
			</Collapse>

			{paymentMethod.key === 'FPAY_WALLET' &&
				paymentMethodSelected.key === 'FPAY_WALLET' && (
					<ModalFPay
						paymentMethod={paymentMethodSelected}
						showModal={screenContainerState}
						onChangeShowModal={onChangeScreenState}
						iframeUrl={iframeURL}
						loading={loading}
						changeLoadingState={changeLoadingState}
						onLoadIframe={onLoadIframe}
					/>
				)}
		</Card>
	);
}
