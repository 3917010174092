import { FormErrors } from 'redux-form';
import { IVehicleForm } from '../store/Vehicle';
import {
	validateEngineSerial,
	validateSerialNumber,
	validateYear,
} from './utils';

export default (values: IVehicleForm) => {
	const errors: FormErrors<IVehicleForm> = {};

	if (!values.type) {
		errors.type = 'Campo requerido';
	}

	if (!values.brand) {
		errors.brand = 'Campo requerido';
	}

	if (!values.model) {
		errors.model = 'Campo requerido';
	}

	if (!values.use) {
		errors.use = 'Campo requerido';
	}

	if (!values.serial) {
		errors.serial = 'Campo requerido';
	} else if (!validateSerialNumber(values.serial)) {
		errors.serial = 'Formato inválido';
	}

	if (!values.engineSerial) {
		errors.engineSerial = 'Campo requerido';
	} else if (!validateEngineSerial(values.engineSerial)) {
		errors.serial = 'Formato inválido';
	}

	if (!values.year) {
		errors.year = 'Campo requerido';
	} else if (!validateYear(Number(values.year))) {
		errors.year = 'Fecha no válida';
	}

	if (!values.seats) {
		errors.seats = 'Campo requerido';
	}

	return errors;
};
