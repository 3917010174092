export default (errors: any) => {
	const elements = Object.keys(errors)
		.map((fieldName) => `[name="${fieldName}"]`)
		.join(',');
	const element: any = document.querySelector(elements);

	if (element && element.focus) {
		element.focus();
	}
};
