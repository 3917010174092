import { FormErrors } from 'redux-form';
import { IEmitForm } from '../store/Emit';
import { allEqual } from './utils';

const regexString = /^[A-Za-zÑñ\- ]+$/i;
const regexEmail = /^[A-Z0-9._%+-]+@[A-Z0-9-]+\.[A-Z]{2,4}(\.[A-Z]{2})?$/i;
const regexPhone = /^(9)?[0-9]{8}$/i;
const regexAddress = /(^[A-z a-z 0-9]+$)/i;

type propPaternal = 'paternal';
type propMaternal = 'maternal';
type propName = 'name';

export default (values: IEmitForm) => {
	const errors: FormErrors<IEmitForm> = {};

	const validatePersonalInfo = (
		param: propName | propPaternal | propMaternal,
	) => {
		const paramValue = values[param];
		const paramForMessage = param === 'name' ? 'nombre' : 'apellido';

		if (!paramValue) {
			errors[param] = 'Campo obligatorio';
		} else if (!regexString.test(paramValue)) {
			errors[
				param
			] = `El ${paramForMessage} no puede contener números ni caracteres especiales, solo letras`;
		} else if (paramValue.length > 50) {
			errors[
				param
			] = `El ${paramForMessage} solo puede contener un máximo de 50 caracteres`;
		} else if (paramValue.length < 2) {
			errors[param] = `El ${paramForMessage} debe tener un mínimo 2 caracteres`;
		}
	};

	validatePersonalInfo('paternal');
	validatePersonalInfo('maternal');
	validatePersonalInfo('name');

	if (!values.email) {
		errors.email = 'Campo obligatorio';
	} else if (!regexEmail.test(String(values.email).toLowerCase())) {
		errors.email = 'Debe ser un email válido';
	}

	if (!values.phone) {
		errors.phone = 'Campo obligatorio';
	} else if (values.phone.trim().length < 9) {
		errors.phone = 'Celular inválido, debe contener 9 dígitos';
	} else if (!regexPhone.test(values.phone.trim())) {
		errors.phone = 'El número de celular debe empezar por 9';
	} else if (allEqual(values.phone.trim())) {
		errors.phone = 'No deben ser todos los dígitos iguales';
	}

	if (!values.birthdate) {
		errors.birthdate = 'Campo obligatorio';
	}

	if (!values.department) {
		errors.department = 'Campo obligatorio.';
	}

	if (!values.province) {
		errors.province = 'Campo obligatorio.';
	}

	if (!values.district) {
		errors.district = 'Campo obligatorio.';
	}

	if (!values.address) {
		errors.address = 'Campo obligatorio';
	} else if (!regexAddress.test(values.address)) {
		errors.address =
			'La dirección solo puede contener números, letras y espacios';
	} else if (values.address.length > 50) {
		errors.address =
			'La dirección solo puede contener un máximo de 50 caracteres';
	}

	if (!values.addressNumber) {
		errors.addressNumber = 'Campo obligatorio';
	}

	return errors;
};
