import axios from 'axios';
import { push } from 'connected-react-router';
import { AnyAction } from 'redux';
import { call, put } from 'redux-saga/effects';
import { IOptions } from '../components/FormSelect';
import { RESET_STATE, showError } from './Commons';

export const SET_VALIDATIONS_FORM = 'VALIDATIONSFORM/SET';

export const GET_VEHICLE_PLATE = 'GET_VEHICLE_PLATE';

export const SET_VEHICLE_DEPARTMENTS = 'VEHICLE_DEPARTMENTS/SET';
export const GET_VEHICLE_DEPARTMENTS = 'QUOTING/VEHICLE_DEPARMENTS';

export const GET_DOCTYPES = 'DOCTYPES/GET';
export const SET_DOCTYPES = 'DOCTYPES/SET';

export const GET_DEPARTMENTS = 'DEPARTMENTS/GET';
export const SET_DEPARTMENTS = 'DEPARTMENTS/SET';

export const GET_DISTRICTS = 'DISTRICTS/GET';
export const SET_DISTRICTS = 'DISTRICTS/SET';

export const GET_PROVINCES = 'PROVINCES/GET';
export const SET_PROVINCES = 'PROVINCES/SET';

export const VALIDATE_PLATE = 'QUOTE/PLATE';
export const SELECT_PLAN = 'QUOTE/SELECT';
export const SUBMIT_QUOTE_FORM = 'QUOTE/SUBMIT';
export const FAIL_SUBMIT = 'SUBMIT/FAIL';
export const SET_FORM_ERROR = 'SET_QOUTE_FORM_ERROR';
export const SET_PLAN = 'PLAN/SELECT';
export const SET_DEFAULT_DEPARTMENT = 'DEPARTMENT/SETDEFAULT';
export const SET_DEFAULT_VEHICLE_DEPARTMENT = 'VEHICLE_DEPARTMENT/SETDEFAULT';
export const SET_DEFAULT_COUPON = 'QUOTE/COUPON';

export const SET_DEFAULT_DOC_TYPE = 'DOCTYPE/SETDEFAULT';
export const LOADING = 'LOADING';

export const URL_DEPARTMENTS = '/departments';
export const URL_PROVINCES = '/provincesByDepartment';
export const URL_DISTRICTS = '/districtsByProvince';
export const URL_VEHICLE_DEPARTMENTS = '/departments';

export const URL_PROTECTA_DOCTYPES = '/doctypes';
export const URL_BASE_PLATE_VIGENCY = '/protecta/plate/vigency';

export interface IValidationForm {
	plate: string;
	docType: string;
	vehicleDepartment: string;
	documentId: string;
	date: string;
	quotePrivacyPolicies: boolean;
	coupon: string;
}

export interface IEmitResumeForm {
	politycs: boolean;
	news: boolean;
}

export interface IQuote {
	id: number;
	consecutive: number;
	branch: number;
	plansWithConsultation: any[];
	plansWithoutConsultation: any[];
}

/**
 * Custom errors in forms
 * @property {string} name name of field. MUST be aproperty of {IValidationForm}
 * @property {string} value error description
 */
export interface IValidationCustomErrors {
	name: string;
	value: string;
}

export const setValidationsForm = (payload: IValidationForm) => ({
	payload,
	type: SET_VALIDATIONS_FORM,
});

export const loading = () => ({
	type: LOADING,
});

export const failSubmit = () => ({
	type: FAIL_SUBMIT,
});

export const getVehiclePlate = () => ({
	type: GET_VEHICLE_PLATE,
});

export const getVehicleDepartments = () => ({
	type: GET_VEHICLE_DEPARTMENTS,
});

export const setVehicleDepartments = (payload: any) => ({
	payload,
	type: SET_VEHICLE_DEPARTMENTS,
});

export const setDefaultVehicleDepartment = (payload: IOptions) => ({
	payload,
	type: SET_DEFAULT_VEHICLE_DEPARTMENT,
});

export const getInsuredDepartments = () => ({
	type: GET_DEPARTMENTS,
});

export const setInsuredDepartments = (payload: any) => ({
	payload,
	type: SET_DEPARTMENTS,
});

export const getInsuredProvinces = (payload: any) => ({
	payload,
	type: GET_PROVINCES,
});

export const setInsuredProvinces = (payload: any) => ({
	payload,
	type: SET_PROVINCES,
});

export const getInsuredDistricts = (payload: any) => ({
	payload,
	type: GET_DISTRICTS,
});

export const setInsuredDistricts = (payload: any) => ({
	payload,
	type: SET_DISTRICTS,
});

export const setDefaultInsuredDepartment = (payload: IOptions) => ({
	payload,
	type: SET_DEFAULT_DEPARTMENT,
});

export const getDocumentTypes = () => ({
	type: GET_DOCTYPES,
});

export const setDoctypes = (payload: {
	original: any;
	mapped: IOptions[];
}) => ({
	payload,
	type: SET_DOCTYPES,
});

export const setDefaultDocType = (payload: IOptions) => ({
	payload,
	type: SET_DEFAULT_DOC_TYPE,
});

export const validPlate = (payload: IValidationForm) => ({
	payload,
	type: VALIDATE_PLATE,
});

export const setFormErrors = (payload: IValidationCustomErrors) => ({
	payload,
	type: SET_FORM_ERROR,
});

export const setDefaultCoupon = (payload: string) => ({
	payload,
	type: SET_DEFAULT_COUPON,
});

const initialState = {
	data: {
		date: '',
		department: '',
		docType: '',
		documentId: '',
		plate: '',
		quotePrivacyPolicies: false,
		coupon: '',
	},
	errors: {
		department: '',
		docType: '',
	},
	submitting: false,
};

export default function validationsFormReducer(
	state = initialState,
	action: AnyAction,
) {
	switch (action.type) {
		case SET_VALIDATIONS_FORM:
			return {
				...state,
				data: action.payload,
			};
		case LOADING:
			return {
				...state,
				submitting: true,
			};
		case FAIL_SUBMIT:
			return {
				...state,
				submitting: false,
			};
		case RESET_STATE:
			return initialState;
		case SET_DEPARTMENTS:
			return {
				...state,
				departments: action.payload,
			};
		case SET_VEHICLE_DEPARTMENTS:
			return {
				...state,
				vehicleDepartments: action.payload,
			};
		case SET_DISTRICTS:
			return {
				...state,
				districts: action.payload,
			};
		case SET_PROVINCES:
			return {
				...state,
				provinces: action.payload,
			};
		case SET_DOCTYPES:
			return {
				...state,
				doctypes: action.payload.mapped,
				originalDoctypes: action.payload.original,
			};
		case SET_DEFAULT_DEPARTMENT:
			return {
				...state,
				defaultDepartment: action.payload,
			};
		case SET_DEFAULT_VEHICLE_DEPARTMENT:
			return {
				...state,
				defaultVehicleDepartment: action.payload,
			};
		case SET_DEFAULT_DOC_TYPE:
			return {
				...state,
				defaultDocType: action.payload,
			};
		case SET_FORM_ERROR:
			return {
				...state,
				errors: {
					...state.errors,
					[action.payload.name]: action.payload.value,
				},
			};
		case SET_DEFAULT_COUPON:
			return {
				...state,
				data: {
					...state.data,
					coupon: action.payload,
				},
			};
		default:
			return state;
	}
}

export function* departments() {
	try {
		const { data } = yield call(axios.get, URL_DEPARTMENTS);

		const departmentsList: IOptions[] = data.map(({ code, name }: any) => ({
			name,
			value: code,
		}));

		const indexDefaultDepartment: number = departmentsList.findIndex(
			({ name }: IOptions) => name.toLowerCase() === 'lima',
		);

		if (indexDefaultDepartment !== -1) {
			yield put(
				setDefaultInsuredDepartment(departmentsList[indexDefaultDepartment]),
			);
		}

		yield put(setInsuredDepartments(departmentsList));
	} catch (error) {
		yield put(
			setFormErrors({
				name: 'department',
				value: 'Lo sentimos, no hemos podido mostrar los departamentos',
			}),
		);
	}
}

export function* vehicleDepartments() {
	try {
		const { data } = yield call(axios.get, URL_VEHICLE_DEPARTMENTS);

		const vehicleDepartmentsList: IOptions[] = data.map(
			({ code, name }: any) => ({
				name,
				value: code,
			}),
		);

		const indexDefaultDepartment: number = vehicleDepartmentsList.findIndex(
			({ name }: IOptions) => name.toLowerCase() === 'lima',
		);

		if (indexDefaultDepartment !== -1) {
			yield put(
				setDefaultVehicleDepartment(
					vehicleDepartmentsList[indexDefaultDepartment],
				),
			);
		}

		yield put(setVehicleDepartments(vehicleDepartmentsList));
	} catch (error) {
		yield put(
			setFormErrors({
				name: 'vehicleDepartment',
				value:
					'Lo sentimos, no hemos podido mostrar los departamentos de vehículo',
			}),
		);
	}
}

export function* doctypes() {
	try {
		const { data } = yield call(axios.get, URL_PROTECTA_DOCTYPES);

		const doctypesList: IOptions[] = data.map(({ code, name }: any) => ({
			name,
			value: code,
		}));

		const indexDefaultDocType: number = doctypesList.findIndex(
			({ value }: IOptions) => value!.toUpperCase() === 'DNI',
		);

		if (indexDefaultDocType !== -1) {
			yield put(setDefaultDocType(doctypesList[indexDefaultDocType]));
		}

		yield put(setDoctypes({ original: data, mapped: doctypesList }));
	} catch (error) {
		yield put(
			setFormErrors({
				name: 'docType',
				value: 'Lo sentimos, ha ocurrido un error insesperado.',
			}),
		);
	}
}

export function* submitToVehicle({ payload }: AnyAction) {
	try {
		yield put(loading());
		yield put(setValidationsForm(payload));
		yield put(failSubmit());
		yield put(push('/vehicle'));
	} catch (error) {
		yield put(failSubmit());
		yield put(showError((error as Error).message || 'Error desconocido'));
	}
}

export function* provinces({ payload }: AnyAction) {
	try {
		yield put(setInsuredProvinces([]));

		const { data } = yield call(
			axios.get,
			`${URL_PROVINCES}/${payload.departmentCode}`,
		);

		const provincesList: IOptions[] = data.map(({ code, name }: any) => ({
			name,
			value: code,
		}));

		yield put(setInsuredProvinces(provincesList));
	} catch (error) {
		yield put(
			setFormErrors({
				name: 'provinces',
				value: 'Lo sentimos, no hemos podido mostrar las provincias',
			}),
		);
	}
}

export function* districts({ payload }: AnyAction) {
	try {
		yield put(setInsuredDistricts([]));

		const { data } = yield call(
			axios.get,
			`${URL_DISTRICTS}/${payload.provinceCode}`,
		);

		const districtsList: IOptions[] = data.map(({ code, name }: any) => ({
			name,
			value: code,
		}));

		yield put(setInsuredDistricts(districtsList));
	} catch (error) {
		yield put(
			setFormErrors({
				name: 'district',
				value: 'Lo sentimos, no hemos podido mostrar los distritos',
			}),
		);
	}
}
