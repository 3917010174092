import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Container, Loading, Plan, PlansNavbar } from '../components';
import { IOptions } from '../components/FormSelect';
import { ICoupon } from '../store/Coupon';
import { IPlan, linewViewChange, selectedPlan } from '../store/Vehicle';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';

import { isMobileDevice } from '../helpers/utils';

// Import Swiper styles
// import 'swiper/swiper.scss';
// import 'swiper/modules/pagination/pagination.scss';
import 'swiper/css';
import 'swiper/css/pagination';
import '../assets/styles/swiper.scss';
import {
	PageNameUA,
	PageNameGA4,
	sendUAPageView,
	sendGA4PageView,
	sendGA4FunnelStepViewed,
} from '../helpers/gtm';

interface IMatrixProps {
	documentId: string;
	plate: string;
	isMoto: boolean;
	use: string;
	date: string;
	brand: string;
	type: string;
	model: string;
	plans: IPlan[];
	serial: string;
	seats: string;
	lineView?: boolean;
	year: string;
	submitting: boolean;
	selectPlan: (payload: IPlan) => void;
	onLineViewChange: (lineView: boolean) => void;
	coupon: ICoupon;
	couponUse: boolean;
}

export function Matrix(props: IMatrixProps) {
	const {
		plans,
		plate,
		isMoto,
		selectPlan,
		onLineViewChange,
		use,
		brand,
		model,
		date,
		type,
		serial,
		seats,
		year,
		submitting,
		coupon,
		couponUse,
		documentId,
	} = props;

	const slides = plans.map((plan, i) => {
		return (
			<SwiperSlide key={i}>
				<Plan
					planData={plan}
					key={plan.properties.id}
					planNumber={i}
					selectPlan={selectPlan}
					coupon={coupon}
					couponUse={couponUse}
					isMoto={isMoto}
				/>
			</SwiperSlide>
		);
	});

	useEffect(() => {
		if (plans.length === 0) {
			sendUAPageView(PageNameUA.Paso_Error, isMoto);
			sendGA4PageView(PageNameGA4.Paso_Error, documentId);
			sendGA4FunnelStepViewed(PageNameGA4.Paso_Error, isMoto, documentId);
		} else {
			sendUAPageView(PageNameUA.Paso_DatosCotizacion, isMoto);
			sendGA4PageView(PageNameGA4.Paso_DatosCotizacion, documentId);
			sendGA4FunnelStepViewed(PageNameGA4.Paso_DatosCotizacion, isMoto, documentId);
		}
	}, [plans.length, isMoto]);

	return (
		<Container>
			{!submitting && (
				<>
					<PlansNavbar
						brand={brand}
						model={model}
						plate={plate}
						onLineViewChange={onLineViewChange}
						use={use}
						date={date}
						serial={serial}
						type={type}
						seats={seats}
						year={year}
						isMoto={isMoto}
						plans={plans}
					/>
					<div
						className={`container matriz ${isMobileDevice() ? 'py-3' : 'py-5'}`}
					>
						<div className="mt-4">
							{isMobileDevice() && (
								<Swiper
									spaceBetween={10}
									slidesPerView={1.3}
									pagination={{
										dynamicBullets: true,
									}}
									centeredSlides={true}
									modules={[Pagination]}
								>
									{slides}
								</Swiper>
							)}
							{!isMobileDevice() && plans.length > 0 && (
								<div className="d-flex flex-row justify-content-center">
									{plans.map((plan: IPlan, index: number) => (
										<Plan
											planData={plan}
											key={plan.properties.id}
											planNumber={index}
											selectPlan={selectPlan}
											coupon={coupon}
											couponUse={couponUse}
											isMoto={isMoto}
										/>
									))}
								</div>
							)}
						</div>
					</div>
				</>
			)}
			{submitting && <Loading />}
		</Container>
	);
}

export const mapStateToProps = (state: any) => {
	const {
		Vehicle: {
			lineView,
			plans,
			data: { use, brand, type, serial, seats, model, year },
			brands,
			uses,
			models,
			types,
			isMoto,
		},
		ValidationForm: {
			data: { documentId, plate, date },
		},
		Coupon,
		Matrix: { submitting },
	} = state;

	if (isMoto && process.env.REACT_APP_MOTO_SKIP_QUOTE === 'true') {
		return {
			brand:'',
			date,
			documentId,
			lineView,
			model:'',
			plans,
			plate,
			isMoto,
			use: '',
			type: '',
			serial,
			seats,
			year,
			submitting,
			coupon: Coupon.data,
			couponUse: Coupon.use,
		};
	}else{
		return {
			brand:
				brands && brands.length > 0
					? brands.find(({ value }: IOptions) => value === brand).name
					: brands,
			date,
			documentId,
			lineView,
			model:
				models && models.length > 0
					? models.find(({ value }: IOptions) => value === model).name
					: model,
			plans,
			plate,
			isMoto,
			use: uses
				? uses.find(({ value, code }: IOptions) => value === use || code === use)
						.name
				: use,
			type: types
				? !!types.find(({ value }: IOptions) => value === type)
					? types.find(({ value }: IOptions) => value === type).name
					: types[0].name
				: type,
			serial,
			seats,
			year,
			submitting,
			coupon: Coupon.data,
			couponUse: Coupon.use,
		};
	}
};

export const mapDispatchToProps = (dispatch: Dispatch) => ({
	onLineViewChange: (payload: boolean) => dispatch(linewViewChange(payload)),
	selectPlan: (payload: IPlan) => dispatch(selectedPlan(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Matrix);
