import 'react-app-polyfill/ie11';
// tslint:disable-next-line:ordered-imports
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
	ConnectedRouter,
	connectRouter,
	routerMiddleware,
} from 'connected-react-router';
import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { reducer as formReducer } from 'redux-form';
import createSagaMiddleware from 'redux-saga';
import App from './App';
import './assets/styles/styles.sass';
import * as reducers from './store';
import sagas from './store/sagas';

const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

const store = createStore(
	combineReducers({
		...reducers,
		form: formReducer,
		router: connectRouter(history),
	}),
	composeWithDevTools(
		applyMiddleware(routerMiddleware(history), sagaMiddleware),
	),
);

sagaMiddleware.run(sagas);

const TOKEN_TYPE = 'Bearer';

axios.defaults.baseURL = process.env.REACT_APP_BFF_URL;
axios.defaults.headers.common.authorization = `${TOKEN_TYPE} ${process.env.REACT_APP_BFF_TOKEN}`;

// if (process.env.REACT_APP_ENVIRONMENT === 'production') {
const tagManagerArgs = {
	dataLayerName: 'dataLayer',
	gtmId: 'GTM-W6WFRKC',
};

TagManager.initialize(tagManagerArgs);
// }

window.addEventListener('load', () => {
	const pathname = window.location.pathname;
	const whitelist = ['/maintenance', '/fail', '/success', '/renew', '/'];
	const find = whitelist.find((path) => pathname.indexOf(path) !== -1);
	if (!find) {
		history.push('/');
	}
});

ReactDOM.render(
	<Provider store={store}>
		<ConnectedRouter history={history}>
			<App />
		</ConnectedRouter>
	</Provider>,
	document.getElementById('root'),
);
