import { userInfo } from 'os';
import TagManager from 'react-gtm-module';
import { hashValue } from './utils';

export enum EventNameUA {
	InputIngresoPlaca = 'input(ingreso-placa)',
	InputDni = 'input(dni)',
	InputCodigoPromocional = 'input(codigo-promocional)',
	InputDatosVehiculoSerie = 'input[datos-vehiculo](serie)',
	InputDatosVehiculoMotor = 'input[datos-vehiculo](motor)',
	InputDatosVehiculoAsientos = 'input[datos-vehiculo](asientos)',
	InputDatosPersonalesNombre = 'input[datos-personales](nombre)',
	InputDatosPersonalesApellidoPaterno = 'input[datos-personales](apellido-paterno)',
	InputDatosPersonalesApellidoMaterno = 'input[datos-personales](apellido-materno)',
	InputDatosPersonalesCorreo = 'input[datos-personales](correo)',
	InputDatosPersonalesTelefono = 'input[datos-personales](telefono)',
	InputDatosVehiculoAnio = 'input[datos-vehiculo](ano)',
	SelectDepartamento = 'select(departamento)',
	SelectFechaSeguro = 'select(fecha-seguro)',
	SelectDatosVehiculoMarca = 'select[datos-vehiculo](marca)',
	SelectDatosVehiculoModelo = 'select[datos-vehiculo](modelo)',
	SelectDatosVehiculoClase = 'select[datos-vehiculo](clase)',
	SelectDatosVehiculoUso = 'select[datos-vehiculo](uso)',
	SelectDatosPersonalesFechaNacimiento = 'select[datos-personales](fecha-de-nacimiento)',
	SelectMetodosPagoAlignet = 'select[metodos-pago](alignet)',
	SelectMetodosPagoFPayCreditCmr = 'select[metodos-pago](fpay-credit-cmr)',
	SelectMetodosPagoFPayDebitCmr = 'select[metodos-pago](fpay-debit-cmr)',
	SelectMetodosPagoFPayThirdPartiesCredit = 'select[metodos-pago](fpay-third-parties-credit)',
	SelectMetodosPagoFPayThirdPartiesDebit = 'select[metodos-pago](fpay-third-parties-debit)',
	SelectMetodosPagoFPayWallet = 'select[metodos-pago](fpay-wallet)',
	CheckTerminosCondiciones = 'check(terminos-condiciones)',
	CheckDatosPersonalesCartaNombramiento = 'check[datos-personales](terminos-condiciones-carta-nombramiento)',
	CheckDatosPersonalesCMRPuntos = 'check[datos-personales](terminos-condiciones-CMR-puntos)',
	ClickIngresoPlacaBtnContinuar = 'click[ingreso-placa](btncotizar)',
	ClickDatosVehiculoBtnContinuar = 'click[ingreso-placa](btncotizar)',
	ClickDatosVehiculoBtnVolver = 'click[ingreso-placa](btnvolver)',
	ClickEditarInformacion = 'click[editar-informacion](editar-informacion)',
	ClickQuieroContratarProtectaSecurity = 'click[quiero-contratar](protecta-security)',
	ClickQuieroContratarRimac = 'click[quiero-contratar](rimac)',
	ClickQuieroContratarInterseguro = 'click[quiero-contratar](interseguro)',
	ClickQuieroContratarLaPositiva = 'click[quiero-contratar](la-positiva)',
	ClickQuieroContratarVivirSeguros = 'click[quiero-contratar](vivir-seguros)',
	ClickDatosPersonalesBtnContinuar = 'click[datos-personales](btncontinuar)',
	ClickDatosPersonalesBtnVolver = 'click[datos-personales](btnvolver)',
	ClickSuccessBtnSegurosFalabella = 'click[success](btnSegurosFalabella)',
}

export enum EventNameGA4 {
	SelectMetodosPagoFPayCreditCmr = 'select[metodos-pago](fpay-credit-cmr)',
	SelectMetodosPagoFPayDebitCmr = 'select[metodos-pago](fpay-debit-cmr)',
	SelectMetodosPagoFPayThirdPartiesCredit = 'select[metodos-pago](fpay-third-parties-credit)',
	SelectMetodosPagoFPayThirdPartiesDebit = 'select[metodos-pago](fpay-third-parties-debit)',
	CheckDatosPersonalesCMRPuntos = 'check[datos-personales](terminos-condiciones-CMR-puntos)',
	ClickContratarMoto = 'click[quiero-contratar](moto)',
	ClickQuieroContratarProtectaSecurity = 'click[quiero-contratar](protecta-security)',
	ClickQuieroContratarRimac = 'click[quiero-contratar](rimac)',
	ClickQuieroContratarLaPositiva = 'click[quiero-contratar](la-positiva)',
	ClickQuieroContratarVivirSeguros = 'click[quiero-contratar](vivir-seguros)',
}

enum PageLocationGA4 {
	Paso_IngresoPlaca = 'soat.segurosfalabella.com.pe/visita',
	Paso_DatoVehiculo = 'soat.segurosfalabella.com.pe/datos-vehiculo',
	Paso_Cotizacion = 'soat.segurosfalabella.com.pe/cotizacion',
	Paso_DatosAsegurado = 'soat.segurosfalabella.com.pe/datos-personales',
	Paso_Pago = 'soat.segurosfalabella.com.pe/pago',
	Paso_ResumenCompra = 'soat.segurosfalabella.com.pe/exito',
	Error = 'soat.segurosfalabella.com.pe/error',
}

export enum PageNameUA {
	Paso_IngresoPlaca = 'pageview(ingreso-placa)',
	Paso_DatosVehiculo = 'pageview(datos-vehiculo)',
	Paso_DatosCotizacion = 'pageview(matriz)',
	Paso_DatosAsegurado = 'pageview(datos-personales)',
	Paso_PagarSoat = 'pageview(cotizador)',
	Paso_VentaSoat = 'pageview(success)',
	Paso_Error = 'pageview(failed)',
}

export enum PageNameGA4 {
	Paso_IngresoPlaca = 'paso-visita',
	Paso_DatosVehiculo = 'paso-datos-vehiculo',
	Paso_DatosCotizacion = 'paso-cotizacion',
	Paso_DatosAsegurado = 'paso-datos-clientes',
	Paso_PagarSoat = 'paso-pago',
	Paso_VentaSoat = 'paso-exito',
	Paso_Error = 'paso-error',
}

enum Action {
	CheckDatosPersonalesCMRPuntos = 'acepto-programa-cmr',
	SelectMetodosPagoFPayCreditCmr = 'pago-cmr',
	SelectMetodosPagoFPayDebitCmr = 'pago-debito-cmr',
	SelectMetodosPagoFPayThirdPartiesCredit = 'pago-otras-credito',
	SelectMetodosPagoFPayThirdPartiesDebit = 'pago-otras-debito',
	ClickContratarMoto = 'quiero-contratar',
	ClickContratarProtectaSecurity = 'cotizacion-protecta',
	ClickContratarRimac = 'cotizacion-rimac',
	ClickContratarLaPositiva = 'cotizacion-positiva',
	ClickContratarVivirSeguros = 'cotizacion-vivirseguros',
}

export enum EventErrorTriggered {
	quotationError = 'quotationError',
	paymentRejected = 'paymentRejected',
	subscriptionError = 'subscriptionError',
}

enum ErrorType {
	ErrorCotizacion = 'error-cotizacion',
	ErrorPago = 'error-pago',
}

enum ErrorMessage {
	ErrorCotizacion = 'error-cotizacion',
	PagoRechazado = 'pago-rechazado',
	ErrorSubscripcion = 'error-subscripcion',
}

type CustomEventGA4 = {
	[key in EventNameGA4]: {
		flow: string;
		location: PageNameGA4;
		action: Action;
	};
};

type PageViewGA4 = {
	[key in PageNameGA4]: {
		page_title: string;
		page_location: PageLocationGA4;
	};
};

type CustomErrorEventGA4 = {
	[key in EventErrorTriggered]: {
		flow: string;
		location: PageNameGA4;
		error_type: ErrorType;
		error_message: ErrorMessage;
	};
};

const pageTitle = 'SOAT';
const flowName = 'web-soat';

const pageViewsGA4: PageViewGA4 = {
	[PageNameGA4.Paso_IngresoPlaca]: {
		page_title: pageTitle,
		page_location: PageLocationGA4.Paso_IngresoPlaca,
	},
	[PageNameGA4.Paso_DatosAsegurado]: {
		page_title: pageTitle,
		page_location: PageLocationGA4.Paso_DatosAsegurado,
	},
	[PageNameGA4.Paso_DatosCotizacion]: {
		page_title: pageTitle,
		page_location: PageLocationGA4.Paso_Cotizacion,
	},
	[PageNameGA4.Paso_DatosVehiculo]: {
		page_title: pageTitle,
		page_location: PageLocationGA4.Paso_DatoVehiculo,
	},
	[PageNameGA4.Paso_PagarSoat]: {
		page_title: pageTitle,
		page_location: PageLocationGA4.Paso_Pago,
	},
	[PageNameGA4.Paso_VentaSoat]: {
		page_title: pageTitle,
		page_location: PageLocationGA4.Paso_ResumenCompra,
	},
	[PageNameGA4.Paso_Error]: {
		page_title: pageTitle,
		page_location: PageLocationGA4.Error,
	},
};

const customEventsGA4: CustomEventGA4 = {
	[EventNameGA4.ClickContratarMoto]: {
		flow: flowName,
		location: PageNameGA4.Paso_DatosVehiculo,
		action: Action.ClickContratarMoto,
	},
	[EventNameGA4.CheckDatosPersonalesCMRPuntos]: {
		flow: flowName,
		location: PageNameGA4.Paso_DatosAsegurado,
		action: Action.CheckDatosPersonalesCMRPuntos,
	},
	[EventNameGA4.SelectMetodosPagoFPayCreditCmr]: {
		flow: flowName,
		location: PageNameGA4.Paso_PagarSoat,
		action: Action.SelectMetodosPagoFPayCreditCmr,
	},
	[EventNameGA4.SelectMetodosPagoFPayDebitCmr]: {
		flow: flowName,
		location: PageNameGA4.Paso_PagarSoat,
		action: Action.SelectMetodosPagoFPayDebitCmr,
	},
	[EventNameGA4.SelectMetodosPagoFPayThirdPartiesCredit]: {
		flow: flowName,
		location: PageNameGA4.Paso_PagarSoat,
		action: Action.SelectMetodosPagoFPayThirdPartiesCredit,
	},
	[EventNameGA4.SelectMetodosPagoFPayThirdPartiesDebit]: {
		flow: flowName,
		location: PageNameGA4.Paso_PagarSoat,
		action: Action.SelectMetodosPagoFPayThirdPartiesDebit,
	},
	[EventNameGA4.ClickQuieroContratarProtectaSecurity]: {
		flow: flowName,
		location: PageNameGA4.Paso_DatosCotizacion,
		action: Action.ClickContratarProtectaSecurity,
	},
	[EventNameGA4.ClickQuieroContratarRimac]: {
		flow: flowName,
		location: PageNameGA4.Paso_DatosCotizacion,
		action: Action.ClickContratarRimac,
	},
	[EventNameGA4.ClickQuieroContratarLaPositiva]: {
		flow: flowName,
		location: PageNameGA4.Paso_DatosCotizacion,
		action: Action.ClickContratarLaPositiva,
	},
	[EventNameGA4.ClickQuieroContratarVivirSeguros]: {
		flow: flowName,
		location: PageNameGA4.Paso_DatosCotizacion,
		action: Action.ClickContratarVivirSeguros,
	},
};

const customErrorEventsGA4: CustomErrorEventGA4 = {
	[EventErrorTriggered.quotationError]: {
		flow: flowName,
		location: PageNameGA4.Paso_DatosCotizacion,
		error_type: ErrorType.ErrorCotizacion,
		error_message: ErrorMessage.ErrorCotizacion,
	},
	[EventErrorTriggered.paymentRejected]: {
		flow: flowName,
		location: PageNameGA4.Paso_PagarSoat,
		error_type: ErrorType.ErrorPago,
		error_message: ErrorMessage.PagoRechazado,
	},
	[EventErrorTriggered.subscriptionError]: {
		flow: flowName,
		location: PageNameGA4.Paso_PagarSoat,
		error_type: ErrorType.ErrorPago,
		error_message: ErrorMessage.ErrorSubscripcion,
	},
};

function format(
	base: string,
	prefix: string,
	neutral: boolean,
	isMoto: boolean,
	notation: 'camelCase' | 'PascalCase' = 'camelCase',
): string {
	function fixedNotation() {
		switch (notation) {
			default:
			case 'camelCase':
				return isMoto ? 'moto' : 'auto';
			case 'PascalCase':
				return isMoto ? 'Moto' : 'Auto';
		}
	}
	const middle = neutral ? '' : prefix;
	const suffix = neutral ? '' : fixedNotation();
	return `${base}${middle}${suffix}`;
}

export const sendUAPageView = (
	page: PageNameUA,
	neutral: boolean = false,
	isMoto: boolean = false,
) => {
	TagManager.dataLayer({
		dataLayer: {
			event: format(page, '@', neutral, isMoto),
		},
		dataLayerName: 'dataLayer',
	});
};

/* tslint:disable:variable-name */
export const sendGA4PageView = (
	page: PageNameGA4,
	dni: string = '',
	error_message: string = '',
): void => {
	const userId = dni && `PE_DNI_${hashValue(dni)}`;
	TagManager.dataLayer({
		dataLayer: {
			event: 'page_view',
			page_location: pageViewsGA4[page].page_location,
			page_title: pageViewsGA4[page].page_title,
			...(userId && { user_id: userId }),
			...(error_message && { error_message }),
		},
		dataLayerName: 'dataLayer',
	});
};

export const sendGA4FunnelStepViewed = (
	page: PageNameGA4,
	isMoto: boolean = false,
	dni: string = '',
): void => {
	let productVariant = '';
	if (page !== PageNameGA4.Paso_IngresoPlaca) {
		productVariant = isMoto ? 'moto' : 'auto';
	}
	const userId = dni && `PE_DNI_${hashValue(dni)}`;
	TagManager.dataLayer({
		dataLayer: {
			event: 'funnel_step_viewed',
			funnel_name: 'contratacion-seguro',
			product_name: 'web-soat',
			funnel_step_name: page,
			...(userId && { user_id: userId }),
			product_variant_name: productVariant,
		},
		dataLayerName: 'dataLayer',
	});
};

export const sendUACustomEvent = (
	event: EventNameUA,
	isMoto: boolean = false,
) => {
	TagManager.dataLayer({
		dataLayer: {
			event: format(event, '@', false, isMoto),
		},
		dataLayerName: 'dataLayer',
	});
};

export const sendGA4UserInteractionEvent = (
	eventName: EventNameGA4,
	dni: string = '',
): void => {
	const userId = dni && `PE_DNI_${hashValue(dni)}`;
	TagManager.dataLayer({
		dataLayer: {
			event: 'user_interaction',
			...(userId && { user_id: userId }),
			...customEventsGA4[eventName],
		},
		dataLayerName: 'dataLayer',
	});
};

interface IEventErrorTriggered {
	quotationError: EventErrorTriggered;
	paymentRejected: EventErrorTriggered;
	subscriptionError: EventErrorTriggered;
	[key: string]: EventErrorTriggered;
}

const errors: IEventErrorTriggered = {
	quotationError: EventErrorTriggered.quotationError,
	paymentRejected: EventErrorTriggered.paymentRejected,
	subscriptionError: EventErrorTriggered.subscriptionError,
};

export const sendGA4ErrorTriggeredEvent = (
	errorTriggered: string,
	dni: string,
): void => {
	const userId = dni && `PE_DNI_${hashValue(dni)}`;
	TagManager.dataLayer({
		dataLayer: {
			event: 'user_interaction',
			...(userId && { user_id: userId }),
			...customErrorEventsGA4[errors.quotationError],
		},
		dataLayerName: 'dataLayer',
	});
};
