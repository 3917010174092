import crypto from 'crypto';
import moment from 'moment-timezone';
moment.tz.setDefault('America/Santiago');
// @ts-ignore
import JShashes from 'jshashes';
const regexRut = /^0*(\d{1,3}(\.?\d{3})*)-?([\dkK])$/i;
// const regexOnlyNumbers = /^([0-9])*$/g;
const onlyLettersAndNumbers = /^[0-9a-zA-Z]+$/;

export const isMoto = (plate: string) => {
	if (
		/^[0-9][A-Z0-9]+$/i.test(plate) || // 0. SI PRIMER CARACTER ES UN NÚMERO, ES UNA MOTO.
		/^[a-z][0-9]+$/i.test(plate) || // 1. PRIMER CARACTER ES UNA LETRA Y TODO EL RESTO NÚMERO, ES UNA MOTO.
		/[a-z-0-9]+[a-z]+$/i.test(plate) || // 2. SI SOLO EL ÚLTIMO CARACTER ES UNA LETRA, ES UNA MOTO.
		/^[a-z][a-z0-9]+[a-z]$/i.test(plate) || // 3. SI EL PRIMERO Y ULTIMO CARACTER ES UNA LETRA Y LO DEMÁS ES UN NÚMERO, ES UNA MOTO.
		Number.isNaN(plate.charAt(5) as any) // 4. SI EL SEXTO DIGITO ES UNA LETRA, ES UNA MOTO
	) {
		return true;
	} else {
		return false;
	}
};

export const isPlateValid = (plate: string) => {
	if (/^[A-Z0-9]{6}/.exec(plate)) {
		return true;
	} else {
		return false;
	}
};

export const validateSerialNumber = (serial: string) => {
	if (serial.length > 30) {
		return false;
	}
	if (!onlyLettersAndNumbers.test(serial)) {
		return false;
	}
	return true;
};

export const serialFormat = (value: string) =>
	value.substr(0, 30).toUpperCase();

export const engineFormat = (value: string) =>
	value.substr(0, 30).toUpperCase();

export const validateEngineSerial = (serial: string) => {
	if (serial.length > 30) {
		return false;
	}
	if (!/(^[A-Z a-z 0-9]+$)/i.test(serial)) {
		return false;
	}
	return true;
};

export const validateYear = (year: number) =>
	year > 1950 && year <= new Date().getFullYear() + 1;

export const calculateUfAmountFromPlan = (planId: string) =>
	(parseInt(planId.substring(3, 4), 10) * 500).toString();

export const parseStringToNumber = (value: string) =>
	parseInt(value, 10) || null;

export const cleanRut = (rut: string): string =>
	rut.replace(/^0+|[^0-9kK]+/g, '').toUpperCase();

export const rutFormatter = (rut: string): string => {
	let result =
		rut.length > 1
			? `${rut.slice(-4, -1)}-${rut.substr(rut.length - 1)}`
			: `${rut.slice(-4, -1)}${rut.substr(rut.length - 1)}`;

	for (let i = 4; i < rut.length; i += 3) {
		result = `${rut.slice(-3 - i, -i)}.${result}`;
	}

	return result;
};

// export const formatDateToIsoString = (date: any, format: string) =>
//   moment(date, format).toISOString();

export const parserRut = (rut: string) => rut.replace(/\./g, '').toLowerCase();

export const formatDni = (rut: string, prev: string): string => {
	if (rut.length > 12) {
		return prev;
	}

	const parsedRut = cleanRut(rut);
	const formatted = rutFormatter(parsedRut);

	return formatted;
};

export const rutValidator = (rut: string): boolean => {
	if (!regexRut.test(rut)) {
		return false;
	}

	const cleanedRut = cleanRut(rut);

	let t = parseInt(cleanedRut.slice(0, -1), 10);
	let m = 0;
	let s = 1;

	while (t > 0) {
		s = (s + (t % 10) * (9 - (m % 6))) % 11;
		t = Math.floor(t / 10);
		m += 1;
	}

	const v = s > 0 ? `${s - 1}` : 'K';

	return v === rut.slice(-1);
};

export const dniValidator = (dni: string) => {
	if (/^\d{7,9}$/.test(dni)) {
		return true;
	}
	return false;
};

export const ceValidator = (ce: string): boolean => {
	if (/^\d{8,12}$/.test(ce)) {
		return true;
	}
	return false;
};

export const formatDate = (date: Date): string =>
	date.toLocaleDateString().replace(/\//g, '-');

export const planCharacteristics: any = {
	VML5C: {
		beneficiaryCap: 250,
		beneficsPdf:
			'https://sfestaticos.blob.core.windows.net/pmc/salud/assets/pdf/PROPUESTA_GENERICA_VML5C.pdf',
		consultation: false,
		deductible: '1,5',
		eventCap: 50,
		refund: 50,
	},
	VML5C1: {
		beneficiaryCap: 250,
		beneficsPdf:
			'https://sfestaticos.blob.core.windows.net/pmc/salud/assets/pdf/PROPUESTA_GENERICA_VML5C1.pdf',
		consultation: true,
		deductible: '1,5',
		eventCap: 50,
		refund: 50,
	},
	VML7C: {
		beneficiaryCap: 350,
		beneficsPdf:
			'https://sfestaticos.blob.core.windows.net/pmc/salud/assets/pdf/PROPUESTA_GENERICA_VML7C.pdf',
		consultation: false,
		deductible: '2',
		eventCap: 50,
		refund: 70,
	},
	VML7C1: {
		beneficiaryCap: 350,
		beneficsPdf:
			'https://sfestaticos.blob.core.windows.net/pmc/salud/assets/pdf/PROPUESTA_GENERICA_VML7C1.pdf',
		consultation: true,
		deductible: '2',
		eventCap: 50,
		refund: 70,
	},
};

// export const chargesToArray = (value: string) =>
//   Array.from(Array(parseInt(value, 10)).keys());

export const months = [
	{
		name: '01',
		value: '01',
	},
	{
		name: '02',
		value: '02',
	},
	{
		name: '03',
		value: '03',
	},
	{
		name: '04',
		value: '04',
	},
	{
		name: '05',
		value: '05',
	},
	{
		name: '06',
		value: '06',
	},
	{
		name: '07',
		value: '07',
	},
	{
		name: '08',
		value: '08',
	},
	{
		name: '09',
		value: '09',
	},
	{
		name: '10',
		value: '10',
	},
	{
		name: '11',
		value: '11',
	},
	{
		name: '12',
		value: '12',
	},
];

export const getYearsForCreditCard = () => {
	const actualYear = new Date().getFullYear();
	const arrayForYears = Array.from(Array(20).keys());
	const yearsArray = arrayForYears.map((index) => ({
		name: `${actualYear + index}`,
		value: `${actualYear + index}`,
	}));

	return yearsArray;
};

// export const chargesMapper = (payload: any) =>
//   chargesToArray(payload.charges).map(chargeNumber => ({
//     birthdate: formatDateToIsoString(
//       payload[`charge-birthdate-${chargeNumber}`],
//       'DD-MM-YYYY',
//     ),
//     documentId: payload[`charge-documentId-${chargeNumber}`],
//     gender: payload[`charge-gender-${chargeNumber}`],
//     maternal: payload[`charge-maternal-${chargeNumber}`],
//     name: payload[`charge-name-${chargeNumber}`],
//     paternal: payload[`charge-paternal-${chargeNumber}`],
//     relationship: payload[`charge-relationship-${chargeNumber}`],
//   }));

export const obfuscateCardNumber = (cardNumber: any): string => {
	const cardNumberWithoutSeparator = cardNumber.replace(/ /g, '');
	const firstNumbers = cardNumberWithoutSeparator.substring(0, 6);
	const lastNumbers = cardNumberWithoutSeparator.substring(
		12,
		cardNumberWithoutSeparator.length,
	);
	const ofuscatedCardNumber = `${firstNumbers}000000${lastNumbers}`;

	return ofuscatedCardNumber;
};

export const encryptCardNumber = (cardNumber: any) => {
	const buffer = Buffer.from(cardNumber.split(' ').join(''), 'utf8');
	const publicKey = `-----BEGIN PUBLIC KEY-----\n${process.env.REACT_APP_PUBLIC_CERT}\n-----END PUBLIC KEY-----`;
	const encrypted = crypto.publicEncrypt(publicKey, buffer);

	return encrypted.toString('base64');
};

// export const paymentMapper = ({
//   // paymentMethod,
//   cmrCard,
//   webpayCard,
//   webpayCardMonth,
//   webpayCardYear,
// }: IEmitForm) =>
//   paymentMethod === 'CMR'
//     ? {
//         cardNumber: obfuscateCardNumber(cmrCard),
//         method: paymentMethod,
//       }
//     : {
//         cardNumber: encryptCardNumber(webpayCard),
//         expiryMonth: webpayCardMonth,
//         expiryYear: webpayCardYear,
//         method: paymentMethod,
//       };

export const calculateAge = (date: string) => {
	const today = moment();
	const birth = moment(date, 'DD-MM-YYYY');
	const different = moment.duration(today.diff(birth));
	const days = different.asDays();
	const years = different.asYears();

	return {
		days,
		years,
	};
};

export const activePromo = () => {
	const datePromoStart = new Date('2019-10-07T02:30:00.000Z');
	const datePromoEnd = new Date('2019-10-14T03:00:00.000Z');
	const actualDate = new Date();

	return datePromoStart <= actualDate && datePromoEnd > actualDate;
};

export const activePromoExtended = () => {
	const datePromoStart = new Date('2019-05-28T04:00:00.000Z');
	const datePromoEnd = new Date('2019-06-30T04:00:00.000Z');
	const actualDate = new Date();

	return datePromoStart <= actualDate && datePromoEnd > actualDate;
};

export const feriaPromo = () => {
	const dateStart = new Date('2019-10-07T02:30:00.000Z');
	const dateEnd = new Date('2019-10-10T03:00:00.000Z');
	const actualDate = new Date();

	return dateStart <= actualDate && dateEnd > actualDate;
};

export const hashValue = (value : string): string => new JShashes.SHA256().setUTF8(true).hex(value);

export const formatPlate = (value: string) => value.toUpperCase().substr(0, 6);

export const removeAccentedChars = (source: string) => {
	const noValids = 'áéíóúÁÉÍÓÚñÑ';
	const replaceBy = 'aeiouAEIOUñÑ';
	let result = `${source}`;
	const chars = noValids.split('');
	for (let index = 0; index < chars.length; index++) {
		const element = chars[index];
		result = result.replace(new RegExp(element, 'g'), replaceBy[index]);
	}
	return result;
};

export const allEqual = (input: string): boolean =>
	input.split('').every((char) => char === input[0]);

export const defaultEncrypter = {
	decrypt: (text: any) => atob(text),
};

export const isMobileDevice = () =>
	/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
		navigator.userAgent,
	);
