import axios, { AxiosResponse } from 'axios';
import { FormErrors } from 'redux-form';
import { IValidationForm } from '../store/ValidationForm';
import session from './session';

const asyncValidatePlate = async (values: IValidationForm) => {
	const errors: FormErrors<IValidationForm> = {};
	const { plate } = values;
	try {
		const {
			data: { validFormat, vehicle },
		}: AxiosResponse<any> = await axios.get(`/vehicles/${plate}`);
		if (!validFormat) {
			errors.plate = 'Formato de placa inválida.';
		}
		if (vehicle) {
			// TODO dispatch vehicle info
			const { validFormat: valid, ...v } = vehicle;
			session.set('v', JSON.stringify(v));
		}
	} catch (error) {
		errors.plate = 'Hubo un error al intentar validar placa.';
	}

	if (errors.plate) {
		throw errors;
	}
};

export default asyncValidatePlate;
