import React from 'react';
import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';

import { Loading } from '../components';
import { IPaymentMethod } from '../store/Payment';
import focusInputOnError from '../helpers/focusInputOnError';
interface ISummaryProps {
	local: string;
	total: string;
	discount: string;
	brand: string;
	model: string;
	plate: string;
	year: string;
	company: string;
	companyImage: string;
	documentNumber: string;
	startDate: string;
	couponUse: boolean;
	coupon: any;
	paymentMethodSelected: IPaymentMethod;
	emitting: boolean;
	loading: boolean;
	inIframe: boolean;
	doIntention: () => void;
	doAlignet: () => void;
}

export function Summary(props: ISummaryProps) {
	const {
		local,
		total,
		brand,
		model,
		plate,
		year,
		company,
		companyImage,
		documentNumber,
		startDate,
		couponUse,
		coupon,
		emitting,
	} = props;

	const getDiscount = () => {
		let disc = '- S/ 0';
		switch (coupon.discountType) {
			case 'amountable':
				const discountValue = Math.round(coupon.discountValue * 100) / 100;
				disc = `- S/ ${discountValue}`;
				break;
			case 'fixable':
				disc = `S/ ${coupon.discountValue}`;
				break;
			case 'percentable':
				disc = `- % ${Math.round(coupon.discountValue * 10) / 10}`;
				break;
		}
		return disc;
	};

	const getDate = (dateParam: any) => {
		const existDate = !!dateParam;
		return existDate && typeof dateParam !== 'string'
			? new Date(dateParam)
					.toISOString()
					.split('T')[0]
					.split('-')
					.reverse()
					.join('-')
			: dateParam.split('-').reverse().join('-');
	};

	const productContent = (
		<div className="product">
			<div className="details mb15 pt10">
				<div className="text">
					<label className="name">SOAT {new Date().getFullYear()}</label>
					<label className="vehicle">
						{brand} {model} {year}
					</label>
				</div>
				<div className="d-flex">
					<span className="price">
						<b>S/ {total}</b>
					</span>
				</div>
			</div>
			<ul>
				<li className="text">DNI: {documentNumber}</li>
				<li className="text">Placa: {plate.toUpperCase()}</li>
				<li className="text">Inicio de vigencia: {getDate(startDate)}</li>
			</ul>
			<div className="image d-flex py-4">
				<img src={companyImage} alt={company} width="120px" />
			</div>
		</div>
	);

	return (
		<div className="summary">
			<div className="summaryHeader">
				<p className="title mt-3 mb-10">Resumen de la compra</p>
			</div>
			{productContent}
			{couponUse && coupon.discountType ? (
				coupon.discountType === 'fixable' ? (
					<div className="subtotal pt-3">
						<div className="value discount d-flex flex-row justify-content-between w-100">
							<span className="text"> Precio Final </span>
							<span className="price">S/ {local}</span>
						</div>
					</div>
				) : (
					<div className="subtotal pt-3">
						<div className="value d-flex flex-row justify-content-between w-100">
							<span className="text"> Subtotal </span>
							<span className="price">S/ {total}</span>
						</div>
						<div className="value discount d-flex flex-row justify-content-between w-100">
							<span className="text"> Descuento </span>
							<span className="price">{getDiscount()}</span>
						</div>
					</div>
				)
			) : null}

			<div className="value d-flex flex-row align-items-center justify-content-between w-100 summary-total-price py-4 px-3">
				<span className="text">Total a pagar</span>
				<span className="price">S/ {local}</span>
			</div>
			{emitting ? <Loading /> : null}
		</div>
	);
}

export default withRouter(
	reduxForm<any, any>({
		enableReinitialize: true,
		form: 'payment',
		onSubmitFail: focusInputOnError,
	})(Summary),
);
