import React from 'react';
import { EventNameUA, EventNameGA4, sendUACustomEvent, sendGA4UserInteractionEvent } from '../helpers/gtm';
import { ICoupon } from '../store/Coupon';
import { IPlan } from '../store/Vehicle';
import PlanCouponDetail from './PlanCouponDetail';

interface IPlanProps {
	planData: IPlan;
	planNumber: number;
	[key: string]: any;
	selectPlan: (payload: IPlan) => any;
	coupon: ICoupon;
	couponUse: boolean;
	isMoto: boolean;
}

export default function Plan(props: IPlanProps) {
	const { planData, selectPlan, coupon, couponUse } = props;

	const select = () => {
		let eventName: EventNameUA | any;
		let eventNameGA4: EventNameGA4 | any;
		if (planData.company.name.toLowerCase().includes('protecta')) {
			eventName = EventNameUA.ClickQuieroContratarProtectaSecurity;
			eventNameGA4 = EventNameGA4.ClickQuieroContratarProtectaSecurity;
		} else if (planData.company.name.toLowerCase().includes('rimac')) {
			eventName = EventNameUA.ClickQuieroContratarRimac;
			eventNameGA4 = EventNameGA4.ClickQuieroContratarRimac;
		} else if (planData.company.name.toLowerCase().includes('la positiva')) {
			eventName = EventNameUA.ClickQuieroContratarLaPositiva;
			eventNameGA4 = EventNameGA4.ClickQuieroContratarLaPositiva;
		} else if (planData.company.name.toLowerCase().includes('vivir seguros')) {
			eventName = EventNameUA.ClickQuieroContratarLaPositiva;
			eventNameGA4 = EventNameGA4.ClickQuieroContratarVivirSeguros;
		}

		sendUACustomEvent(eventName, props.isMoto);
		sendGA4UserInteractionEvent(eventNameGA4);

		selectPlan(planData);
	};

	const handleOnClickMoto = () => {
		sendGA4UserInteractionEvent(EventNameGA4.ClickContratarMoto);
		window.location.href = planData.redirect ? planData.redirect : '';
	};

	return (
		<article className="px-3 d-flex justify-content-center">
			<div className="product-card quote">
				<header>
					<figure>
						<img src={planData.company.image} alt={planData.company.name} />
					</figure>
				</header>
				<hr />
				<section>
					<PlanCouponDetail coupon={coupon} couponUse={couponUse} />
					<h4 className="product-card--price">
						{planData.type === 'default' ? (
							planData.premiums.local
						) : (
							<>
								<span>s/</span>
								{planData.premiums.local}
								{couponUse && planData.premiums.total ? (
									<span className="offer"> &nbsp; (oferta)</span>
								) : null}
							</>
						)}
					</h4>

					{couponUse && planData.premiums.total ? (
						<h4 className="product-card--old-price">
							<span>s/</span>
							{planData.premiums.total}
							<span className="normal-price"> normal</span>
						</h4>
					) : null}
				</section>

				<div className="product-card--promos" />

				{planData.company.promo && <div className="promo-image">
					<figure className="promo-image">
						<img src={planData.company.promo} alt={'promo'} />
					</figure>
				</div>}


				<hr />

				<div className="product-card--button d-flex justify-content-center pb-3">
					{planData.type !== 'default' ? (
						<button
							className="btn primary"
							id="emit"
							type="button"
							onClick={select}
						>
							Quiero contratar
						</button>
					) : (
						<button
							className="btn primary"
							id="emit"
							type="button"
							onClick={handleOnClickMoto}
						>
							Quiero contratar
						</button>
					)}
				</div>
			</div>
		</article>
	);
}
