import { AnyAction } from 'redux';
import { put } from 'redux-saga/effects';
import { paymentMethodsResponse } from '../helpers/data';
import { IOptions } from '../components/FormSelect';
import { dataInstallments } from '../helpers/data';

export const GET_PAYMENT_METHODS = 'PAYMENT_METHODS/GET';
export const SET_PAYMENT_METHODS = 'PAYMENT_METHODS/SET';
export const SET_INSTALMENTS_DATA = 'INSTALMENTS_DATA/SET';

export const SELECT_PAYMENT_METHOD = 'PAYMENT_METHOD/SELECT';
export const SET_PAYMENT_METHOD = 'PAYMENT_METHOD/SET';
export const PAYMENT_METHODS_LOADING = 'PAYMENT/LOADING';
export const SET_FORM_ERROR = 'SET_PAYMENT_FORM_ERROR';
export const URL_PAYMENT_METHODS = '/paymentMethods';
export const URL_PAYMENT = '/payment';
export const SET_INSTALMENTS = 'INSTALMENT/SET';
export const SET_DEFAULT_INSTALMENT = 'INSTALMENT/SET_DEFAULT';
export const SET_SELECT_INSTALLMENT = 'INSTALMENT/SET_SELECT';
export const SET_INSTALMENT_NULL = 'INSTALMENT_NULL/SET';

export interface IPaymentMethod {
	key: string;
	name: string;
	label: string;
	ulrImage: string;
	id: string;
	active: boolean;
	urlPayment: string;
}

/**
 * Custom errors in forms
 * @property {string} name name of field. MUST be aproperty of {IValidationForm}
 * @property {string} value error description
 */
export interface IValidationCustomErrors {
	name: string;
	value: string;
}

export const getPaymentMethods = () => ({
	type: GET_PAYMENT_METHODS,
});

export const setInstalmentsData = () => ({
	type: SET_INSTALMENTS_DATA,
});

export const setSelectInstalment = (payload: number) => ({
	payload,
	type: SET_SELECT_INSTALLMENT,
});

export const setPaymentMethods = (payload: any) => ({
	payload,
	type: SET_PAYMENT_METHODS,
});

export const selectedPaymentMethod = (payload: IPaymentMethod) => ({
	payload,
	type: SELECT_PAYMENT_METHOD,
});

export const setSelectedPaymentMethod = (payload: IPaymentMethod) => ({
	payload,
	type: SET_PAYMENT_METHOD,
});

export const setPaymentMethodsLoading = (payload: boolean) => ({
	payload,
	type: PAYMENT_METHODS_LOADING,
});

export const setFormErrors = (payload: IValidationCustomErrors) => ({
	payload,
	type: SET_FORM_ERROR,
});

export const setInstalments = (payload: any) => ({
	payload,
	type: SET_INSTALMENTS,
});

export const setDefaultInstalment = (payload: IOptions) => ({
	payload,
	type: SET_DEFAULT_INSTALMENT,
});

export const setInstalmentNull = () => ({
	type: SET_INSTALMENT_NULL,
});

const initialState = {
	submitting: false,
	paymentMethodSelected: {},
	errors: {
		paymentMethods: '',
	},
	instalments: [],
	defaultInstalment: {},
	instalment: null,
};

export function* selectPaymentMethod({ payload }: AnyAction) {
	yield put(setPaymentMethodsLoading(true));
	yield put(setSelectedPaymentMethod(payload));
	yield put(setPaymentMethodsLoading(false));
}

export function* paymentMethods() {
	try {
		yield put(setPaymentMethods([]));
		const { data } = paymentMethodsResponse; // yield call(axios.get, URL_PAYMENT_METHODS);
		yield put(setPaymentMethods(data));
	} catch (error) {
		yield put(
			setFormErrors({
				name: 'paymentMethods',
				value: 'Lo sentimos, no hemos podido mostrar los métodos de pago',
			}),
		);
	}
}

export function* instalments() {
	yield put(setInstalments(dataInstallments));
	yield put(setDefaultInstalment(dataInstallments[0]));
}

export default function paymentReducer(
	state = initialState,
	action: AnyAction,
) {
	switch (action.type) {
		case PAYMENT_METHODS_LOADING:
			return {
				...state,
				submitting: action.payload,
			};
		case SELECT_PAYMENT_METHOD:
			return {
				...state,
				paymentMethodSelected: action.payload,
			};

		case SET_PAYMENT_METHODS:
			return {
				...state,
				paymentMethods: action.payload,
			};
		case SET_FORM_ERROR:
			return {
				...state,
				errors: {
					...state.errors,
					[action.payload.name]: action.payload.value,
				},
			};
		case SET_INSTALMENTS:
			return {
				...state,
				instalments: action.payload,
			};
		case SET_DEFAULT_INSTALMENT:
			return {
				...state,
				defaultInstalment: action.payload,
			};
		case SET_SELECT_INSTALLMENT:
			return {
				...state,
				instalment: action.payload,
			};
		case SET_INSTALMENT_NULL:
			return {
				...state,
				instalment: null,
			};
		default:
			return state;
	}
}
