import * as React from 'react';
import { ICoupon } from '../store/Coupon';

interface IPlanCouponDetailProps {
	coupon: ICoupon;
	couponUse: boolean;
}
export default function PlanCouponDetail(props: IPlanCouponDetailProps) {
	const { coupon, couponUse } = props;

	const typeCoupon = () => {
		switch (coupon.discountType) {
			case 'amountable':
				return (
					<p>
						<span>-s/{coupon.discountValue} dcto </span>
					</p>
				);
			case 'fixable':
				return <p>Por solo</p>;
			case 'percentable':
				return (
					<p>
						<span>{coupon.discountValue}% dcto </span>
					</p>
				);
			default:
				return <p>&nbsp;</p>;
		}
	};

	return (
		<>
			{couponUse ? (
				<div className="product-card--coupon">{typeCoupon()}</div>
			) : null}
		</>
	);
}
