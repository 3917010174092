import React, { useEffect, useState } from 'react';
import { Link } from 'react-scroll';

const className = (state: boolean): string =>
	state ? 'scroll-up-button show' : 'scroll-up-button hide';

export default function ScrollUpButton() {
	const [state, setState] = useState(false);

	const scroll = () => {
		const current = window.scrollY > 200;

		setState(current);
	};

	useEffect(() => {
		window.addEventListener('scroll', scroll);

		return () => window.removeEventListener('scroll', scroll);
	}, []);

	return (
		<Link
			className={className(state)}
			activeClass=" "
			to="index"
			spy={true}
			smooth={true}
			offset={-300}
			duration={500}
		>
			<i className="material-icons">keyboard_arrow_up</i>
		</Link>
	);
}
