import axios from 'axios';
import { push } from 'connected-react-router';
import { AnyAction } from 'redux';
import { put, select, call } from 'redux-saga/effects';
import { emitPersonalInformationForm } from './Emit';
import {
	getInsuredDepartments,
	getInsuredProvinces,
	getInsuredDistricts,
} from './ValidationForm';

export const SELECT_PLAN = 'QUOTE/SELECT';
export const SET_PLAN = 'PLAN/SELECT';
export const MATRIX_LOADING = 'MATRIX/LOADING';
export const URL_DEPARTMENTS = '/departments';
export const URL_PROVINCES = '/provincesByDepartment';
export const URL_DISTRICTS = '/districtsByProvince';
export const URL_PERSON = '/optimus/person';

const stringNumberDateToDateType = (stringDate: any) => {
	try {
		if (stringDate) {
			const month = stringDate.substring(4, 6);
			const day = stringDate.substring(6, 8);
			const year = stringDate.substring(0, 4);
			// tslint:disable-next-line
			const dateAsDateType = new Date(
				parseInt(year, 10),
				parseInt(month, 10) - 1,
				parseInt(day, 10),
				0,
				0,
				0,
				0,
			);
			return dateAsDateType;
		}
	} catch (error) {
		return new Date();
	}
};

const tryGetStreetNumber = (street: any) => {
	try {
		if (street) {
			const existNumber = street.match(/\d+/g).length > 0;
			if (existNumber) {
				return street.match(/\d+/g)[0];
			}
			return street;
		}
	} catch (error) {
		return 0;
	}
};

interface IAppData {
	Emit: {
		data: {
			name: string;
		};
	};
	ValidationForm: {
		data: {
			documentId: string;
		};
	};
}
export interface IPlan {
	company: {
		id: string;
		name: string;
		image: string;
	};
	properties: {
		id: string;
		name: string;
		details: string;
		benefits: string;
		characteristics: string | null;
		deductible: number;
		campaign: string | null;
	};
	premiums: {
		annual: number;
		monthly: number;
		local: number;
		period: string;
		total: number;
	};
}

export const selectedPlan = (payload: IPlan) => ({
	payload,
	type: SELECT_PLAN,
});

export const setSelectedPlan = (payload: IPlan) => ({
	payload,
	type: SET_PLAN,
});

export const setMatrixLoading = (payload: boolean) => ({
	payload,
	type: MATRIX_LOADING,
});

export function* selectPlan({ payload }: AnyAction) {
	yield put(setMatrixLoading(true));
	yield put(setSelectedPlan(payload));
	try {
		const data: IAppData = yield select();
		const existData = data.Emit.data.name !== '';
		if (!existData) {
			const { data: personaData } = yield call(
				axios.get,
				`${URL_PERSON}/${data.ValidationForm.data.documentId}`,
			);
			const existPersonData = personaData.Nombres !== '';
			if (existPersonData) {
				let province = '';
				let district = '';
				let department = '';

				const { data: departmenst } = yield call(axios.get, URL_DEPARTMENTS);
				const firstDepartment = departmenst
					? departmenst.find((i: any) =>
							i.name.toUpperCase() === personaData.Departamento
								? personaData.Departamento.toUpperCase()
								: '',
					  )
					: null;
				department = firstDepartment ? firstDepartment.code : '';

				const existDepartament = !!department;
				if (existDepartament) {
					yield put(getInsuredDepartments());
					yield put(getInsuredProvinces({ departmentCode: department }));

					const { data: provinces } = yield call(
						axios.get,
						`${URL_PROVINCES}/${department}`,
					);
					const firstProvince = provinces
						? provinces.find((i: any) =>
								i.name.toUpperCase() === personaData.Provincia
									? personaData.Provincia.toUpperCase()
									: '',
						  )
						: null;
					province = firstProvince ? firstProvince.code : '';

					const existProvince = !!province;
					if (existProvince) {
						yield put(getInsuredDistricts({ provinceCode: province }));

						const { data: districts } = yield call(
							axios.get,
							`${URL_DISTRICTS}/${province}`,
						);
						const firstDistricts = districts
							? districts.find((i: any) =>
									i.name.toUpperCase() === personaData.Distrito
										? personaData.Distrito.toUpperCase()
										: '',
							  )
							: null;
						district = firstDistricts ? firstDistricts.code : '';
					}
				}

				yield put(
					emitPersonalInformationForm({
						address: personaData.Direccion
							? personaData.Direccion.replace(/\./g, '')
							: personaData.Direccion,
						addressNumber: tryGetStreetNumber(
							personaData.Direccion
								? personaData.Direccion.replace(/\./g, '')
								: personaData.Direccion,
						),
						birthdate: stringNumberDateToDateType(
							personaData.FechaNacimiento,
						) as any,
						department,
						district,
						email: '',
						maternal: personaData.ApMaterno,
						name: personaData.Nombres,
						paternal: personaData.ApPaterno,
						phone: '',
						province,
						politycs: false,
						news: false,
						gender: personaData.deGenero,
						relationship: personaData.estadoCivil,
					}),
				);
			}
		}
		// tslint:disable-next-line
	} catch (error) {}
	yield put(push('/emit'));
	yield put(setMatrixLoading(false));
}

const initialState = {
	submitting: false,
};

export default function validationsFormReducer(
	state = initialState,
	action: AnyAction,
) {
	switch (action.type) {
		case MATRIX_LOADING:
			return {
				...state,
				submitting: action.payload,
			};
		default:
			return state;
	}
}
