import React from 'react';
import { connect } from 'react-redux';

import { Container, Working } from '../components';

interface IResolveContainerProps {
	reset: () => void;
}

export function ResolveContainer(props: IResolveContainerProps) {
	return (
		<Container>
			<Working />
		</Container>
	);
}

export default connect()(ResolveContainer);
