import axios from 'axios';
import moment from 'moment';
import { push } from 'connected-react-router';
import { AnyAction } from 'redux';
import { call, put, select } from 'redux-saga/effects';
import { IOptions } from '../components/FormSelect';
import { showError } from './Commons';
import {
	setValidationsForm,
	getInsuredDepartments,
	getInsuredProvinces,
	getInsuredDistricts,
} from './ValidationForm';
import { IValidationForm } from './ValidationForm';
import { emitPersonalInformationForm } from './Emit';
import session from '../helpers/session';
import { getCouponData } from './Coupon';
import {
	motoTypesResponseMapped,
	motoUseResponseMapped,
} from '../helpers/data';
import { dataQuotes } from '../helpers/data';

export const GET_VEHICLE_USES = 'GET_VEHICLE_USES';
export const DO_QUOTE = 'DO_QUOTE';
export const DO_DATA_BY_PROPOSALID = 'DO_DATA_BY_PROPOSALID';
export const GET_VEHICLE_TYPES = 'GET_VEHICLE_TYPES';
export const GET_VEHICLES_BRAND = 'GET_VEHICLES_BRAND';
export const GET_VEHICLES_MODELS = 'GET_VEHICLES_MODELS';
export const SET_VEHICLE_VALUES = 'SET_VEHICLE_VALUES';
export const VEHICLE_LOADING = 'VEHICLE/LOADING';
export const SELECT_PLAN = 'QUOTE/SELECT';
export const STOP_VEHICLE_LOADING = 'VEHICLE/SUBMIT/FAIL';
export const SET_VEHICLE_FORM = 'VEHICLEFORM/SET';
export const SET_VEHICLES_TYPES = 'SET_VEHICLES_TYPES';
export const SET_VEHICLES_BRANDS = 'SET_VEHICLES_BRAND';
export const URL_VEHICLE_USES = '/uses';
export const URL_VEHICLE_TYPES = '/types';
export const URL_VEHICLE_BRANDS = '/brands';
export const URL_VEHICLE_MODELS = '/modelsByBrand';
export const URL_QUOTE = '/quotes';
export const URL_DATA_VEHICLE_BY_PROPOSALID = '/proposal';
export const LINEWVIEW_CHANGE = 'LINEWVIEW_CHANGE';
export const SET_VEHICLE_FORM_ERROR = 'SET_FORM_ERROR';
export const SET_USES = 'SET_USES';
export const SET_BRAND = 'SET_BRAND';
export const SET_SERIAL = 'SET_SERIAL';
export const SET_USE = 'SET_USE';
export const SET_YEAR = 'SET_YEAR';
export const SET_TYPE = 'SET_TYPE';
export const SET_PLAN = 'PLAN/SELECT';
export const SET_PLANS = 'SET_PLANS';
export const SET_MODELS = 'SET_MODELS';
export const SET_MODEL = 'SET_MODEL';
export const SET_MODEL_BY_TYPE = 'SET_MODEL_BY_TYPE';
export const SET_MOTO = 'SET_MOTO';

const addDay = (dateString: string) => {
	const day = dateString.split('-')[2];
	const year = dateString.split('-')[0];
	const month = dateString.split('-')[1];
	// tslint:disable-next-line
	const nextDayResult =
		parseInt(day, 10).toString().length === 1
			? `0${parseInt(day, 10) + 1}`
			: `${parseInt(day, 10) + 1}`;
	// tslint:disable-next-line
	return `${parseInt(year) + 1}-${month}-${nextDayResult}`;
};

const stringDateToDateType = (stringDate: any) => {
	try {
		if (stringDate) {
			const splitDate = stringDate.split('-');
			const month = splitDate[1];
			const day = splitDate[2];
			const year = splitDate[0];
			// tslint:disable-next-line
			const fixMonth = month.startsWith('0')
				? parseInt(month[1], 10) - 1
				: parseInt(month, 10);
			// tslint:disable-next-line
			const dateAsDateType = new Date(
				parseInt(year, 10),
				fixMonth,
				parseInt(day, 10),
				0,
				0,
				0,
				0,
			);
			return dateAsDateType;
		}
	} catch (error) {
		return new Date();
	}
};

const delay = (time: number) =>
	new Promise((resolve) => setTimeout(resolve, time));
const generateErrorRenewMessage = (time: any) =>
	`No se pudo realizar la cotización.\r\n Volviendo a la página principal en ${time} segundos... `;

export interface IVehicleForm {
	type: string;
	brand: string;
	model: string;
	modelAsNumber: string;
	use: string;
	year: string;
	serial: string;
	seats: string;
	engineSerial: string;
	plate?: string;
}

export interface IPlan {
	company: {
		id: string;
		name: string;
		image: string;
		promo: string;
	};
	properties: {
		id: string;
		name: string;
		details: string;
		benefits: string;
		characteristics: string | null;
		deductible: number;
		campaign: string | null;
	};
	premiums: {
		annual: number;
		monthly: number;
		local: number;
		period: string;
		total: number;
	};
	redirect?: string;
	type?: string;
}

export interface IPlateType {
	plate: string;
	isMoto?: boolean;
}

export interface IBrandType {
	brand: string;
	isMoto?: boolean;
}
export interface IModelType {
	type?: string;
	isMoto?: boolean;
}

export const stopVehicleFormLoading = () => ({
	type: STOP_VEHICLE_LOADING,
});

export const vehicleLoading = () => ({
	type: VEHICLE_LOADING,
});

export const setVehicleForm = (payload: IVehicleForm) => ({
	payload,
	type: SET_VEHICLE_FORM,
});

export const getVehicleUses = () => ({
	type: GET_VEHICLE_USES,
});

export const setVehicleUses = (payload: IOptions[]) => ({
	payload,
	type: SET_USES,
});

export const getVehicleTypes = () => ({
	type: GET_VEHICLE_TYPES,
});

export const setVehicleTypes = (payload: IOptions[]) => ({
	payload,
	type: SET_VEHICLES_TYPES,
});

export const getVehicleBrands = () => ({
	type: GET_VEHICLES_BRAND,
});

export const setVehicleBrands = (payload: IOptions[]) => ({
	payload,
	type: SET_VEHICLES_BRANDS,
});

// no se si se usa
export const selectedPlan = (payload: IPlan) => ({
	payload,
	type: SELECT_PLAN,
});

export const linewViewChange = (payload: boolean) => ({
	payload,
	type: LINEWVIEW_CHANGE,
});

export const setSelectedPlan = (payload: IPlan) => ({
	payload,
	type: SET_PLAN,
});

export const doQuote = (payload: IVehicleForm) => ({
	payload,
	type: DO_QUOTE,
});

export const doRenewByProposalId = (payload: string) => ({
	payload,
	type: DO_DATA_BY_PROPOSALID,
});

export const setBrand = (payload: string) => ({
	payload,
	type: SET_BRAND,
});

export const setSerial = (payload: string) => ({
	payload,
	type: SET_SERIAL,
});

export const setUse = (payload: string) => ({
	payload,
	type: SET_USE,
});

export const setYear = (payload: string) => ({
	payload,
	type: SET_YEAR,
});

export const setType = (payload: string) => ({
	payload,
	type: SET_TYPE,
});

export const setModel = (payload: string) => ({
	payload,
	type: SET_MODEL,
});

export const setModelAsNumber = (payload: string) => ({
	payload,
	type: SET_MODEL_BY_TYPE,
});

export const getVehicleModels = (payload: string) => ({
	payload,
	type: GET_VEHICLES_MODELS,
});

export const setVehicleModels = (payload: IOptions[]) => ({
	payload,
	type: SET_MODELS,
});

export const setVehicleValues = (payload: string) => ({
	payload,
	type: SET_VEHICLE_VALUES,
});

export const setMoto = (payload: boolean) => ({
	payload,
	type: SET_MOTO,
});

export const getQuoteInformation = (state: any) => state.ValidationForm.data;
export const getVehicleData = (state: any) => state.Vehicle.data;
export const getVehicle = (state: any) => state.Vehicle;

const setPlans = (payload: any) => ({
	payload,
	type: SET_PLANS,
});

export const setFormErrors = (payload: any) => ({
	payload,
	type: SET_VEHICLE_FORM_ERROR,
});

export interface IStateDataVehicle {
	brand: string;
	model: string;
	modelAsNumber: string;
	serial: string;
	type: string;
	use: string;
	year: string;
	engineSerial: string;
	chasisSerial: string;
}

const initialState = {
	data: {
		brand: '',
		model: '',
		modelAsNumber: '',
		serial: '',
		type: '',
		use: '',
		year: '',
		engineSerial: '',
		chasisSerial: '',
	},
	errors: {
		brand: '',
		model: '',
		type: '',
		use: '',
	},
	lineView: false,
	plans: [],
	submitting: false,
	isMoto: false,
};

export default function vehicleReducer(
	state = initialState,
	action: AnyAction,
) {
	switch (action.type) {
		case VEHICLE_LOADING:
			return {
				...state,
				submitting: true,
			};
		case LINEWVIEW_CHANGE:
			return {
				...state,
				lineView: action.payload,
			};
		case SET_MODEL:
			return {
				...state,
				data: {
					...state.data,
					model: action.payload,
				},
			};
		case SET_MODEL_BY_TYPE:
			return {
				...state,
				data: {
					...state.data,
					modelAsNumber: action.payload,
				},
			};
		case STOP_VEHICLE_LOADING:
			return {
				...state,
				submitting: false,
			};
		case SET_VEHICLE_FORM:
			return {
				...state,
				data: action.payload,
			};
		case SET_VEHICLES_TYPES:
			return {
				...state,
				types: action.payload,
			};
		case SET_USES:
			return {
				...state,
				uses: action.payload,
			};
		case SET_TYPE:
			return {
				...state,
				data: {
					...state.data,
					type: action.payload,
				},
			};
		case SET_VEHICLES_BRANDS:
			return {
				...state,
				brands: action.payload,
			};
		case SET_BRAND:
			return {
				...state,
				data: {
					...state.data,
					brand: action.payload,
				},
			};

		case SET_SERIAL:
			return {
				...state,
				data: {
					...state.data,
					serial: action.payload,
				},
			};

		case SET_USE:
			return {
				...state,
				data: {
					...state.data,
					use: action.payload,
				},
			};

		case SET_YEAR:
			return {
				...state,
				data: {
					...state.data,
					year: action.payload,
				},
			};

		case SET_MODELS:
			return {
				...state,
				models: action.payload,
			};
		case SET_PLANS:
			return {
				...state,
				control: action.payload.control,
				plans: action.payload.plans,
				quoteId: action.payload.id,
				branch: action.payload.branch,
				consecutive: action.payload.consecutive,
				transactionId: action.payload.transactionId,
			};
		case SET_PLAN:
			return {
				...state,
				planSelected: action.payload,
			};
		case SET_VEHICLE_FORM_ERROR:
			return {
				...state,
				errors: {
					...state.errors,
					[action.payload.name]: action.payload.value,
				},
			};
		case SET_MOTO:
			return {
				...state,
				isMoto: action.payload,
			};
		default:
			return state;
	}
}

export function* uses() {
	try {
		const { data } = yield call(axios.get, URL_VEHICLE_USES);
		const { isMoto } = yield select(getVehicle);

		const usesList: IOptions[] = isMoto
			? motoUseResponseMapped
			: data.map(({ code, name }: any) => ({
					name,
					value: code,
			  }));

		yield put(setVehicleUses(usesList));
	} catch (error) {
		yield put(
			setFormErrors({
				name: 'use',
				value: 'Lo sentimos, no hemos podido mostrar los usos',
			}),
		);
	}
}

export function* brands(action: AnyAction) {
	try {
		yield put(setVehicleBrands([]));
		yield put(setVehicleModels([]));
		const { isMoto } = yield select(getVehicle);

		const { data } = yield call(
			axios.get,
			`${URL_VEHICLE_BRANDS}${isMoto ? '?type=moto' : ''}`,
		);

		const brandsList: IOptions[] = data.map(({ code, name }: any) => ({
			name,
			value: code,
		}));

		yield put(setVehicleBrands(brandsList));
	} catch (error) {
		yield put(
			setFormErrors({
				name: 'brand',
				value: 'Lo sentimos, no hemos podido mostrar las marcas',
			}),
		);
	}
}

export function* models(action: AnyAction) {
	try {
		yield put(setBrand(action.payload));
		const { isMoto } = yield select(getVehicle);
		yield put(setVehicleModels([]));
		const { data } = yield call(
			axios.get,
			`${URL_VEHICLE_MODELS}/${action.payload}${isMoto ? '?type=moto' : ''}`,
		);

		const modelsList: IOptions[] = data.map(({ code, name }: any) => ({
			name,
			value: code,
		}));

		yield put(setVehicleModels(modelsList));
	} catch (error) {
		yield put(
			setFormErrors({
				name: 'model',
				value: 'Lo sentimos, no hemos podido mostrar los modelos',
			}),
		);
	}
}

export function* infoVehicleToPlate({ payload }: AnyAction) {
	const state = (yield select()) as unknown as any;

	if (
		state.Vehicle.isMoto &&
		process.env.REACT_APP_MOTO_SKIP_QUOTE === 'true'
	) {
		let newPlans: any = [];
		newPlans = dataQuotes.moto;
		const data = { plans: newPlans };
		yield put(setPlans(data));
		yield put(stopVehicleFormLoading());
		yield put(push('/plans'));
	} else {
		yield put(vehicleLoading());

		const defaultSeats = '5';
		let vehicle: any = null;

		try {
			const v = session.get('v') || '';
			vehicle = JSON.parse(v);
			const { Vehicle: VehicleCache } = yield select();
			if (VehicleCache.data && VehicleCache.data.plate === vehicle.plate) {
				yield put(stopVehicleFormLoading());
				return;
			}
		} catch (error) {
			// tslint:disable-next-line: no-console
			console.error('Error al obtener la placa guardada.');
		}

		try {
			if (vehicle) {
				const seats =
					!vehicle.seats || vehicle.seats === '0' ? '' : vehicle.seats;
				const { isMoto } = yield select(getVehicle);

				yield put(getVehicleBrands());

				const newPayload: any = {
					...vehicle,
					brand: vehicle.brand.code,
					model: vehicle.model.code,
					use: vehicle.use.code,
					type: vehicle.type.code,
					seats: `${seats}`,
					modelAsNumber: vehicle.model.code,
				};

				if (newPayload.brand) {
					yield put(getVehicleModels(newPayload.brand));
				}
				const isValidSeats = !isNaN(newPayload.seats) && newPayload.seats > 0;
				if (!isValidSeats) {
					newPayload.seats = isMoto ? '2' : defaultSeats;
				}

				yield put(
					setVehicleForm({
						...newPayload,
					}),
				);

				const isValidAutoQuote =
					!!newPayload.brand &&
					!!newPayload.serial &&
					!!newPayload.chasisSerial &&
					!!newPayload.engineSerial &&
					!!newPayload.type &&
					!!newPayload.year &&
					`${newPayload.year}`.length > 1 &&
					!!newPayload.modelAsNumber &&
					!!newPayload.use;

				if (isValidAutoQuote) {
					yield put(
						doQuote({
							...newPayload,
							modelAsNumber: vehicle.model.code,
						}),
					);
				} else {
					yield put(stopVehicleFormLoading());
				}
			} else {
				yield put(showError('Por favor intente nuevamente'));
				yield put(stopVehicleFormLoading());
			}
		} catch (error) {
			yield put(showError('Por favor intente nuevamente'));
			// tslint:disable-next-line: no-console
			console.error(error);
			yield put(stopVehicleFormLoading());
		}
	}
	// yield call(delay, 1000 * 3);
}

export function* types(action: AnyAction) {
	try {
		yield put(setVehicleTypes([]));

		const { data } = yield call(axios.get, URL_VEHICLE_TYPES);
		const { isMoto } = yield select(getVehicle);

		const typesList: IOptions[] = isMoto
			? motoTypesResponseMapped
			: data.map((element: any) => ({
					name: element.name,
					value: element.code,
			  }));
		yield put(setVehicleTypes(typesList));
	} catch (error) {
		yield put(
			setFormErrors({
				name: 'type',
				value: 'Lo sentimos, no hemos podido mostrar las clases',
			}),
		);
	}
}

export function* updateVehicleModel(action: AnyAction) {
	yield put(setModelAsNumber(action.payload));
	yield put(setModel(action.payload));
}

export function* quote({ payload }: AnyAction) {
	const state = (yield select()) as unknown as any;

	const { data: vehicleData, isMoto } = state.Vehicle;

	let newPlans: any = [];

	try {
		yield put(vehicleLoading());

		yield put(
			setVehicleForm({
				...payload,
			}),
		);

		const { data: couponData, use: couponUse } = yield select(getCouponData);
		const coupon = couponUse
			? {
					discountType: couponData.discountType,
					discountValue: couponData.discountValue,
			  }
			: undefined;
		const { engineSerial } = yield select(getVehicleData);
		const quoteInformation: IValidationForm = yield select(getQuoteInformation);

		const { brand, type, use, year, seats, modelAsNumber, serial } = payload;
		const {
			vehicleDepartment,
			docType,
			documentId,
			plate,
			quotePrivacyPolicies,
			date,
		} = quoteInformation;

		yield call(uses);

		yield call(types, { payload: '', type: '' });

		yield call(brands, {
			payload: type,
			type: GET_VEHICLES_BRAND,
		});

		yield call(models, {
			payload: brand,
			type: GET_VEHICLES_MODELS,
		});

		const stateUpdated = (yield select()) as unknown as any;

		const {
			types: vehicleTypes,
			brands: vehicleBrands,
			models: vehicleModels,
			uses: vehicleUses,
		} = stateUpdated.Vehicle;

		const vehicleTypeName = vehicleTypes.filter(
			(vehicleType: IOptions) => vehicleType.value === vehicleData.type,
		)[0].name;

		const vehicleBrandName = vehicleBrands.filter(
			(vehicleBrand: IOptions) => vehicleBrand.value === brand,
		)[0].name;

		const vehicleModelName = vehicleModels.filter(
			(vehicleModel: IOptions) => vehicleModel.value === vehicleData.model,
		)[0].name;

		const vehicleUseName = vehicleUses.filter(
			(vehicleUse: IOptions) =>
				vehicleUse.value === vehicleData.use ||
				vehicleUse.code === vehicleData.use,
		)[0].name;

		const quoteRequest = {
			branch: '51',
			channel: 'INTERNET',
			executive: '38644',
			payload: {
				agreements: {
					privacyPersonalDataTerm: quotePrivacyPolicies,
				},
				insured: {
					document: {
						number: documentId,
						type: docType,
					},
				},
				vehicle: {
					chasisSerial: serial,
					engineSerial,
					address: {
						area: {
							level1: vehicleDepartment,
						},
					},
					brand: {
						code: brand,
						name: vehicleBrandName,
					},
					model: {
						code: modelAsNumber,
						name: vehicleModelName,
					},
					plate,
					seats,
					type: {
						code: type,
						name: vehicleTypeName,
					},
					use: {
						code: use,
						name: vehicleUseName,
					},
					year,
				},
				insurance: {
					startDate: moment(date).format('YYYY-MM-DD'),
				},
				coupon,
			},
		};

		const { data } = yield call(axios.post, URL_QUOTE, quoteRequest);

		if (isMoto) {
			const defaultDataQuotesMoto = dataQuotes.moto.map((e: any) => {
				e.quoteId = data.plans[0].quoteId ? data.plans[0].quoteId : 'abc123';
				return e;
			});

			newPlans = defaultDataQuotesMoto.concat(data.plans);

			data.plans = newPlans;
		}

		yield put(setPlans(data));
		yield put(stopVehicleFormLoading());
		yield put(push('/plans'));
	} catch (error) {
		if (isMoto) {
			newPlans = dataQuotes.moto;
			const data = { plans: newPlans };
			yield put(setPlans(data));
			yield put(stopVehicleFormLoading());
			yield put(push('/plans'));
		} else {
			yield put(stopVehicleFormLoading());
			yield put(showError('Por favor intente nuevamente'));
		}
	}
}

export function* selectPlan({ payload }: AnyAction) {
	yield put(setSelectedPlan(payload));
	yield put(push('/emit'));
}

export function* renewByProposalId({ payload }: AnyAction) {
	try {
		const proposalId = payload;
		const url = `${URL_DATA_VEHICLE_BY_PROPOSALID}/${proposalId}`;

		const { data } = yield call(axios.get, url);
		const dataQuote = {
			type: data.vehicle.type.code,
			brand: data.vehicle.brand.code,
			model: data.vehicle.model.name,
			modelAsNumber: data.vehicle.model.code,
			use: data.vehicle.use.code,
			year: data.vehicle.year,
			serial: data.vehicle.serial,
			seats: data.vehicle.seats,
			engineSerial: data.vehicle.engineSerial,
		};

		yield call(brands, {
			payload: data.vehicle.type.code,
			type: GET_VEHICLES_BRAND,
		});

		yield call(models, {
			payload: data.vehicle.brand.code,
			type: GET_VEHICLES_MODELS,
		});

		const nextDay = addDay(data.policy.startDate);

		const ivalidationForm = {
			plate: data.vehicle.plate,
			docType: 'DNI',
			vehicleDepartment: data.insured.address.area.level1,
			documentId: data.insured.document.number,
			date: nextDay,
			quotePrivacyPolicies: true,
			coupon: '',
		};

		yield put(setValidationsForm(ivalidationForm));

		yield call(types, {
			payload: data.vehicle.model.name,
			type: GET_VEHICLE_TYPES,
		});

		yield call(uses);

		yield put(getInsuredDepartments());

		yield put(
			getInsuredProvinces({ departmentCode: data.insured.address.area.level1 }),
		);
		yield put(
			getInsuredDistricts({ provinceCode: data.insured.address.area.level2 }),
		);

		yield put(doQuote(dataQuote));

		yield put(
			emitPersonalInformationForm({
				address: data.insured.address.area.level4,
				addressNumber: data.insured.address.area.level5,
				birthdate: stringDateToDateType(data.insured.birthdate) as any,
				department: data.insured.address.area.level1,
				district: data.insured.address.area.level3,
				email: data.insured.email,
				maternal: data.insured.maternal,
				name: data.insured.names,
				paternal: data.insured.paternal,
				phone: data.insured.phone,
				province: data.insured.address.area.level2,
				politycs: false,
				news: false,
				gender: data.insured.gender,
				relationship: data.insured.relationship,
			}),
		);
	} catch (error) {
		const maxTime = 5;

		for (let index = 0; index <= maxTime; index++) {
			yield call(delay, 1000);
			yield put(showError(generateErrorRenewMessage(maxTime - index)));
		}
		window.location.href = '/';
	}
}
