import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, Form, Input } from 'reactstrap';
import { IAdviserState } from '../store/Adviser';

const ERROR_TYPES = ['Debes ingresar un código', 'Ingresa un código válido'];

interface IModalAdviser {
	isOpen: boolean;
	handleModal: any;
	validateAdviser: (adviserCode: string) => void;
	saveAdviserCode: (adviserCode: string) => void;
	adviser: IAdviserState;
	resetStateAdviserCode: () => void;
}

function ModalAdviser(props: IModalAdviser) {
	const adviserData = props.adviser;
	const handleModalFunc = props.handleModal;

	const [code, setCode] = useState(['', '', '', '', '']);
	const [error, setError] = useState(false);
	const [errorType, setErrorType] = useState('');

	const inputRefs = useRef<HTMLInputElement[]>(Array(5).fill(null));

	const handleInputChange = (
		index: number,
		e: React.ChangeEvent<HTMLInputElement>,
	) => {
		const { value: newValue } = e.target;

		if (newValue.length <= 1) {
			const newCode = [...code];
			newCode[index] = newValue;
			setCode(newCode);
		}
		if (newValue.length === 1 && index < code.length - 1) {
			inputRefs.current[index + 1].focus();
		} else if (newValue.length === 1 && index === code.length - 1) {
			inputRefs.current[index].type = 'text';
			inputRefs.current[index].setSelectionRange(0, 0);
			inputRefs.current[index].type = 'number';
			const sendButton = document.querySelector(
				'.send-btn',
			) as HTMLButtonElement | null;
			if (sendButton) {
				sendButton.focus();
			}
			inputRefs.current[index].blur();
		}
	};

	const handleKeyDown = (
		index: number,
		e: React.KeyboardEvent<HTMLInputElement>,
	) => {
		if (e.key === 'Backspace' && code[index] === '' && index > 0) {
			inputRefs.current[index - 1].focus();
		}
	};

	const handleFocus = (index: number) => {
		inputRefs.current[index].select();
	};

	const sendCode = () => {
		const isEmpty = code.some((value) => value === '');
		if (!isEmpty) {
			const adviserCode = code.join('');
			props.saveAdviserCode(adviserCode);
			props.validateAdviser(adviserCode);
		} else {
			setErrorType(ERROR_TYPES[0]);
			setError(true);
		}
	};

	const handleClose = () => {
		handleModalFunc(false);
		props.resetStateAdviserCode();
		setCode(['', '', '', '', '']);
		setError(false);
		setErrorType('');
	};

	useEffect(() => {
		if (adviserData.fulfilled && adviserData.data.validAdviser) {
			setError(false);
			handleModalFunc(false);
		}
		if (adviserData.error.errorCode === 1 && !adviserData.data.validAdviser) {
			setErrorType(ERROR_TYPES[1]);
			setError(true);
			setCode(['', '', '', '', '']);
			inputRefs.current[0].focus();
		}
	}, [adviserData, error, errorType, handleModalFunc]);

	const inputsCode = code.map((cd, i) => (
		<Input
			key={i}
			id={`field${i}`}
			value={cd}
			onChange={(e) => handleInputChange(i, e)}
			onKeyDown={(e) => handleKeyDown(i, e)}
			className={error ? 'code-input-error' : 'code-input'}
			type="number"
			innerRef={(el: HTMLInputElement) => (inputRefs.current[i] = el)}
			onFocus={() => handleFocus(i)}
		/>
	));
	return (
		<Modal isOpen={props.isOpen} toggle={handleModalFunc}>
			<div className="modal-header">
				<div className="close-container">
					<Button className="d-sm-block" onClick={handleClose}>
						<i className="material-icons align-middle icon-close">close</i>
					</Button>
				</div>
			</div>
			<div className="modal-body">
				<div className="header-container">
					<span className="modal-title">Ingresa el código del asesor</span>
					<p className="sub-title">
						Si un asesor te ayudó en la compra, por favor, ingresa su código.
					</p>
				</div>
				<div className="inputs-container">
					<Form className="code-inputs">{inputsCode}</Form>
					<div className="info-container">
						{error && <div className="error-text">{errorType}</div>}
					</div>
				</div>
				<div className="btn-container">
					<Button className="send-btn d-sm-block" onClick={sendCode}>
						Enviar
					</Button>
				</div>
			</div>
		</Modal>
	);
}

export default ModalAdviser;
