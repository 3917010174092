import React, { RefObject } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Loading } from '../components';
import { isMobileDevice } from '../helpers/utils';
import { IPaymentMethod } from '../store/Payment';

interface IModalFPayProps {
	paymentMethod: IPaymentMethod;
	showModal: boolean;
	iframeUrl: string;
	loading: boolean;
	onChangeShowModal: (showModal: boolean) => void;
	changeLoadingState: (loadingState: boolean) => void;
	onLoadIframe: (
		frame: RefObject<HTMLIFrameElement>,
		eventStateCallback: () => void,
	) => any;
}

export default function ModalFPay(props: IModalFPayProps) {
	const {
		paymentMethod,
		showModal,
		onChangeShowModal,
		iframeUrl,
		loading,
		changeLoadingState,
		onLoadIframe,
	} = props;

	const iframeRef = React.useRef<HTMLIFrameElement>(null);
	const iframeStyles = loading
		? ({
				display: 'none',
		  } as const)
		: {};

	return (
		<div>
			<Modal
				modalClassName="modal-fpay"
				toggle={() => onChangeShowModal}
				isOpen={showModal}
				backdrop={'static'}
				keyboard={false}
			>
				<ModalHeader>
					{!isMobileDevice() && (
						<span>
							<img
								className="mr-20 align-middle float-lg-left"
								src={paymentMethod.ulrImage}
								alt="FPay"
								width="40px"
							/>
						</span>
					)}
					{isMobileDevice() && (
						<p className="mt-15">Continuar para abrir la app</p>
					)}
				</ModalHeader>
				<ModalBody>
					{loading && <Loading />}
					<div className="iframe" style={iframeStyles}>
						<iframe
							name="mainFrame"
							id="mainframe"
							className="border-0 mt-20"
							scrolling="no"
							sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-top-navigation"
							src={iframeUrl}
							title="FPay"
							ref={iframeRef}
							onClick={() => false}
							onLoad={onLoadIframe(iframeRef, () => changeLoadingState(false))}
						/>
					</div>
				</ModalBody>
				<ModalFooter>
					{!isMobileDevice() && (
						<Button
							color="primary"
							className="btn-dark"
							onClick={() => onChangeShowModal(showModal)}
						>
							Usar otro medio de pago
						</Button>
					)}
					{isMobileDevice() && (
						<div>
							<button
								type="button"
								className="btn-link btn-mobile"
								onClick={() => onChangeShowModal(showModal)}
							>
								Cancelar
							</button>
						</div>
					)}
				</ModalFooter>
			</Modal>
		</div>
	);
}
