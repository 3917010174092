import * as React from 'react';
import { Element } from 'react-scroll';

export default function QuoteFormTitle() {
	return (
		<Element name="index" className="col-12 mt60">
			<h1 className="quote-title">Compra tu SOAT Auto o Moto</h1>
			<h5 className="quote-subtitle text-gray mb40 px-16">
				Cotiza y compara al instante.
			</h5>
		</Element>
	);
}
