import React, { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { withRouter } from 'react-router-dom';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { FormCheckbox } from '.';
import validate from '../helpers/emitPersonalInformationValidate';
import { IEmitForm } from '../store/Emit';
import focusInputOnError from '../helpers/focusInputOnError';
import { ICoupon } from '../store/Coupon';
import { IEmitResumeForm } from '../store/ValidationForm';

interface IEmitResumeFormProps {
	errors: IEmitResumeForm;
	local: string;
	total: string;
	brand: string;
	model: string;
	plate: string;
	year: string;
	use: string;
	coupon: ICoupon;
	couponUse: boolean;
	returnPage: () => void;
}

export function EmitResumeForm(
	props: IEmitResumeFormProps &
		InjectedFormProps<IEmitResumeForm, IEmitResumeFormProps>,
) {
	const {
		handleSubmit,
		returnPage,
		local,
		total,
		brand,
		model,
		plate,
		year,
		use,
		valid,
		couponUse,
	} = props;

	const [politycs, setPolitycs] = useState(false);

	const onChangePolicies = () => {
		setPolitycs(!politycs);
	};

	const btnIsValid = () => {
		return valid && politycs;
	};

	useEffect(() => {
		// if (process.env.REACT_APP_ENVIRONMENT === 'production') {
		const tagManagerArgs = {
			dataLayer: {
				event: 'vpvPayment',
			},
			dataLayerName: 'dataLayer',
		};

		TagManager.dataLayer(tagManagerArgs);
		// }
	}, []);

	return (
		<div className="col-12 col-md-10 wizard-card">
			<form onSubmit={handleSubmit} noValidate={true}>
				<div className="row">
					<div className="col-12">
						<h2 className="wizard-card--title">
							Vigencia y pago
							<div className="progress-step">
								<div className="done" />
								<div className="current" />
							</div>
						</h2>
					</div>

					<div className="col-12 mt20 pb50">
						<div className="row">
							<div className="col-12 col-sm-4 col-lg-4">
								<span className="text-bold">Vehículo</span>
								<p>
									{brand}, {model}, {year}
								</p>
							</div>
							<div className="col-12 col-sm">
								<span className="text-bold">Placa</span>
								<p>{plate.toUpperCase()}</p>
							</div>
							<div className="col-12 col-sm">
								<span className="text-bold">Uso</span>
								<p>{use}</p>
							</div>

							{couponUse ? (
								<div className="col-12 col-sm-3 col-lg-3">
									<span className="text-bold"> Precio sin descuento </span>
									<p className="text-decoration-line-through">S/ {total}</p>
								</div>
							) : null}

							<div className="col-12 col-sm">
								<span className="text-bold">Total</span>
								<p>S/ {local}</p>
							</div>
							<div className="col-12">
								<button type="button" onClick={returnPage} className="p-0 link">
									Modificar mis datos
									<i className="mod-right-arrow uk-icon-chevron_right" />
								</button>
							</div>
						</div>
					</div>

					<div className="col-12 mb20">
						<Field
							name="politycs"
							component={FormCheckbox}
							onChange={onChangePolicies}
							text={[
								'He leído los ',
								<a
									href="https://sfestaticos.blob.core.windows.net/peru/soat/pdf/terminos-y-condiciones-soat.pdf"
									target="_blank"
									rel="noopener noreferrer"
									key="politycsKey"
								>
									Términos y Condiciones
								</a>,
								' del Seguro y acepto la ',
								<a
									href="//sfestaticos.blob.core.windows.net/peru/pdf/normativa/modelo-carta-de-nombramiento.pdf"
									target="_blank"
									rel="noopener noreferrer"
									key="politycs2Key"
								>
									Carta de Nombramiento
								</a>,
								' a favor de Corredores de Seguros Falabella.',
							]}
						/>
					</div>

					<div className="col-12 mb20">
						<Field
							name="news"
							component={FormCheckbox}
							text={[
								'Acepto los términos y condiciones para acceder al ',
								<a
									href="https://sfestaticos.blob.core.windows.net/pmc/peru/pdf/programa-cmr-puntos.pdf"
									target="_blank"
									rel="noopener noreferrer"
									key="newsKey"
								>
									Programa de CMR Puntos.
								</a>,
							]}
						/>
					</div>
				</div>
				<div className="row justify-content-center mt30 buttons-container">
					<div className="col-12 col-sm-5 col-md-4">
						<button
							id="pay"
							className={`btn ${btnIsValid() ? 'primary' : 'white'}`}
							type="submit"
							disabled={!btnIsValid()}
						>
							Pagar
						</button>
					</div>
				</div>
			</form>
		</div>
	);
}

export default withRouter(
	reduxForm<IEmitForm, any>({
		enableReinitialize: true,
		form: 'proposal',
		onSubmitFail: focusInputOnError,
		validate,
	})(EmitResumeForm),
);
