import * as React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import focusInputOnError from '../helpers/focusInputOnError';
import { EventNameUA, sendUACustomEvent } from '../helpers/gtm';
import { engineFormat, serialFormat } from '../helpers/utils';
import validate from '../helpers/vehicleFormValidate';
import { IVehicleForm } from '../store/Vehicle';
import { FormInput, FormSelect } from './';
import { IOptions } from './FormSelect';

interface IVehicleFormCustomProps {
	types: IOptions[];
	brands?: IOptions[];
	models?: IOptions[];
	uses?: IOptions[];
	plate: string;
	isMoto?: boolean;
	errors: any;
	submitRef: React.MutableRefObject<HTMLButtonElement | any> | undefined;
	getModels: (brand: string, isMoto: boolean) => void;
	setValid: (valid: boolean) => void;
}

export function VehicleForm(
	props: IVehicleFormCustomProps &
		InjectedFormProps<IVehicleForm, IVehicleFormCustomProps>,
) {
	const {
		handleSubmit,
		types,
		valid,
		brands,
		getModels,
		models,
		uses,
		plate,
		isMoto,
		errors: {
			brand: brandError,
			model: modelError,
			type: typeError,
			use: useError,
		},
		setValid,
		submitRef,
	} = props;
	const handleBrandChange = (event: any) => {
		getModels(event.target.value, isMoto || false);
	};

	const back = () => {
		sendUACustomEvent(EventNameUA.ClickDatosVehiculoBtnVolver, isMoto);
		window.history.back();
	};

	const isValid = () => {
		setValid(valid);
		return valid;
	};

	return (
		<form onSubmit={handleSubmit} noValidate={true}>
			<div className="row flex-column">
				<div className="col-12 col-sm-8 col-md-7 col-lg-6 form-group mx-auto mb20">
					<h2 className="wizard-card--title">
						Completa los datos del vehículo
					</h2>
				</div>

				{/* <div className="col-12 mb40">
					<p>
						¿No recuerdas todos los datos de tu vehículo? Consúltalos{' '}
						<a
							href="//www.sunarp.gob.pe/seccion/servicios/detalles/0/c3.html"
							target="_blank"
							rel="noopener noreferrer"
						>
							aquí
						</a>
					</p>
				</div> */}

				<div className="col-12 col-sm-8 col-md-7 col-lg-6 form-group mx-auto mb20">
					<input
						id={'plate'}
						type={'text'}
						name={'plate'}
						value={`Placa: ${plate.toUpperCase()}`}
						readOnly={true}
						className={`form-control`}
					/>

					{/* <Field
            name="plate"
            value={`Placa: ${plate.toUpperCase()}`}
            component={FormInput}
            formNoValidate={true}
					/> */}
				</div>

				<div className="w-100 d-none d-sm-block" />

				<div className="col-12 col-sm-8 col-md-7 col-lg-6 form-group mx-auto mb20">
					<label htmlFor="brand">Marca</label>
					<Field
						name="brand"
						placeholder={'Selecciona...'}
						options={brands}
						component={FormSelect}
						onChange={handleBrandChange}
						props={{ error: brandError }}
						onFocus={() =>
							sendUACustomEvent(EventNameUA.SelectDatosVehiculoMarca, !!isMoto)
						}
					/>
				</div>

				<div className="w-100 d-none d-sm-block" />

				<div className="col-12 col-sm-8 col-md-7 col-lg-6 form-group mx-auto mb20">
					<label htmlFor="model">Modelo</label>
					<Field
						name="model"
						placeholder={'Selecciona...'}
						options={models}
						component={FormSelect}
						props={{ error: modelError }}
						onFocus={() =>
							sendUACustomEvent(EventNameUA.SelectDatosVehiculoModelo, !!isMoto)
						}
					/>
				</div>

				<div className="w-100 d-none d-sm-block" />

				<div className="col-12 col-sm-8 col-md-7 col-lg-6 form-group mx-auto mb20">
					<label htmlFor="type">Clase</label>
					<Field
						name="type"
						placeholder={'Selecciona...'}
						options={types}
						component={FormSelect}
						disabled={isMoto}
						onFocus={() =>
							sendUACustomEvent(EventNameUA.SelectDatosVehiculoClase, !!isMoto)
						}
						props={{
							error: typeError,
							isDisabled: isMoto,
						}}
					/>
				</div>

				<div className="w-100 d-none d-sm-block" />

				<div className="col-12 col-sm-8 col-md-7 col-lg-6 form-group mx-auto mb20">
					<label htmlFor="use">Uso</label>
					<Field
						name="use"
						placeholder={'Selecciona...'}
						options={uses}
						component={FormSelect}
						props={{ error: useError }}
						onFocus={() =>
							sendUACustomEvent(EventNameUA.SelectDatosVehiculoUso, !!isMoto)
						}
					/>
				</div>

				<div className="w-100 d-none d-sm-block" />

				<div className="col-12 col-sm-8 col-md-7 col-lg-6 form-group mx-auto mb20">
					<label htmlFor="serial">Nº de serie / chasis</label>
					<Field
						name="serial"
						type="text"
						placeholder={'Ej. 12345678901234567'}
						component={FormInput}
						normalize={serialFormat}
						onFocus={() =>
							sendUACustomEvent(EventNameUA.InputDatosVehiculoSerie, !!isMoto)
						}
					/>
				</div>

				<div className="w-100 d-none d-sm-block" />

				{!isMoto && (
					<div className="col-12 col-sm-8 col-md-7 col-lg-6 form-group mx-auto mb20">
						<label htmlFor="serial">Nº de motor</label>
						<Field
							name="engineSerial"
							type="text"
							placeholder={'Ej. 12345678901234567'}
							component={FormInput}
							normalize={engineFormat}
							onFocus={() =>
								sendUACustomEvent(EventNameUA.InputDatosVehiculoMotor, !!isMoto)
							}
						/>
					</div>
				)}

				<div className="w-100 d-none d-sm-block" />

				<div className="col-12 col-sm-8 col-md-7 col-lg-6 form-group mx-auto mb20">
					<label htmlFor="year">Año</label>
					<Field
						name="year"
						type="number"
						placeholder={'Ej. 2024'}
						component={FormInput}
						onFocus={() =>
							sendUACustomEvent(EventNameUA.InputDatosVehiculoAnio, !!isMoto)
						}
					/>
				</div>

				<div className="w-100 d-none d-sm-block" />

				<div className="col-12 col-sm-8 col-md-7 col-lg-6 form-group mx-auto mb20">
					<label htmlFor="seats">Nº de asientos</label>
					<Field
						name="seats"
						type="number"
						placeholder={'Ej. 4'}
						disabled={isMoto}
						component={FormInput}
						onFocus={() =>
							sendUACustomEvent(EventNameUA.InputDatosVehiculoAsientos, !!isMoto)
						}
					/>
				</div>
			</div>

			<footer
				className="row justify-content-center mt30 buttons-container"
				style={{ display: 'none' }}
			>
				<div className="col-12 col-sm-5 col-md-4">
					<button className="btn white" type="button" onClick={back}>
						Volver
					</button>
				</div>
				<div className="col-12 col-sm-5 col-md-4">
					<button
						id="vehicle"
						className={`btn ${isValid() ? 'primary' : 'white'}`}
						type="submit"
						disabled={!isValid()}
						ref={submitRef}
						onClick={() =>
							sendUACustomEvent(
								EventNameUA.ClickDatosVehiculoBtnContinuar,
								!!isMoto,
							)
						}
					>
						Cotizar
					</button>
				</div>
			</footer>
		</form>
	);
}

export default reduxForm<IVehicleForm, IVehicleFormCustomProps>({
	enableReinitialize: true,
	form: 'vehicle',
	onSubmitFail: focusInputOnError,
	validate,
})(VehicleForm);
