import * as React from 'react';
import TagManager from 'react-gtm-module';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { change } from 'redux-form';
import { Container, Loading, VehicleForm } from '../components/';
import { IOptions } from '../components/FormSelect'; 
import {
	PageNameUA,
	PageNameGA4,
	sendUAPageView,
	sendGA4PageView,
	sendGA4FunnelStepViewed,
} from '../helpers/gtm';
import {
	doQuote,
	getVehicleBrands,
	getVehicleModels,
	getVehicleTypes,
	getVehicleUses,
	IVehicleForm,
	setVehicleValues,
} from '../store/Vehicle';
import { hashValue } from '../helpers/utils';

interface IVehicleProps {
	getTypes: () => void;
	getBrands: () => void;
	getModels: (brand: string) => void;
	getUses: () => void;
	quote: (data: IVehicleForm) => void;
	getValues: (plate: string) => void;
	submitting: boolean;
	types: IOptions[];
	brands?: IOptions[];
	models?: IOptions[];
	uses?: IOptions[];
	plate: string;
	isMoto?: boolean;
	documentId: string;
	initialValues: any;
	errors: any;
}

export function Vehicle(props: IVehicleProps) {
	const {
		getTypes,
		submitting,
		types,
		brands,
		getModels,
		models,
		getUses,
		uses,
		plate,
		isMoto,
		quote,
		initialValues,
		errors,
		getValues,
		documentId,
	} = props;

	const submitRef = React.useRef<
		React.MutableRefObject<HTMLButtonElement> | undefined
	>();

	const setValid = (valid: boolean) => {
		setValidated(valid);
	};

	const [isValid, setValidated] = React.useState(false);
	const back = () => window.history.back();
	const userId = hashValue(documentId);
	
	React.useEffect(() => {
		const hashData = async () => {
			try {
				const tagManagerArgs = {
					dataLayer: {
						event: 'vpvStep1.1',
						userId,
					},
					dataLayerName: 'dataLayer',
				};

				TagManager.dataLayer(tagManagerArgs);
			} catch (error) {
				/* tslint:disable */
				console.log(error);
			}
		};
		hashData();
		getTypes();
		getUses();
		if (plate.length > 0) {
			getValues(plate);
		} else {
			window.location.href = '/';
		}
	}, [plate, getTypes, getUses, getValues, documentId, isMoto]);

	React.useEffect(() => {
		sendUAPageView(PageNameUA.Paso_DatosVehiculo, !!isMoto, false);
		sendGA4PageView(PageNameGA4.Paso_DatosVehiculo, documentId);
		sendGA4FunnelStepViewed(PageNameGA4.Paso_DatosVehiculo, isMoto, documentId);
	}, [isMoto]);

	return (
		<div>
			<Container>
				{!submitting && (
					<div className="relative">
						<div id="banner" className="w-100" />
						<div className="container">
							<div className="row">
								{/* <VehicleFormTitle /> */}
								<div className="col-12">
									<div className="col-12 mt50">
										<VehicleForm
											onSubmit={quote}
											initialValues={initialValues}
											types={types}
											brands={brands}
											getModels={getModels}
											models={models}
											uses={uses}
											plate={plate}
											errors={errors}
											isMoto={isMoto}
											submitRef={submitRef}
											setValid={setValid}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
				{submitting && <Loading />}
			</Container>
			{!submitting && (
				<footer>
					<div className="d-flex flex-row justify-content-center">
						<div className="btn-back">
							<button className="btn white" type="button" onClick={back}>
								<i className="material-icons align-middle">
									keyboard_arrow_left
								</i>
							</button>
						</div>
						<div className="btn-next">
							<button
								id="vehicle"
								className={`btn ${isValid ? 'primary' : 'white'}`}
								onClick={() =>
									(submitRef.current as unknown as HTMLButtonElement).click()
								}
								disabled={!isValid}
							>
								Continuar
							</button>
						</div>
					</div>
				</footer>
			)}
		</div>
	);
}

export const mapStateToProps = (state: any) => {
	const {
		Vehicle: {
			submitting,
			types,
			brands,
			models,
			uses,
			data: initialValues,
			errors,
			isMoto,
		},
		ValidationForm: {
			data: { plate, documentId },
		},
	} = state;

	return {
		brands,
		errors,
		initialValues,
		models,
		plate,
		submitting,
		types,
		uses,
		documentId,
		isMoto,
	};
};

export const mapDispatchToProps = (dispatch: Dispatch) => ({
	getBrands: () => {
		dispatch(getVehicleBrands());
		dispatch(change('vehicle', 'brand', ''));
		dispatch(change('vehicle', 'model', ''));
	},
	getModels: (brand: string) => {
		dispatch(getVehicleModels(brand));
		dispatch(change('vehicle', 'model', ''));
	},
	getTypes: () => dispatch(getVehicleTypes()),
	getUses: () => dispatch(getVehicleUses()),
	getValues: (plate: string) => dispatch(setVehicleValues(plate)),
	quote: (payload: IVehicleForm) => dispatch(doQuote(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Vehicle);
