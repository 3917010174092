import React from 'react';
import { connect } from 'react-redux';

import LogoMoto from '../assets/svg/logo-moto.svg';
import LogoCar from '../assets/svg/logo-car.svg';
import { Progress } from 'reactstrap';

interface IStepperProps {
	isMoto: boolean;
	pathname: string;
}

const stepOne = ['/', '/vehicle'];
const stepTwo = ['/plans'];
const stepThree = ['/emit', '/payment'];

const step = (pathname: string): number => {
	if (stepOne.includes(pathname)) {
		return 1;
	}

	if (stepTwo.includes(pathname)) {
		return 2;
	}

	if (stepThree.includes(pathname)) {
		return 3;
	} else {
		return 1;
	}
};

function Stepper(props: IStepperProps) {
	const { isMoto, pathname } = props;

	return (
		<div className="stepper stepper d-flex justify-content-center">
			<section className="d-flex flex-column p-3">
				<div className="d-flex flex-row pb-2">
					<img
						src={isMoto ? LogoMoto : LogoCar}
						alt="Seguros Falabella Moto"
						width="30px"
					/>

					<h4 className="pl-2 d-flex align-items-center">
						{' '}
						Seguro
						<span className="font-weight-bold align-middle">
							{' '}
							&nbsp;SOAT {isMoto ? 'Moto' : 'Auto'}{' '}
						</span>
					</h4>
					<h5 className="d-flex align-items-center mr-0 ml-auto">
						{' '}
						Paso {step(pathname)} de 4{' '}
					</h5>
				</div>

				<Progress value={step(pathname) * 25} color="success" />
			</section>
		</div>
	);
}

export const mapStateToProps = (state: any, path: any) => {
	const {
		Vehicle: { isMoto },
		router: {
			location: { pathname },
		},
	} = state;

	return { isMoto, pathname };
};

export default connect(mapStateToProps)(Stepper);
