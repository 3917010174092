import axios from 'axios';
import { AnyAction } from 'redux';
import { call, put } from 'redux-saga/effects';

export const SET_ADVISER_STATUS = 'ADVISER_STATUS/SET';
export const GET_ADVISER_STATUS = 'ADVISER_STATUS/GET';
export const GET_ADVISER_CODE = 'ADVISER_CODE/GET';
export const SET_ADVISER_CODE = 'ADVISER_CODE/SET';
export const SET_ADVISER_FULFILLED = 'ADVISER_FULFILLED/SET';
export const RESET_ADVISER_STATE = 'ADVISER_STATE/RESET';

export const SET_FORM_ERROR = 'SET_ADVISER_FORM_ERROR';

export const LOADING = 'LOADING';

export const URL_ADVISER = '/adviser';

/**
 * Custom errors in forms
 * @property {string} name name of field. MUST be aproperty of {IValidationForm}
 * @property {string} value error description
 */
export interface IValidationCustomErrors {
	name: string;
	value: string;
	errorCode?: any;
}

export interface IAdviserState {
	data: {
		validAdviser: boolean;
		adviserCode: string;
	};
	loading: boolean;
	error: IValidationCustomErrors;
	fulfilled: boolean;
}

export const loading = () => ({
	type: LOADING,
});

export const getAdviserStatus = (payload: any) => ({
	payload,
	type: GET_ADVISER_STATUS,
});

export const setAdviserStatus = (payload: boolean) => ({
	payload,
	type: SET_ADVISER_STATUS,
});

export const getAdviserCode = (payload: string) => ({
	payload,
	type: GET_ADVISER_CODE,
});

export const setAdviserCode = (payload: string) => ({
	payload,
	type: SET_ADVISER_CODE,
});

export const setFormErrors = (payload: IValidationCustomErrors) => ({
	payload,
	type: SET_FORM_ERROR,
});

export const setFulfilled = (payload: boolean) => ({
	payload,
	type: SET_ADVISER_FULFILLED,
});

export const resetAdviserState = () => ({
	type: RESET_ADVISER_STATE,
});

const initialState: IAdviserState = {
	data: {
		validAdviser: false,
		adviserCode: '',
	},
	loading: false,
	error: {
		name: '',
		value: '',
		errorCode: null,
	},
	fulfilled: false,
};

export default function adviserReducer(
	state = initialState,
	action: AnyAction,
) {
	switch (action.type) {
		case SET_ADVISER_STATUS:
			return {
				...state,
				data: {
					...state.data,
					validAdviser: action.payload,
				},
			};
		case SET_ADVISER_CODE:
			return {
				...state,
				data: {
					...state.data,
					adviserCode: action.payload,
				},
			};
		case SET_FORM_ERROR:
			return {
				...state,
				error: {
					...action.payload,
				},
			};
		case SET_ADVISER_FULFILLED:
			return {
				...state,
				fulfilled: action.payload,
			};
		case RESET_ADVISER_STATE:
			return initialState;
		default:
			return state;
	}
}

export function* validateAdviser({ payload }: AnyAction) {
	try {
		const { data } = yield call(
			axios.get,
			`${URL_ADVISER}/${payload.adviserCode}`,
		);

		yield put(setAdviserStatus(data));

		if (!data) {
			yield put(
				setFormErrors({
					name: 'advisorCode',
					value: 'Código Asesor invalido',
					errorCode: 1,
				}),
			);
			yield put(setFulfilled(false));
		} else {
			yield put(
				setFormErrors({
					name: '',
					value: '',
					errorCode: null,
				}),
			);
			yield put(setFulfilled(true));
		}
	} catch (error) {
		yield put(
			setFormErrors({
				name: 'advisorCode',
				value: 'Error validando Código Asesor',
			}),
		);
	}
}

export function* setAdviser({ payload }: any) {
	yield put(setAdviserCode(payload));
}
