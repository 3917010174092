import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
	CompaniesSupport,
	Container,
	Loading,
	QuoteForm,
	QuoteFormTitle,
	Coverages,
} from '../components/';
import { IOptions } from '../components/FormSelect';
import { ICoupon } from '../store/Coupon';
import {
	getDocumentTypes,
	getVehicleDepartments,
	IValidationForm,
	validPlate,
	setDefaultCoupon,
} from '../store/ValidationForm';
import { defaultEncrypter } from '../helpers/utils';
import { setVehicleValues } from '../store/Vehicle';

interface IMatchProps {
	path: string;
	url: string;
	isExact: boolean;
	params: { plate?: string; couponCode?: string };
}
interface IQuoteProps {
	submitting: boolean;
	getVehDepartment: () => void;
	vehicleDepartments: any[];
	submitToVehicle: (payload: IValidationForm) => object;
	getDoctypes: () => void;
	doctypes: any[];
	defaultVehicleDepartment: IOptions;
	defaultDocType: IOptions;
	initialValues: any;
	errors: IValidationForm;
	match: IMatchProps;
	Coupon: ICoupon;
	couponCode: string;
	plate: string;
	isMoto: boolean;
	updateCoupon: (coupon: string) => void;
}

export function Quote(props: IQuoteProps) {
	const {
		submitting,
		getVehDepartment,
		vehicleDepartments = [],
		submitToVehicle,
		doctypes = [],
		getDoctypes,
		defaultVehicleDepartment,
		defaultDocType,
		initialValues,
		errors,
		match,
		Coupon,
		couponCode,
		updateCoupon,
		plate,
		isMoto,
	} = props;

	const submitRef = useRef<MutableRefObject<HTMLButtonElement> | undefined>();
	const childRef = useRef(null);

	const [politycs, setPolitycs] = useState(false);
	const [isValidBtn, setValidButton] = useState(false);

	const onChangePolicies = () => {
		setPolitycs(!politycs);
	};

	const setValidBtn = (valid: boolean) => {
		setValidButton(valid);
	};

	useEffect(() => {
		if (couponCode !== undefined) {
			updateCoupon(couponCode);
		}
		getVehDepartment();
		getDoctypes();
	}, [getVehDepartment, getDoctypes, updateCoupon, couponCode]);

	if (match.params.plate !== undefined && match.params.plate.length === 6) {
		initialValues.plate = match.params.plate;
	}

	return (
		<div>
			<Container>
				{!submitting && (
					<div className="relative">
						<div id="banner" className="w-100" />
						<div className="container">
							<div className="row">
								<QuoteFormTitle />
								<div className="col-12">
									<QuoteForm
										ref={childRef}
										onChangePolicies={onChangePolicies}
										onSubmit={submitToVehicle}
										vehicleDepartments={vehicleDepartments}
										doctypes={doctypes}
										defaultVehicleDepartment={defaultVehicleDepartment}
										defaultDocType={defaultDocType}
										initialValues={initialValues}
										errors={errors}
										coupon={Coupon}
										couponCode={couponCode}
										plate={plate}
										isMoto={isMoto}
										submitRef={submitRef}
										politycs={politycs}
										setValidBtn={setValidBtn}
									/>
								</div>
							</div>
						</div>
						<div className="col-12 col-sm-8 col-md-7 col-lg-6 form-group mx-auto">
							<CompaniesSupport text={'Con el respaldo de:'} />
						</div>
						<p className="fee-info">* Comisión por la intermediación 8,6%</p>
					</div>
				)}
				{submitting && <Loading />}

				<Coverages />
			</Container>

			<footer>
				<div className="d-flex justify-content-center mx-auto btn-unique">
					<button
						id="quote"
						className={`btn ${isValidBtn ? 'primary' : 'white'}`}
						onClick={() =>
							(submitRef.current as unknown as HTMLButtonElement).click()
						}
						disabled={!isValidBtn}
					>
						Cotizar
					</button>
				</div>
			</footer>
		</div>
	);
}

export const mapStateToProps = (state: any, path: any) => {
	const {
		Vehicle: { isMoto },
		ValidationForm: {
			submitting,
			vehicleDepartments,
			doctypes,
			defaultVehicleDepartment,
			defaultDocType,
			data,
			errors,
			plate,
		},
		Coupon,
	} = state;

	const { couponCode } = path.match.params;

	return {
		defaultDocType,
		defaultVehicleDepartment,
		doctypes,
		errors,
		initialValues: data,
		submitting,
		vehicleDepartments,
		Coupon,
		couponCode,
		plate,
		isMoto,
	};
};

export const mapDispatchToProps = (dispatch: Dispatch) => ({
	getDoctypes: () => dispatch(getDocumentTypes()),
	getVehDepartment: () => dispatch(getVehicleDepartments()),
	submitToVehicle: (payload: IValidationForm) => dispatch(validPlate(payload)),
	getValues: (plate: string) => dispatch(setVehicleValues(plate)),
	updateCoupon: (couponCode: string) => {
		dispatch(setDefaultCoupon(couponCode));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(Quote);
