const session = new Map<string, string>();

const get = (key: string): string | undefined => session.get(key);
const set = (key: string, value: string): void => {
	session.set(key, value);
};
const remove = (key: string): boolean => session.delete(key);
const clear = (): void => session.clear();

export default {
	get,
	set,
	remove,
	clear,
};
