import React, { useState } from 'react';
import Modal from './ModalCoverage';
import Logo from '../assets/svg/logo-car.svg';

interface ICoverage {
	title: string;
	description?: string;
	toppings: string[];
	extra?: string;
}

const coverages: ICoverage[] = [
	{
		title: '¿Qué cubre?',
		description:
			'Gastos médicos, incapacidad temporal o permanente, muerte, otros.',
		toppings: [
			'Gastos médicos de cada persona hasta 5 UIT.',
			'Incapacidad temporal de cada persona hasta 1 UIT.',
			'Invalidez permanente de cada persona hasta 4 UIT.',
			'Muerte por accidente de tránsito de cada persona hasta 4 UIT.',
			'Gasto de sepelio (Sólo por accidente de tránsito) de cada persona hasta 1 UIT.',
		],
	},
	{
		title: '¿Qué no cubre?',
		description:
			'Accidentes causados en carreras de automóviles, ocurridos fuera del territorio nacional, otros.',
		toppings: [
			'Los causados en carreras de automóviles y otras competencias de vehículos motorizados.',
			'Ocurridos fuera del territorio nacional.',
			'Los ocurridos en lugares no abiertos al tránsito público.',
			'Los ocurridos como consecuencia de guerras, sismos u otros casos fortuitos enteramente extraños a la circulación del vehículo automotor.',
			'El suicidio y la comisión de lesiones autoinferidas utilizando el vehículo automotos asegurado.',
		],
	},
	{
		title: '¿Cómo usar tu seguro?',
		description: 'En caso de accidente, conoce qué hacer.',
		toppings: [
			'De requerirlo, solicita a tu aseguradora una ambulancia, caso contrario, conduce al (los) herido(s) al centro de salud más cercano.',
			'Exhibe tu certificado SOAT original (físico o electrónico) al centro de salud o indica tu placa para validar su vigencia en la página de APESEG.',
			'Denuncia el accidente de tránsito a la autoridad policial.',
			'Sométete a dosaje etílico de ley.',
		],
		extra:
			'Si necesitas más información sobre el uso de este seguro <a href="https://web.segurosfalabella.com/pe/centro-de-ayuda/soat?_ga=2.255793800.1692169773.1666618153-1447749129.1654178081" target="_blank" rel="noopener noreferrer" key="newsKey">ingresa aquí</a>',
	},
];
function Coverages() {
	const [isOpen, setOpen] = useState(false);
	const initialCov: ICoverage = {
		title: '',
		toppings: [''],
	};
	const [contentModal, setContentModal] = useState(initialCov);

	const toggle = (i: any) => {
		setContentModal({
			title: coverages[i].title,
			...(coverages[i].extra && { extra: coverages[i].extra }),
			toppings: coverages[i].toppings,
		});
		setOpen(!isOpen);
	};

	return (
		<section className="coverages d-flex justify-content-center align-items-center pb-4">
			<div className="coverages--content d-flex flex-column">
				<div className="d-flex justify-content-left pl-1 pb-4">
					<img src={Logo} alt="Corredores de Seguros Falabella" width="32px" />
					<span className="d-flex align-items-center pl-2">
						{' '}
						Coberturas y Beneficios{' '}
					</span>
				</div>

				{coverages.map((item, i) => (
					<div key={i} className="coverages--box" onClick={() => toggle(i)}>
						<div>
							<h5> {item.title} </h5>
							<span> {item.description} </span>
						</div>
						<i className="material-icons">chevron_right</i>
					</div>
				))}

				<Modal isOpen={isOpen} toggle={toggle} data={contentModal} />
			</div>
		</section>
	);
}

export default Coverages;
