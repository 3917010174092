import React from 'react';

export function HeaderLogo() {
	return (
		<div className="d-flex justify-content-center align-items-center py-2">
			<a
				href="//web.segurosfalabella.com/pe/"
				target="_blank"
				rel="noopener noreferrer"
			>
				<img src="https://sfestaticos.blob.core.windows.net/regional/assets/pe_color_1_9402cec946.svg" alt="Corredores de Seguros Falabella" width="200px" />
			</a>
		</div>
	);
}

export default HeaderLogo;
